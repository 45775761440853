const macd_data_init_state = {
  macd_fast_ema: "12",
  macd_slow_ema: "26",
  macd_sma: "9",
  macd_applied_price: "close",
  macd_entry_type: "above_below",
  macd_above: "30",
  macd_below: "70"
}

module.exports = macd_data_init_state