import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
} from "reactstrap";

import CommonModal from './common';

import common_data_init_state from "../model/common"

import bb_data_init_state from "../model/bb"

function BB(props) {


  const [common_data, set_common_data] = React.useState(common_data_init_state);

  const updateCommonData = (newData) => {
    set_common_data(newData);
  };

  const [edit_data, set_edit_data] = React.useState(null);

  const { strategyID, strategy, symbol, symbol_code, exchange, exchange_code, day, month_name, month_value, year, option_type, option_gap, trade_type, order_type, timeframe,
    stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
    squareoff_time
  } = common_data;

  const [bb_data, set_bb_data] = React.useState(bb_data_init_state);

  const { bb_period, bb_deviation } = bb_data;

  const changeHandler = (e) => {

    //bb
    if (e.target.name == "bb_period") {
      set_bb_data({ ...bb_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "bb_deviation") {
      set_bb_data({ ...bb_data, [e.target.name]: e.target.value });
    }

  };

  function Submit() {

    //debugger;

    var input = { ...common_data, ...bb_data };

    input.strategy = props.strategy;

    input.strategyID = props.strategyID;

    input.strategy_name = props.strategy_name;

    input.strategy_type = props.strategy_type;

    //alert(JSON.stringify(input));

    if (bb_period == "" || bb_deviation == "") {
      SetapiError("Enter All Required Fields !!!")
      return;
    }

    if (input.strategy_type == "NORMAL") {
      if (input.strategy == "" || symbol == "" || symbol_code == "" || exchange == "" || exchange_code == "" || qty == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }
    }
    else if (input.strategy_type == "OPTIONS") {
      if (input.strategy == "" || symbol == "" || month_name == "" || month_value == "" || year == "" || qty == "" || option_type == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }

      if (option_type != "ATM" && option_gap == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }
    }

    if (input.strategyID == "") {
      props.AddStrategy(input);
      props.setmodal_standard(false);
    } else {
      props.UpdateStrategy(input);
      props.setmodal_standard(false);
    }

  }

  useEffect(() => {

    if (props.editData) {

      var item = props.editData;

      const { strategyID, strategy, symbol, symbol_code, exchange, exchange_code, trade_type, order_type, timeframe,
        stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
        squareoff_time, ...rest
      } = item;

      set_edit_data({
        strategyID, strategy, symbol, symbol_code, exchange, exchange_code, trade_type, order_type, timeframe,
        stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
        squareoff_time
      });

      set_bb_data(rest);

    }
    else {
      set_bb_data(bb_data_init_state);
    }
  }, [props.editData]);


  const [apiSuccess, SetapiSuccess] = useState(null)

  const [apiError, SetapiError] = useState(null)

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => SetapiSuccess(null), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => SetapiError(null), 1000)
    }
  }, [apiError]);

  return (
    <div>
      <Row>
        <CommonModal edit_data={edit_data} strategy={props.strategy} strategy_type={props.strategy_type} strategy_name={props.strategy_name} updateCommonData={updateCommonData} />

        <fieldset className="border border-light border-2 rounded-3 p-2 mb-2">
          <legend className="float-none w-auto px-3 fs-6 fw-bold">
            Bollinger Properties
          </legend>

          <Row>

            <Col className="mb-2" md={6}>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  value={bb_period}
                  name="bb_period"
                  onChange={changeHandler}
                  required={true} />
                <label htmlFor="floatingSelectGrid">Period</label>
              </div>
            </Col>

            <Col className="mb-2" md={6}>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  value={bb_deviation}
                  name="bb_deviation"
                  onChange={changeHandler}
                  required={true} />
                <label htmlFor="floatingSelectGrid">Deviations</label>
              </div>
            </Col>

          </Row>

        </fieldset>

        <Col className="mb-2" md={12}>
          <button type="button" onClick={() => { Submit() }} className="btn btn-primary float-end">
            Add Strategy
          </button>
        </Col>
        {
          apiSuccess && (
            <div
              className="alert alert-success text-center mb-0"
              role="alert">
              {apiSuccess}
            </div>
          )
        }

        {
          apiError && (
            <div
              className="alert alert-danger text-center mb-0"
              role="alert">
              {apiError}
            </div>
          )
        }
      </Row>
    </div>
  );
}

export default BB;
