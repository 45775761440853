import React, { useEffect, useState, useRef } from "react";

import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Tooltip,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
} from "reactstrap";

function EVENTS(props) {

    var item = props.item;

    return (
        <div>
            {item.active == "false" ? (
                <>
                    <button onClick={() => { props.StrategyEvents(item.strategyID, "start") }} data-bs-toggle="tooltip" data-bs-placement="top" id="starttooltip" className="btn btn-sm btn-soft btn-success me-1 pt-2 mb-2"><i className="dripicons-media-play"></i></button>
                    <UncontrolledTooltip placement="top" target="starttooltip">
                        Start
                    </UncontrolledTooltip>
                </>
            ) : (
                <>
                    <button onClick={() => { props.StrategyEvents(item.strategyID, "stop") }} data-bs-toggle="tooltip" data-bs-placement="top" id="stoptooltip" className="btn btn-sm btn-secondary me-1 pt-2 mb-2"><i className="dripicons-media-stop"></i></button><UncontrolledTooltip placement="top" target="stoptooltip">
                        Stop
                    </UncontrolledTooltip>
                </>
            )}

            <button onClick={() => { props.SquareoffStrategy(item.strategyID) }} data-bs-toggle="tooltip" data-bs-placement="top" id="squaretooltip" className="btn btn-sm btn-info me-1 pt-2 mb-2"><i className="dripicons-time-reverse"></i></button>
            <UncontrolledTooltip placement="top" target="squaretooltip">
                Squareoff
            </UncontrolledTooltip>

            <button onClick={() => { props.EditStrategy(item) }} data-bs-toggle="tooltip" data-bs-placement="top" id="edittooltip" className="btn btn-sm btn-warning me-1 pt-2 mb-2"><i className="dripicons-document-edit"></i></button>
            <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
            </UncontrolledTooltip>

            <button onClick={() => { props.StrategyEvents(item.strategyID, "reset") }} data-bs-toggle="tooltip" data-bs-placement="top" id="resettooltip" className="btn btn-sm btn-dark me-1 pt-2 mb-2"><i className="bx bx-reset"></i></button>
            <UncontrolledTooltip placement="top" target="resettooltip">
                Reset
            </UncontrolledTooltip>

            <button onClick={() => { props.DeleteStrategy(item.strategyID) }} data-bs-toggle="tooltip" data-bs-placement="top" id="deletetooltip" className="btn btn-sm btn-danger me-1 pt-2 mb-2"><i className="dripicons-trash"></i></button>
            <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
            </UncontrolledTooltip>
        </div>
    )

}

export default EVENTS;
