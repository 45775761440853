import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";
import moment from "moment";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Tooltip,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
} from "reactstrap";
import classnames from "classnames";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import images from "assets/images";
import {
    placeOrders,
    placeOrdersSuccessful,
    placeOrdersFailed,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { getOrderbook } from "../../store/actions";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

const TradingPopup = props => {

    const { show_popup, toggleShow, tradeObject, ModifyLimit } = props;

    const [orderSymbol, setorderSymbol] = useState("");

    const [transactionType, settransactionType] = useState("BUY");

    const [sessionType, setsessionType] = useState("DAY");

    const [tradeType, settradeType] = useState("NRML");

    const [productType, setproductType] = useState("MARKET");

    const [orderType, setorderType] = useState("REGULAR");

    const [qty, setqty] = useState("0");

    const [price, setprice] = useState("0");

    const [triggerPrice, settriggerPrice] = useState("0");

    const [targetPrice, settargetPrice] = useState("0");

    const [stopPrice, setstopPrice] = useState("0");

    useEffect(() => {
        //console.log("show_popup=" + show_popup);
    }, [show_popup]);

    useEffect(() => {
        //console.log("tradeObject=" + JSON.stringify(tradeObject));
        if (ModifyLimit == false) {
            if (tradeObject.transactionType == "BUY") {
                setmodal_header_color("bg-info text-white");
                setmodal_header_class("btn-outline-info");
            } else {
                setmodal_header_color("bg-danger text-white");
                setmodal_header_class("btn-outline-danger");
            }
            setorderSymbol(tradeObject.symbol);
            settransactionType(tradeObject.transactionType);
            setsessionType(tradeObject.sessionType);
            settradeType(tradeObject.tradeType);
            setproductType(tradeObject.productType);
            setorderType(tradeObject.orderType);
            setqty(tradeObject.qty);
            setprice(tradeObject.price);
            settriggerPrice(tradeObject.triggerPrice);
            settargetPrice(tradeObject.targetPrice);
            setstopPrice(tradeObject.stopPrice);
        }
        else {
            if (tradeObject.broker == "alice") {
                if (tradeObject.Trantype == "B") {
                    setmodal_header_color("bg-info text-white");
                    setmodal_header_class("btn-outline-info");
                } else {
                    setmodal_header_color("bg-danger text-white");
                    setmodal_header_class("btn-outline-danger");
                }
                setorderSymbol(tradeObject.Trsym);
                settransactionType((tradeObject.Trantype == "B") ? "BUY" : "SELL");
                setsessionType("DAY");
                settradeType(tradeObject.Pcode);
                setproductType((tradeObject.Prctype == "L") ? "LIMIT" : (tradeObject.Prctype == "MKT") ? "MARKET" : (tradeObject.Prctype == "SL") ? "SL" : "SLM");
                setorderType("REGULAR");
                setqty(tradeObject.Qty);
                setprice(tradeObject.Prc);
                settriggerPrice(tradeObject.Trgprc);
                settargetPrice("0");
                setstopPrice("0");
            }
            else if (tradeObject.broker == "zerodha") {
                if (tradeObject.transaction_type == "BUY") {
                    setmodal_header_color("bg-info text-white");
                    setmodal_header_class("btn-outline-info");
                } else {
                    setmodal_header_color("bg-danger text-white");
                    setmodal_header_class("btn-outline-danger");
                }
                setorderSymbol(tradeObject.tradingsymbol);
                settransactionType(tradeObject.transaction_type);
                setsessionType("DAY");
                settradeType(tradeObject.product);
                setproductType(tradeObject.order_type);
                setorderType("REGULAR");
                setqty(tradeObject.quantity);
                setprice(tradeObject.price);
                settriggerPrice(tradeObject.trigger_price);
                settargetPrice("0");
                setstopPrice("0");
            }
            else if (tradeObject.broker == "angel") {
                if (tradeObject.transactiontype == "BUY") {
                    setmodal_header_color("bg-info text-white");
                    setmodal_header_class("btn-outline-info");
                } else {
                    setmodal_header_color("bg-danger text-white");
                    setmodal_header_class("btn-outline-danger");
                }
                setorderSymbol(tradeObject.tradingsymbol);
                settransactionType(tradeObject.transactiontype);
                setsessionType("DAY");
                settradeType((tradeObject.producttype == "DELIVERY") ? "CNC" : (tradeObject.producttype == "CARRYFORWARD") ? "NRML" : (tradeObject.producttype == "INTRADAY") ? "MIS" : tradeObject.producttype);
                setproductType((tradeObject.ordertype == "LIMIT") ? "LIMIT" : (tradeObject.ordertype == "MARKET") ? "MARKET" : (tradeObject.ordertype == "STOPLOSS_LIMIT") ? "SL" : "SLM");
                setorderType("REGULAR");
                setqty(tradeObject.quantity);
                setprice(tradeObject.price);
                settriggerPrice(tradeObject.triggerprice);
                settargetPrice("0");
                setstopPrice("0");
            }
        }
    }, [tradeObject]);

    const dispatch = useDispatch();

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    const [modal_header_color, setmodal_header_color] = useState("bg-info text-white");

    const [modal_header_class, setmodal_header_class] = useState("btn-outline-info");

    const [qtyvalid, setqtyvalid] = useState(true);
    const [pricevalid, setpricevalid] = useState(true);
    const [triggerPricevalid, settriggerPricevalid] = useState(true);
    const [targetPricevalid, settargetPricevalid] = useState(true);
    const [stopPricevalid, setstopPricevalid] = useState(true);


    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function CallPlaceOrder() {

        var qty = parseInt(tradeObject.qty);

        var price = parseFloat(tradeObject.price);
        var triggerPrice = parseFloat(tradeObject.triggerPrice);
        var targetPrice = parseFloat(tradeObject.targetPrice);
        var stopPrice = parseFloat(tradeObject.stopPrice);

        var productType = tradeObject.productType;
        var orderType = tradeObject.orderType;

        if (price == 0) {
            var tickdata_record = localStorage.getItem("tickdata_record");
            if (tickdata_record) {

                tickdata_record = JSON.parse(tickdata_record);

                var index = tickdata_record.findIndex(function (e) { return e.instrumentToken == tradeObject.token });

                //console.log("index=" + index + ",lastTradedPrice=" + (tickdata_record[index].lastTradedPrice / 100));

                if (index > -1) {
                    tradeObject.price = tickdata_record[index].lastTradedPrice / 100;
                }

            }
        }

        var valid = true;

        if (qty == 0) {
            setqtyvalid(false);
            valid = false;
        }

        if (productType == "LIMIT" && price == 0) {
            setpricevalid(false);
            valid = false;
        }

        if ((productType == "SL" || productType == "SLM") && triggerPrice == 0) {
            settriggerPricevalid(false);
            valid = false;
        }

        if ((orderType == "BO" || orderType == "CO") && stopPrice == 0) {
            setstopPricevalid(false);
            valid = false;
        }

        if (orderType == "BO" && targetPrice == 0) {
            settargetPricevalid(false);
            valid = false;
        }

        //alert(JSON.stringify(tradeObject));

        if (valid) {
            dispatch(placeOrders({ "tradeObject": tradeObject }, props.history));
            toggleShow();
        }

    }

    function CallModifyOrder() {
        //console.log('modify');
        //console.log(JSON.stringify(tradeObject));

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;
        var role = JSON.parse(authUser).role;

        var input = {
            email: email,
            role: role,
            broker: tradeObject.broker,
            orderInfo: tradeObject
        }

        input.orderInfo.new_price=price;
        input.orderInfo.new_qty=qty;

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/trading/modifyOrder', JSON.stringify(input), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                toggleShow();

                setTimeout(() => { dispatch(getOrderbook()); }, 500);

            }
            else {
                SetapiError(output.message);

                toggleShow();
            }

        });

    }

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    return (
        <React.Fragment>

            {apiSuccess && (
                <div
                    className="alert alert-success text-center"
                    role="alert">
                    {apiSuccess}
                </div>
            )}

            {apiError && (
                <div
                    className="alert alert-danger text-center"
                    role="alert">
                    {apiError}
                </div>
            )}

            <Modal
                isOpen={show_popup}
                toggle={toggleShow}
                centered
            >
                <div className={modal_header_color + " modal-header "}>
                    <h5 className="modal-title mt-0" style={{ "color": "#ffff" }}>{orderSymbol}</h5>

                    <div>
                        <div
                            className="btn-group btn-group-example"
                            role="group">
                            <button type="button" disabled={ModifyLimit} className="btn btn-info w-xs" onClick={() => {
                                setmodal_header_color("bg-info text-white");
                                setmodal_header_class("btn-outline-info");
                                settransactionType("BUY");
                                tradeObject.transactionType = "BUY";
                            }}>
                                BUY
                            </button>
                            <button type="button" disabled={ModifyLimit} className="btn btn-danger w-xs" onClick={() => {
                                setmodal_header_color("bg-danger text-white");
                                setmodal_header_class("btn-outline-danger");
                                settransactionType("SELL");
                                tradeObject.transactionType = "SELL";
                            }}>
                                SELL
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal-body">
                    <Row>
                        <div className="col-md-6">
                            <div
                                className="btn-group btn-group-example mb-3"
                                role="group"
                            >
                                {tradeObject.exchange == "NSE" ?
                                    (
                                        <button
                                            type="button" disabled={ModifyLimit}
                                            onClick={() => { settradeType("CNC"); tradeObject.tradeType = "CNC"; }}
                                            className={modal_header_class + " btn w-sm " + (tradeType == "CNC" && modal_header_color)}
                                        >
                                            CNC
                                        </button>
                                    ) :
                                    (
                                        <button
                                            type="button" disabled={ModifyLimit}
                                            onClick={() => { settradeType("NRML"); tradeObject.tradeType = "NRML"; }}
                                            className={modal_header_class + " btn w-sm " + (tradeType == "NRML" && modal_header_color)}
                                        >
                                            NRML
                                        </button>
                                    )}


                                < button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { settradeType("MIS"); tradeObject.tradeType = "MIS"; }}
                                    className={modal_header_class + " btn w-sm " + (tradeType == "MIS" && modal_header_color)}
                                >
                                    MIS
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ textAlign: "right" }}>
                            <div
                                className="btn-group btn-group-example mb-3"
                                role="group"
                            >
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setsessionType("DAY"); tradeObject.sessionType = "DAY"; }}
                                    className={modal_header_class + " btn w-sm " + (sessionType == "DAY" && modal_header_color)}
                                >
                                    DAY
                                </button>
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setsessionType("IOC"); tradeObject.sessionType = "IOC"; }}
                                    className={modal_header_class + " btn w-sm " + (sessionType == "IOC" && modal_header_color)}
                                >
                                    IOC
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-4">
                            <div className="form-floating mb-3">
                                <input type="text" className={qtyvalid == true ? "form-control" : "form-control is-invalid"} placeholder="Qty" value={qty} onChange={(e) => { tradeObject.qty = parseInt(e.target.value) || 0; setqty(e.target.value || 0); if (parseInt(e.target.value || 0) > 0) setqtyvalid(true); else setqtyvalid(false); }} />
                                <label >Qty</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating mb-3">
                                <input type="text" className={pricevalid == true ? "form-control" : "form-control is-invalid"} placeholder="Price" value={price} onChange={(e) => { tradeObject.price = parseFloat(e.target.value) || 0; setprice(e.target.value || 0); if (parseFloat(e.target.value || 0) > 0) setpricevalid(true); else setpricevalid(false); }} />
                                <label >Price</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-floating mb-3">
                                <input type="text" disabled={ModifyLimit} className={triggerPricevalid == true ? "form-control" : "form-control is-invalid"} placeholder="Trigger Price" value={triggerPrice} onChange={(e) => { tradeObject.triggerPrice = parseFloat(e.target.value) || 0; settriggerPrice(e.target.value || 0); if (parseFloat(e.target.value || 0) > 0) settriggerPricevalid(true); else settriggerPricevalid(false); }} />
                                <label >Trigger Price</label>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-6">
                            <div
                                className="mb-3"
                                role="group"
                            >
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setproductType("LIMIT"); tradeObject.productType = "LIMIT"; }}
                                    className={modal_header_class + " btn w-sm " + (productType == "LIMIT" && modal_header_color)}
                                >
                                    LIMIT
                                </button>
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setproductType("MARKET"); tradeObject.productType = "MARKET"; }}
                                    className={modal_header_class + " btn w-sm " + (productType == "MARKET" && modal_header_color)}
                                >
                                    MARKET
                                </button>
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setproductType("SL"); tradeObject.productType = "SL"; }}
                                    className={modal_header_class + " btn w-sm " + (productType == "SL" && modal_header_color)}
                                >
                                    SL
                                </button>
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setproductType("SLM"); tradeObject.productType = "SLM"; }}
                                    className={modal_header_class + " btn w-sm " + (productType == "SLM" && modal_header_color)}
                                >
                                    SLM
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ textAlign: "right" }}>
                            <div
                                className="mb-3"
                                role="group"
                            >
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setorderType("REGULAR"); tradeObject.orderType = "REGULAR"; }}
                                    className={modal_header_class + " btn w-sm " + (orderType == "REGULAR" && modal_header_color)}
                                >
                                    REGULAR
                                </button>
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setorderType("BO"); tradeObject.orderType = "BO"; }}
                                    className={modal_header_class + " btn w-sm " + (orderType == "BO" && modal_header_color)}
                                >
                                    BO
                                </button>
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setorderType("CO"); tradeObject.orderType = "CO"; }}
                                    className={modal_header_class + " btn w-sm " + (orderType == "CO" && modal_header_color)}
                                >
                                    CO
                                </button>
                                <button
                                    type="button" disabled={ModifyLimit}
                                    onClick={() => { setorderType("AMO"); tradeObject.orderType = "AMO"; }}
                                    className={modal_header_class + " btn w-sm " + (orderType == "AMO" && modal_header_color)}
                                >
                                    AMO
                                </button>
                            </div>
                        </div>
                    </Row>

                    {(orderType == "BO" || orderType == "CO") && (
                        <Row>

                            {(orderType == "BO") && (
                                <div className="col-md-4">
                                    <div className="form-floating mb-3">
                                        <input type="text" disabled={ModifyLimit} className={targetPricevalid == true ? "form-control" : "form-control is-invalid"} placeholder="Target" value={targetPrice} onChange={(e) => { tradeObject.targetPrice = parseFloat(e.target.value) || 0; settargetPrice(e.target.value || 0); if (parseFloat(e.target.value || 0) > 0) settargetPricevalid(true); else settargetPricevalid(false); }} />
                                        <label >Target</label>
                                    </div>
                                </div>
                            )}

                            {(orderType == "BO" || orderType == "CO") && (
                                <div className="col-md-4">
                                    <div className="form-floating mb-3">
                                        <input type="text" disabled={ModifyLimit} className={stopPricevalid == true ? "form-control" : "form-control is-invalid"} placeholder="Stoploss" value={stopPrice} onChange={(e) => { tradeObject.stopPrice = parseFloat(e.target.value) || 0; setstopPrice(e.target.value || 0); if (parseFloat(e.target.value || 0) > 0) setstopPricevalid(true); else setstopPricevalid(false); }} />
                                        <label >Stoploss</label>
                                    </div>
                                </div>
                            )}

                        </Row>
                    )}

                    <Row>
                        <div className="col-md-12" style={{ textAlign: "right" }}>
                            {ModifyLimit == false ?
                                (<button type="submit" className={modal_header_color + " btn w-md me-1 "} style={{ "color": "#ffff" }} onClick={() => { CallPlaceOrder(); }}>Place</button>)
                                :
                                (<button type="submit" className={modal_header_color + " btn w-md me-1 "} style={{ "color": "#ffff" }} onClick={() => { CallModifyOrder(); }}>Modify</button>)
                            }

                            <button type="button" className="btn btn-light w-md" data-dismiss="modal" onClick={toggleShow}>Cancel</button>
                        </div>
                    </Row>
                </div>
                {
                    apiSuccess && (
                        <div
                            className="alert alert-success text-center mb-0"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )
                }

                {
                    apiError && (
                        <div
                            className="alert alert-danger text-center mb-0"
                            role="alert">
                            {apiError}
                        </div>
                    )
                }
            </Modal >

        </React.Fragment >
    );
};

export default TradingPopup;
