import React, { useEffect, useState, useRef } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Tooltip, Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions

import classnames from "classnames";

import axios from "axios";

import { API_URL } from "helpers/api_helper";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

const TradeHistory = props => {

    //meta title
    document.title = "TradeHistory | Algobridge ";


    const [ttop, setttop] = useState({ id: "TooltipTop_0", open: false, message: "" });

    const [page, setpage] = useState(0);

    const [searchData, setsearchData] = useState("");

    const [strategy_type, Setstrategy_type] = useState("NORMAL");

    const [tradehistoryData, settradehistoryData] = useState(null);

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    const [socketInstruments, SetsocketInstruments] = useState(null)

    const [TickData, SetTickData] = useState(null);

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    // useEffect(() => {
    //     LoadData(searchData, page);
    // }, []);

    useEffect(() => {
        LoadData(searchData, page);
    }, [strategy_type]);

    useEffect(() => {

        socket_io.on("event", (data) => {
            //console.log("data=" + data);
            if (data == "reload") {
                LoadData(searchData, page);
            }
        });

    }, []);

    function SearchData() {
        //alert("searchData=" + searchData);
        LoadData(searchData, page);
    }

    function LoadData(search, no) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email,
            "strategy_type": strategy_type,
            "search": search,
            "page": no
        };

        //console.log("page=" + no);

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/trading/getCustomStrategiesOrders', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                settradehistoryData(output.data);
                if (output.data) {
                    var instruments = { "a": "subscribe", "v": [], "m": "marketdata" };
                    var data = output.data;
                    for (var i = 0; i < data.length; i++) {
                        var exchange_code = parseInt(data[i].strategyProperties.exchange_code);
                        var instrument_token = parseInt(data[i].strategyProperties.symbol_code);
                        instruments.v.push([exchange_code, instrument_token]);
                    }
                    SetsocketInstruments(instruments);
                }
            }
            else {
                settradehistoryData(null);
            }
        });
    }

    function ClearStrategiesTradeHistory() {

        if (window.confirm('Are You Sure Want To Clear History ?')) {

            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            var obj = {
                "email": email,
                "strategy_type": strategy_type
            };

            //console.log("page=" + no);

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/trading/clearCustomStrategiesOrdersHistory', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    settradehistoryData(null);

                }
                else {
                    SetapiError(output.message);
                }

            });

        }

    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    useEffect(() => {

        if (socketInstruments != null) {


            if (socketInstruments.v.length > 0) {
                //console.log("socketInstruments=" + JSON.stringify(socketInstruments));
                AddToMarketDataSocketInstruments(socketInstruments.v);
            }

            socket_io.on("socket-marketdata", (result) => {

                //console.log("data=" + JSON.stringify(result));

                var tickdata_record = localStorage.getItem("strategy_tickdata_record");
                if (tickdata_record) {

                    tickdata_record = JSON.parse(tickdata_record);

                    var index = tickdata_record.findIndex(function (e) { return e.instrumentToken == result.instrumentToken });

                    //console.log("index=" + index);

                    if (index == -1) {
                        tickdata_record.push(result);
                        tickdata_record = JSON.stringify(tickdata_record);
                        localStorage.setItem("strategy_tickdata_record", tickdata_record);
                    }
                    else {
                        tickdata_record[index] = result;
                        tickdata_record = JSON.stringify(tickdata_record);
                        localStorage.setItem("strategy_tickdata_record", tickdata_record);
                    }

                }
                else {
                    var input = [];
                    input.push(result)
                    input = JSON.stringify(input);
                    localStorage.setItem("strategy_tickdata_record", input);
                }

                SetTickData(result);

            });

        }

    }, [socketInstruments]);

    useEffect(() => {
        if (TickData != null) {
            var tickdata_record = localStorage.getItem("strategy_tickdata_record");
            if (tickdata_record) {
                tickdata_record = JSON.parse(tickdata_record);
                for (var i = 0; i < tickdata_record.length; i++) {
                    fillInstrumentsTickData(tickdata_record[i]);
                }
            }
        }
    }, [TickData]);

    function fillInstrumentsTickData(data) {

        //console.log("data.instrumentToken=" + data.instrumentToken);

        data.lastTradedPrice = data.lastTradedPrice / 100;
        data.openPrice = data.openPrice / 100;
        data.highPrice = data.highPrice / 100;
        data.lowPrice = data.lowPrice / 100;
        data.closePrice = data.closePrice / 100;

        if (tradehistoryData) {

            const newTradehistoryData = [...tradehistoryData]; // make a copy of the array

            for (var i = 0; i < newTradehistoryData.length; i++) {

                //console.log("newTradehistoryData[i].strategyProperties.symbol_code=" + newTradehistoryData[i].strategyProperties.symbol_code + ",data.instrumentToken=" + data.instrumentToken);

                if (parseInt(newTradehistoryData[i].strategyProperties.symbol_code) == data.instrumentToken) {

                    newTradehistoryData[i].pastprice = newTradehistoryData[i].live_price || 0;
                    newTradehistoryData[i].live_price = data.lastTradedPrice;

                    if (parseFloat(newTradehistoryData[i].live_price) > parseFloat(newTradehistoryData[i].pastprice)) {
                        newTradehistoryData[i].trend = "UP";
                    }
                    else if (parseFloat(newTradehistoryData[i].live_price) < parseFloat(newTradehistoryData[i].pastprice)) {
                        newTradehistoryData[i].trend = "DOWN";
                    }

                    var entry_price = newTradehistoryData[i].price;

                    if (newTradehistoryData[i].strategyProperties.transactionType == "BUY") {

                        newTradehistoryData[i].movement = ((parseFloat(newTradehistoryData[i].live_price) - parseFloat(entry_price)) * 100) / parseFloat(entry_price)

                        newTradehistoryData[i].movement = parseFloat(newTradehistoryData[i].movement).toFixed(2);


                    } else {
                        newTradehistoryData[i].movement = ((parseFloat(entry_price) - parseFloat(newTradehistoryData[i].live_price)) * 100) / parseFloat(entry_price)

                        newTradehistoryData[i].movement = parseFloat(newTradehistoryData[i].movement).toFixed(2);
                    }


                }
            }

            settradehistoryData(newTradehistoryData);

        }
    }

    //tab navigation
    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Dashboards" breadcrumbItem="Strategy Trades History" />

                    <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                    Setstrategy_type("NORMAL");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">NORMAL STRATEGIES</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                    Setstrategy_type("OPTIONS");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">OPTIONS STRATEGIES</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    <Row className="mb-4">
                                        <Col md={6} className="mb-2">
                                            <div className="d-flex flex-wrap gap-2">

                                                <div className="btn-group">
                                                    <Link to="#" onClick={() => {
                                                        if (page > 0) {
                                                            LoadData(searchData, page - 1);
                                                            setpage(page - 1);
                                                        }
                                                    }} className="btn btn-outline-primary">Prev</Link>
                                                    <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                                                    <Link to="#" onClick={() => {
                                                        LoadData(searchData, page + 1);
                                                        setpage(page + 1);
                                                    }} className="btn btn-outline-primary">Next</Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                                            <div style={{ display: "inline-flex" }}>
                                                <input value={searchData} onChange={(val) => {
                                                    if (val != "") {
                                                        setsearchData(val.target.value)
                                                    }
                                                }}
                                                    onKeyPress={event => {
                                                        if (event.key === 'Enter') {
                                                            SearchData();
                                                        }
                                                    }}
                                                    className="form-control " type="text" placeholder="Search"></input>

                                                <button
                                                    type="button"
                                                    className="btn btn-danger ms-2 me-3"
                                                    onClick={() => {
                                                        ClearStrategiesTradeHistory();
                                                    }}
                                                >
                                                    Clear
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                id="tech-companies-1"
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th data-priority="1">Strategy Name</Th>
                                                        <Th data-priority="2">Symbol</Th>
                                                        <Th data-priority="3">Trade</Th>
                                                        <Th data-priority="4">Entry</Th>
                                                        <Th data-priority="6">Live Price</Th>
                                                        <Th data-priority="7">Qty</Th>
                                                        <Th data-priority="8">Event</Th>
                                                        <Th data-priority="10">Status</Th>
                                                        <Th>Time</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {tradehistoryData &&

                                                        tradehistoryData.map((data, key) => (
                                                            <Tr key={key}>
                                                                <Td>{data.strategy}</Td>
                                                                <Td>{data.symbol}</Td>
                                                                <Td><p style={{ color: (data.transactionType == "BUY") ? "green" : "red" }}>{data.transactionType}</p></Td>
                                                                <Td>{(data.price) ? parseFloat(data.price).toFixed(2) : 0}</Td>
                                                                <Td><div style={{ display: "inline-flex" }}>
                                                                    <p style={{ color: (data.trend == "UP") ? "green" : "red" }}>{(data.live_price || 0).toFixed(2)}</p>
                                                                    <p className="ms-2" style={{ color: (data.movement > 0) ? "green" : "red" }}>{"(" + (data.movement || 0) + "%)"}</p>
                                                                </div>
                                                                </Td>
                                                                <Td>{data.qty}</Td>
                                                                <Td>{data.event.toUpperCase()}</Td>
                                                                <Td>

                                                                    <button
                                                                        type="button"
                                                                        className={((JSON.stringify(data.response).toLowerCase().indexOf("success") > -1 && JSON.stringify(data.response).toLowerCase().indexOf("invalid") == -1) || (JSON.stringify(data.response).toLowerCase().indexOf("ok") > -1) || (JSON.stringify(data.response).toLowerCase().indexOf("transit") > -1) || (JSON.stringify(data.response).toLowerCase().indexOf('"code":-99') > -1)) ? "btn btn-success" : "btn btn-danger"}
                                                                        id={"TooltipTop_" + key}
                                                                        onClick={() => {
                                                                            setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: JSON.stringify(data.response) })
                                                                        }}

                                                                    >
                                                                        {((JSON.stringify(data.response).toLowerCase().indexOf("success") > -1 && JSON.stringify(data.response).toLowerCase().indexOf("invalid") == -1) || (JSON.stringify(data.response).toLowerCase().indexOf("ok") > -1) || (JSON.stringify(data.response).toLowerCase().indexOf("transit") > -1) || (JSON.stringify(data.response).toLowerCase().indexOf('"code":-99') > -1)) ? "SUCCESS" : "FAILED"}
                                                                    </button>

                                                                    <Tooltip
                                                                        placement="top"
                                                                        isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                                                        target={"TooltipTop_" + key}
                                                                    >
                                                                        {ttop.id == "TooltipTop_" + key && ttop.message}
                                                                    </Tooltip>


                                                                </Td>
                                                                <Td>{(data.created_at) ? new Date(data.created_at).toLocaleString() : "-"}</Td>
                                                            </Tr>
                                                        ))}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default TradeHistory
