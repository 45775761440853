import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
} from "reactstrap";

import CommonModal from './common';

import common_data_init_state from "../model/common"

import macd_data_init_state from "../model/macd"

function MACD(props) {


  const [common_data, set_common_data] = React.useState(common_data_init_state);

  const updateCommonData = (newData) => {
    set_common_data(newData);
  };

  const [edit_data, set_edit_data] = React.useState(null);

  const { strategyID, strategy, symbol, symbol_code, exchange, exchange_code, day, month_name, month_value, year, option_type, option_gap, trade_type, order_type, timeframe,
    stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
    squareoff_time
  } = common_data;

  const [macd_data, set_macd_data] = React.useState(macd_data_init_state);

  const { macd_fast_ema, macd_slow_ema, macd_sma, macd_applied_price, macd_entry_type, macd_above, macd_below } = macd_data;

  const changeHandler = (e) => {

    //macd
    if (e.target.name == "macd_period") {
      set_macd_data({ ...macd_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "macd_applied_price") {
      set_macd_data({ ...macd_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "macd_entry_type") {
      set_macd_data({ ...macd_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "macd_above") {
      set_macd_data({ ...macd_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "macd_below") {
      set_macd_data({ ...macd_data, [e.target.name]: e.target.value });
    }

  };

  function Submit() {

    //debugger;

    var input = { ...common_data, ...macd_data };

    input.strategy = props.strategy;

    input.strategyID = props.strategyID;

    input.strategy_name = props.strategy_name;

    input.strategy_type = props.strategy_type;

    //alert(JSON.stringify(input));

    if (macd_entry_type == "" || macd_fast_ema == "" || macd_slow_ema == "" || macd_sma == "" || macd_applied_price == "") {
      SetapiError("Enter All Required Fields !!!")
      return;
    }

    if (macd_entry_type == "above_below" && (macd_above == "" || macd_below == "")) {
      SetapiError("Enter All Required Fields !!!")
      return;
    }

    if (input.strategy_type == "NORMAL") {
      if (input.strategy == "" || symbol == "" || symbol_code == "" || exchange == "" || exchange_code == "" || qty == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }
    }
    else if (input.strategy_type == "OPTIONS") {
      if (input.strategy == "" || symbol == "" || month_name == "" || month_value == "" || year == "" || qty == "" || option_type == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }

      if (option_type != "ATM" && option_gap == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }
    }

    if (input.strategyID == "") {
      props.AddStrategy(input);
      props.setmodal_standard(false);
    } else {
      props.UpdateStrategy(input);
      props.setmodal_standard(false);
    }

  }

  useEffect(() => {

    if (props.editData) {

      var item = props.editData;

      const { strategyID, strategy, symbol, symbol_code, exchange, exchange_code, trade_type, order_type, timeframe,
        stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
        squareoff_time, ...rest
      } = item;

      set_edit_data({
        strategyID, strategy, symbol, symbol_code, exchange, exchange_code, trade_type, order_type, timeframe,
        stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
        squareoff_time
      });

      set_macd_data(rest);

    }
    else {
      set_macd_data(macd_data_init_state);
    }
  }, [props.editData]);


  const [apiSuccess, SetapiSuccess] = useState(null)

  const [apiError, SetapiError] = useState(null)

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => SetapiSuccess(null), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => SetapiError(null), 1000)
    }
  }, [apiError]);

  return (
    <div>
      <Row>
        <CommonModal edit_data={edit_data} strategy={props.strategy} strategy_type={props.strategy_type} strategy_name={props.strategy_name} updateCommonData={updateCommonData} />

        <fieldset className="border border-light border-2 rounded-3 p-2 mb-2">
          <legend className="float-none w-auto px-3 fs-6 fw-bold">
            MACD Properties
          </legend>

          <Row>

            <Col className="mb-2" md={6}>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  value={macd_fast_ema}
                  name="macd_fast_ema"
                  onChange={changeHandler}
                  required={true} />
                <label htmlFor="floatingSelectGrid">Fast Ema</label>
              </div>
            </Col>

            <Col className="mb-2" md={6}>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  value={macd_slow_ema}
                  name="macd_slow_ema"
                  onChange={changeHandler}
                  required={true} />
                <label htmlFor="floatingSelectGrid">Slow Ema</label>
              </div>
            </Col>

            <Col className="mb-2" md={6}>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  value={macd_sma}
                  name="macd_sma"
                  onChange={changeHandler}
                  required={true} />
                <label htmlFor="floatingSelectGrid">SMA</label>
              </div>
            </Col>

            <Col className="mb-2" md={6}>
              <div className="form-floating mb-3">
                <select value={macd_applied_price} name="macd_applied_price" required={true} className="form-select" onChange={changeHandler}>
                  <option value="">SELECT</option>
                  <option value="open">OPEN</option>
                  <option value="high">HIGH</option>
                  <option value="low">LOW</option>
                  <option value="close">CLOSE</option>
                </select>
                <label htmlFor="floatingSelectGrid">Applied Price</label>
              </div>
            </Col>

            <Col className="mb-2" md={6}>
              <div className="form-floating mb-3">
                <select value={macd_entry_type} name="macd_entry_type" required={true} className="form-select" onChange={changeHandler}>
                  <option value="">SELECT</option>
                  <option value="above_below">ABOVE/BELOW</option>
                  <option value="crossover">CROSSOVER</option>
                </select>
                <label htmlFor="floatingSelectGrid">Entry Type</label>
              </div>
            </Col>

            {macd_entry_type == "above_below" &&
              <>
                <Col className="mb-2" md={6}>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="number"
                      value={macd_above}
                      name="macd_above"
                      onChange={changeHandler}
                      required={true} />
                    <label htmlFor="floatingSelectGrid">Buy Above</label>
                  </div>
                </Col>

                <Col className="mb-2" md={6}>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="number"
                      value={macd_below}
                      name="macd_below"
                      onChange={changeHandler}
                      required={true} />
                    <label htmlFor="floatingSelectGrid">Sell Below</label>
                  </div>
                </Col>

              </>
            }

          </Row>

        </fieldset>


        <Col className="mb-2" md={12}>
          <button type="button" onClick={() => { Submit() }} className="btn btn-primary float-end">
            Add Strategy
          </button>
        </Col>
        {
          apiSuccess && (
            <div
              className="alert alert-success text-center mb-0"
              role="alert">
              {apiSuccess}
            </div>
          )
        }

        {
          apiError && (
            <div
              className="alert alert-danger text-center mb-0"
              role="alert">
              {apiError}
            </div>
          )
        }
      </Row>
    </div>
  );
}

export default MACD;
