import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_FOLLOW_REQUESTS,
  FOLLOW_REQUESTS_EVENTS,
  GET_FOLLOW_CLIENTS,
  FOLLOW_CLIENTS_EVENTS,
  FOLLOW_CLIENTS_RENEWAL,
} from "./actionTypes";
import {
  getFollowRequestsSuccessful,
  getFollowRequestsFailed,
  followRequestsEventsSuccessful,
  followRequestsEventsFailed,
  getFollowClientsSuccessful,
  getFollowClientsFailed,
  followClientsEventsSuccessful,
  followClientsEventsFailed,
  followClientsRenewalSuccessful,
  followClientsRenewalFailed
} from "./actions";

import {
  getFollowRequestsCall, followRequestsEventsCall, getFollowClientsCall,
  followClientsEventsCall, followClientsRenewalCall,
} from "../../../helpers/backend_helper";

function* getFollowRequests({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getFollowRequestsCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));

    // var output = {
    //   "result": "success",
    //   "data": [
    //     {
    //       "name": "PARVEEN RANA",
    //       "userKey": "JDHFJDFHJSDH",
    //       "mobile": "9555455557",
    //       "created_at": "20-08-2022 10:00AM",
    //       "active": "true"
    //     },
    //     {
    //       "name": "NOWSATH",
    //       "userKey": "JDHFJDFHJSDF",
    //       "mobile": "8190071452",
    //       "created_at": "20-08-2022 10:00AM",
    //       "active": "true"
    //     }
    //   ]
    // }

    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getFollowRequestsSuccessful(output.data))
    }
    else {
      yield put(getFollowRequestsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getFollowRequestsFailed(error.message));
  }
}

function* followRequestsEvents({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(followRequestsEventsCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(followRequestsEventsSuccessful(output.message))
    }
    else {
      yield put(followRequestsEventsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(followRequestsEventsFailed(error.message));
  }
}

function* getFollowClients({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getFollowClientsCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));

    // var output = {
    //   "result": "success",
    //   "data": [
    //     {
    //       "name": "PARVEEN RANA",
    //       "userKey": "JDHFJDFHJSDH",
    //       "mobile": "9555455557",
    //       "start_at": "20-08-2022 10:00AM",
    //       "end_at": "20-09-2022 10:00AM",
    //       "days_left": "23",
    //       "active": "true"
    //     },
    //     {
    //       "name": "NOWSATH",
    //       "userKey": "JDHFJDFHJSDF",
    //       "mobile": "8190071452",
    //       "start_at": "20-08-2022 10:00AM",
    //       "end_at": "20-09-2022 10:00AM",
    //       "days_left": "23",
    //       "active": "false"
    //     }
    //   ]
    // }

    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getFollowClientsSuccessful(output.data))
    }
    else {
      yield put(getFollowClientsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getFollowClientsFailed(error.message));
  }
}

function* followClientsEvents({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(followClientsEventsCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(followClientsEventsSuccessful(output.message))
    }
    else {
      yield put(followClientsEventsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(followClientsEventsFailed(error.message));
  }
}

function* followClientsRenewal({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(followClientsRenewalCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(followClientsRenewalSuccessful(output.message))
    }
    else {
      yield put(followClientsRenewalFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(followClientsRenewalFailed(error.message));
  }
}

function* myClientsSaga() {
  yield takeEvery(GET_FOLLOW_REQUESTS, getFollowRequests);
  yield takeEvery(FOLLOW_REQUESTS_EVENTS, followRequestsEvents);
  yield takeEvery(GET_FOLLOW_CLIENTS, getFollowClients);
  yield takeEvery(FOLLOW_CLIENTS_EVENTS, followClientsEvents);
  yield takeEvery(FOLLOW_CLIENTS_RENEWAL, followClientsRenewal);
}

export default myClientsSaga;
