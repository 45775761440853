import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Tooltip,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
} from "reactstrap";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

const Disclaimer = props => {

    //meta title
    document.title = "Disclaimer | Algobridge ";

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    const [modal_backdrop, setmodal_backdrop] = useState(false);

    const [session, Setsession] = useState(null);

    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function AgreeDisclaimer() {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {};

        obj.email = email;

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/agreeDisclaimer', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                setmodal_backdrop(false);

                var authUser = localStorage.getItem("authUser");
                if (authUser) {
                    authUser = atob(authUser);
                    authUser = JSON.parse(authUser);
                    authUser.DisclaimerAgree = "true";
                    localStorage.setItem("authUser", btoa(JSON.stringify(authUser)));
                }

                props.history.push("/marketwatch");

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    useEffect(() => {

        var authUser = localStorage.getItem("authUser");
        if (authUser) {
            authUser = atob(authUser);
            Setsession(JSON.parse(authUser));
        }
        else {
            history.push("/login");
        }

    }, []);

    useEffect(() => {

        if (session) {
            //alert(session);
            setmodal_backdrop(true);
        }

    }, [session]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Modal
                        size="lg"
                        isOpen={modal_backdrop}
                        toggle={() => {
                            tog_backdrop();
                        }}
                        backdrop={'static'}
                        id="staticBackdrop"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Disclaimer</h5>
                        </div>
                        <div className="modal-body">

                            {(session && session.createdBy == "Register") && (
                                <>
                                    <p><strong>Disclaimer for Seven ticks software:</strong></p>
                                    <p>If you require any more information or have any questions about our site&apos;s disclaimer, please feel free to contact us by email at <a target="_blank" rel="noreferrer" href="mailto:seventicks@gmail.com">seventicks@gmail.com</a>.&nbsp;</p>
                                    <p><strong>Trading in the Stock Market is Risky:</strong></p>
                                    <p>Stock &amp; Commodity trading is inherently risky &amp; the users agree to assume complete responsibility for the outcomes of all trading decisions that they make, including but not limited to loss of capital. By using our platform (algobridge) users can trade at their own risk. Neither Algobridge nor any of the representatives are responsible for any loss that occurs by using algobridge.</p>
                                    <p>As you know that you are using an app is providing the service of copy trading. We strongly suggest you do not provide any of your confidential information such as Trading account Passwords, pins, etc. Our company never asks the sensitive information like your account password or your trading account details (password, pin) to be stored on our platform.</p>
                                    <p><strong>Disclaimer for using Algobridge:</strong></p>
                                    <p>All the information on this website - <a target="_blank" rel="noreferrer" href="//www.algobridge.in">www.algobridge.in</a> - is published in good faith and for general information purposes only. Algo Bridge does not make any warranties about the completeness, reliability, and accuracy of this information. Any action you take upon the information you find on this website (algobridge), is strictly at your own risk. Algo Bridge will not be liable for any losses and/or damages in connection with the use of our website.</p>
                                    <p>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link that may have gone &apos;bad&apos;.</p>
                                    <p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms that are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their &quot;Terms of Service&quot; before engaging in any business or uploading any information.</p>
                                    <p><strong>Consent</strong></p>
                                    <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>
                                </>
                            )}

                            {(session && session.createdBy == "Master") && (
                                <>
                                    <p><strong>Agreement to Use of Platform:&nbsp;</strong></p>
                                    <p>&nbsp;Hi <strong>{session.name}</strong>, Your Account is being created by <strong>{session.MasterInfo.name + ", " + session.MasterInfo.email + ", " + session.MasterInfo.mobile}</strong></p>
                                    <p><strong>How copy trading works?</strong></p>
                                    <p>By using our copy trading platform <strong>(Algobridge)</strong>, you acknowledge and agree that the master traders who create accounts for clients are not engaging in automated trading. Instead, they manually place trades based on their own strategies and analysis. Your account was created by a specific master trader, who may provide trading signals or other advice, but ultimately you are solely responsible for any trading decisions you make.</p>
                                    <p><strong>Disclaimer:</strong></p>
                                    <p>You should be aware that trading involves risk, and you should carefully consider your financial situation and investment goals before using our platform. Past performance is not necessarily indicative of future results, and any trading activity is subject to market conditions and other factors outside of the control of the master traders or our platform.</p>
                                    <p>By using our platform, you agree to indemnify and hold harmless the master traders, our company, and our affiliates from any losses, damages, or liabilities incurred as a result of your trading activity. You further acknowledge that any information or advice provided by the master traders is for educational and informational purposes only, and should not be construed as investment advice or a guarantee of profits.</p>
                                    <p><strong>Legal</strong>:&nbsp;</p>
                                    <p>Certainly, it is important to note that our company is not registered with the Securities and Exchange Board of India (SEBI) as an investment advisor or a Robo-advisor. We are a software development company that provides a platform for master traders to offer their trading services to clients.</p>
                                    <p><strong>Fees and Refunds:&nbsp;</strong></p>
                                    <p>Additionally, please note that our platform does not charge any fees or commissions for clients of the master traders. The master trader who created your account is solely responsible for any fees or charges associated with their trading services. As such, our platform is not responsible for any refunds or disputes related to fees charged by the master trader.</p>
                                    <p>Please ensure that you fully understand the risks involved in trading and that you are comfortable with the trading strategies employed by the master trader who created your account. If you have any questions or concerns, please do not hesitate to contact our customer support team for assistance.</p>
                                    <p>While we strive to ensure the quality and reliability of our platform, we do not provide any investment advice or recommendations ourselves. Any decisions regarding trading activity should be based on your own research, analysis, and risk assessment. We encourage all users to seek independent financial advice before making any investment decisions.</p>
                                    <p></p>
                                    <p>&nbsp;</p>
                                    <p>Thank you for allowing us to clarify these important details. If you have any further questions or concerns, please feel free to reach out to us.</p>
                                    <p>By using our platform, you agree to comply with our security policies and take all necessary precautions to protect your account information. If you have any concerns or questions about our security measures, please contact our support team.</p>
                                    <p>&nbsp;</p>
                                </>
                            )}

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger">Not Agree</button>
                            <button type="button" className="btn btn-success" onClick={() => {
                                //setmodal_backdrop(false);
                                AgreeDisclaimer();
                            }}>Agree</button>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>);
}

export default Disclaimer;