import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Label, Input, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

//import images
import adobe from "../../../assets/images/companies/img-1.png";
import adobephotoshop from "../../../assets/images/companies/img-1.png";
import airbnb from "../../../assets/images/companies/img-1.png";
import amazon from "../../../assets/images/companies/img-1.png";
import flutter from "../../../assets/images/companies/img-1.png";
import mailchimp from "../../../assets/images/companies/img-1.png";
import line from "../../../assets/images/companies/img-1.png";
import spotify from "../../../assets/images/companies/img-1.png";
import wordpress from "../../../assets/images/companies/img-1.png";
import upwork from "../../../assets/images/companies/img-1.png";
import sass from "../../../assets/images/companies/img-1.png";
import reddit from "../../../assets/images/companies/img-1.png";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

const Strategies = () => {
    const [modal, setModal] = useState(false);

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    const [Strategies, SetStrategies] = useState(null);

    function GetStrategies() {
        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(API_URL + '/user/getStrategies', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetStrategies(output.data);

            }
        });
    }

    useEffect(() => {
        GetStrategies();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        {(Strategies || []).map((item, key) => (
                            <Col xl={3} md={6} key={key}>
                                <Card>
                                    <CardBody>
                                        <div className="favorite-icon">
                                            <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                        </div>
                                        <img src={require('../../../assets/images/companies/'+item.img_path)} alt="" height="50" className="mb-3" />
                                        <h5 className="fs-17 mb-2">
                                            <Link to="/job-details" className="text-dark">{item.name}</Link></h5>
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <p className="text-muted fs-14 mb-1">{item.type}</p>
                                            </li>{" "}
                                            <li className="list-inline-item">
                                                <p className="text-muted fs-14 mb-0"><i className="uil uil-wallet"></i> {item.amount + " INR"} / day</p>
                                            </li>
                                        </ul>
                                        <div className="mt-3 hstack gap-2">
                                            {(item.options || []).map((option, index) => (
                                                <>
                                                    <span key={index} className={"badge rounded-1 p-1 " + item.options_classes[index]}>{option}</span>
                                                </>
                                            ))}
                                        </div>
                                        <div className="mt-4 hstack gap-2">
                                            <button type="button" className="btn btn-outline-success w-100">Watch View</button>
                                            <button type="button" className="btn btn-outline-primary w-100">Buy Now</button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default Strategies;