import React, { useEffect, useState, useRef } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    InputGroup,
    Button
} from "reactstrap";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import classnames from "classnames";

import axios from "axios";

import { API_URL } from "helpers/api_helper";


const TimeframesData = [
    {
        name: "M1",
        value: 1,
        start_time: "9:15",
        end_time: "9:16"
    },
    {
        name: "M3",
        value: 3,
        start_time: "9:15",
        end_time: "9:18"
    },
    {
        name: "M5",
        value: 5,
        start_time: "9:15",
        end_time: "9:20"
    },
    {
        name: "M10",
        value: 10,
        start_time: "9:15",
        end_time: "9:25"
    },
    {
        name: "M15",
        value: 15,
        start_time: "9:15",
        end_time: "9:30"
    },
    {
        name: "M30",
        value: 30,
        start_time: "9:15",
        end_time: "09:45"
    }
];

var nse_symbol_data = [
    {
        "trading_symbol": "NIFTY",
        "code": 26000,
        "lot_size": 50,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BANKNIFTY",
        "code": 26009,
        "lot_size": 25,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "FINNIFTY",
        "code": 26037,
        "lot_size": 40,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "AARTIIND",
        "code": 7,
        "lot_size": 850,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ABB",
        "code": 13,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ABBOTINDIA",
        "code": 17903,
        "lot_size": 40,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ABCAPITAL",
        "code": 21614,
        "lot_size": 5400,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ABFRL",
        "code": 30108,
        "lot_size": 2600,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ACC",
        "code": 22,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ADANIENT",
        "code": 25,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ADANIPORTS",
        "code": 15083,
        "lot_size": 625,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ALKEM",
        "code": 11703,
        "lot_size": 200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "AMARAJABAT",
        "code": 100,
        "lot_size": 1000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "AMBUJACEM",
        "code": 1270,
        "lot_size": 1800,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "APOLLOHOSP",
        "code": 157,
        "lot_size": 125,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "APOLLOTYRE",
        "code": 163,
        "lot_size": 3500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ASHOKLEY",
        "code": 212,
        "lot_size": 5000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ASIANPAINT",
        "code": 236,
        "lot_size": 200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ASTRAL",
        "code": 14418,
        "lot_size": 275,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ATUL",
        "code": 263,
        "lot_size": 75,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "AUBANK",
        "code": 21238,
        "lot_size": 1000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "AUROPHARMA",
        "code": 275,
        "lot_size": 1000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "AXISBANK",
        "code": 5900,
        "lot_size": 1200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BAJAJ-AUTO",
        "code": 16669,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BAJAJFINSV",
        "code": 16675,
        "lot_size": 500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BAJFINANCE",
        "code": 317,
        "lot_size": 125,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BALKRISIND",
        "code": 335,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BALRAMCHIN",
        "code": 341,
        "lot_size": 1600,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BANDHANBNK",
        "code": 2263,
        "lot_size": 1800,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BANKBARODA",
        "code": 4668,
        "lot_size": 5850,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BATAINDIA",
        "code": 371,
        "lot_size": 275,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BEL",
        "code": 383,
        "lot_size": 5700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BERGEPAINT",
        "code": 404,
        "lot_size": 1100,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BHARATFORG",
        "code": 422,
        "lot_size": 1000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BHARTIARTL",
        "code": 10604,
        "lot_size": 950,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BHEL",
        "code": 438,
        "lot_size": 10500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BIOCON",
        "code": 11373,
        "lot_size": 2300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BOSCHLTD",
        "code": 2181,
        "lot_size": 50,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BPCL",
        "code": 526,
        "lot_size": 1800,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BRITANNIA",
        "code": 547,
        "lot_size": 200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "BSOFT",
        "code": 6994,
        "lot_size": 2000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CANBK",
        "code": 10794,
        "lot_size": 2700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CANFINHOME",
        "code": 583,
        "lot_size": 975,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CHAMBLFERT",
        "code": 637,
        "lot_size": 1500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CHOLAFIN",
        "code": 685,
        "lot_size": 1250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CIPLA",
        "code": 694,
        "lot_size": 650,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "COALINDIA",
        "code": 20374,
        "lot_size": 4200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "COFORGE",
        "code": 11543,
        "lot_size": 150,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "COLPAL",
        "code": 15141,
        "lot_size": 350,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CONCOR",
        "code": 4749,
        "lot_size": 1000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "COROMANDEL",
        "code": 739,
        "lot_size": 700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CROMPTON",
        "code": 17094,
        "lot_size": 1500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CUB",
        "code": 5701,
        "lot_size": 5000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "CUMMINSIND",
        "code": 1901,
        "lot_size": 600,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "DABUR",
        "code": 772,
        "lot_size": 1250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "DALBHARAT",
        "code": 8075,
        "lot_size": 500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "DEEPAKNTR",
        "code": 15044,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "DELTACORP",
        "code": 15044,
        "lot_size": 2300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "DIVISLAB",
        "code": 10940,
        "lot_size": 150,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "DIXON",
        "code": 21690,
        "lot_size": 125,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "DLF",
        "code": 14732,
        "lot_size": 1650,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "DRREDDY",
        "code": 881,
        "lot_size": 125,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "EICHERMOT",
        "code": 910,
        "lot_size": 175,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ESCORTS",
        "code": 958,
        "lot_size": 275,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "EXIDEIND",
        "code": 676,
        "lot_size": 3600,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "FEDERALBNK",
        "code": 1023,
        "lot_size": 5000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "FSL",
        "code": 14304,
        "lot_size": 5200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GAIL",
        "code": 4717,
        "lot_size": 9150,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GLENMARK",
        "code": 7406,
        "lot_size": 1450,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GMRINFRA",
        "code": 13528,
        "lot_size": 22500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GNFC",
        "code": 1174,
        "lot_size": 1300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GODREJCP",
        "code": 10099,
        "lot_size": 1000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GODREJPROP",
        "code": 17875,
        "lot_size": 425,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GRANULES",
        "code": 11872,
        "lot_size": 2000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GRASIM",
        "code": 1232,
        "lot_size": 475,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "GUJGASLTD",
        "code": 10599,
        "lot_size": 1250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HAL",
        "code": 2303,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HAVELLS",
        "code": 9819,
        "lot_size": 500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HCLTECH",
        "code": 7229,
        "lot_size": 700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HDFC",
        "code": 1330,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HDFCAMC",
        "code": 4244,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HDFCBANK",
        "code": 1333,
        "lot_size": 550,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HDFCLIFE",
        "code": 467,
        "lot_size": 1100,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HEROMOTOCO",
        "code": 1348,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HINDALCO",
        "code": 1363,
        "lot_size": 1400,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HINDCOPPER",
        "code": 17939,
        "lot_size": 4300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HINDPETRO",
        "code": 1406,
        "lot_size": 2700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HINDUNILVR",
        "code": 1394,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "HONAUT",
        "code": 3417,
        "lot_size": 15,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IBULHSGFIN",
        "code": 30125,
        "lot_size": 4000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ICICIBANK",
        "code": 4963,
        "lot_size": 700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ICICIGI",
        "code": 21770,
        "lot_size": 425,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ICICIPRULI",
        "code": 18652,
        "lot_size": 1500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IDEA",
        "code": 14366,
        "lot_size": 70000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IDFC",
        "code": 11957,
        "lot_size": 10000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IDFCFIRSTB",
        "code": 11184,
        "lot_size": 15000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IEX",
        "code": 220,
        "lot_size": 3750,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IGL",
        "code": 11262,
        "lot_size": 1375,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "INDHOTEL",
        "code": 1512,
        "lot_size": 2000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "INDIACEM",
        "code": 1515,
        "lot_size": 2900,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "INDIAMART",
        "code": 10726,
        "lot_size": 150,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "INDIGO",
        "code": 11195,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "INDUSINDBK",
        "code": 5258,
        "lot_size": 450,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "INDUSTOWER",
        "code": 29135,
        "lot_size": 2800,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "INFY",
        "code": 1594,
        "lot_size": 400,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "INTELLECT",
        "code": 5926,
        "lot_size": 750,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IOC",
        "code": 1624,
        "lot_size": 9750,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IPCALAB",
        "code": 1633,
        "lot_size": 650,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "IRCTC",
        "code": 13611,
        "lot_size": 875,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ITC",
        "code": 1660,
        "lot_size": 1600,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "JINDALSTEL",
        "code": 6733,
        "lot_size": 1250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "JKCEMENT",
        "code": 11723,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "JSWSTEEL",
        "code": 11723,
        "lot_size": 1350,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "JUBLFOOD",
        "code": 18096,
        "lot_size": 1250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "KOTAKBANK",
        "code": 1922,
        "lot_size": 400,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "LALPATHLAB",
        "code": 11654,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "LAURUSLABS",
        "code": 19234,
        "lot_size": 1100,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "LICHSGFIN",
        "code": 1997,
        "lot_size": 2000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "LT",
        "code": 11483,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "L&TFH",
        "code": 24948,
        "lot_size": 8924,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "LTIM",
        "code": 17818,
        "lot_size": 150,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "LTTS",
        "code": 18564,
        "lot_size": 200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "LUPIN",
        "code": 10440,
        "lot_size": 850,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MANAPPURAM",
        "code": 19061,
        "lot_size": 6000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MARICO",
        "code": 4067,
        "lot_size": 1200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MARUTI",
        "code": 10999,
        "lot_size": 100,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MCDOWELL-N",
        "code": 10447,
        "lot_size": 625,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MCX",
        "code": 31181,
        "lot_size": 400,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "METROPOLIS",
        "code": 9581,
        "lot_size": 400,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MFSL",
        "code": 2142,
        "lot_size": 650,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MGL",
        "code": 17534,
        "lot_size": 800,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "M&M",
        "code": 2031,
        "lot_size": 700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "M&MFIN",
        "code": 13285,
        "lot_size": 4000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MOTHERSON",
        "code": 4204,
        "lot_size": 6750,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MPHASIS",
        "code": 4503,
        "lot_size": 175,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MRF",
        "code": 2277,
        "lot_size": 10,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "MUTHOOTFIN",
        "code": 23650,
        "lot_size": 375,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "NATIONALUM",
        "code": 6364,
        "lot_size": 7500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "NAUKRI",
        "code": 13751,
        "lot_size": 125,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "NAVINFLUOR",
        "code": 14672,
        "lot_size": 150,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "NESTLEIND",
        "code": 17963,
        "lot_size": 40,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "NMDC",
        "code": 15332,
        "lot_size": 4500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "NTPC",
        "code": 11630,
        "lot_size": 5700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "OBEROIRLTY",
        "code": 20242,
        "lot_size": 700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "OFSS",
        "code": 10738,
        "lot_size": 200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ONGC",
        "code": 2475,
        "lot_size": 3850,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PAGEIND",
        "code": 14413,
        "lot_size": 15,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PEL",
        "code": 2412,
        "lot_size": 550,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PERSISTENT",
        "code": 18365,
        "lot_size": 150,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PETRONET",
        "code": 11351,
        "lot_size": 3000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PFC",
        "code": 14299,
        "lot_size": 6200,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PIDILITIND",
        "code": 2664,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PIIND",
        "code": 24184,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PNB",
        "code": 10666,
        "lot_size": 16000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "POLYCAB",
        "code": 9590,
        "lot_size": 300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "POWERGRID",
        "code": 14977,
        "lot_size": 2700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "PVR",
        "code": 13147,
        "lot_size": 407,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "RAIN",
        "code": 15337,
        "lot_size": 3500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "RAMCOCEM",
        "code": 2043,
        "lot_size": 850,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "RBLBANK",
        "code": 18391,
        "lot_size": 5000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "RECLTD",
        "code": 15355,
        "lot_size": 8000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "RELIANCE",
        "code": 2885,
        "lot_size": 250,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SAIL",
        "code": 2963,
        "lot_size": 8000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SBICARD",
        "code": 17971,
        "lot_size": 800,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SBILIFE",
        "code": 21808,
        "lot_size": 750,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SBIN",
        "code": 3045,
        "lot_size": 1500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SHREECEM",
        "code": 3103,
        "lot_size": 25,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SIEMENS",
        "code": 3150,
        "lot_size": 275,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SRF",
        "code": 3273,
        "lot_size": 375,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SRTRANSFIN",
        "code": 4306,
        "lot_size": 600,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SUNPHARMA",
        "code": 3351,
        "lot_size": 700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SUNTV",
        "code": 13404,
        "lot_size": 1500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "SYNGENE",
        "code": 10243,
        "lot_size": 1000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TATACHEM",
        "code": 3405,
        "lot_size": 500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TATACOMM",
        "code": 3721,
        "lot_size": 500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TATACONSUM",
        "code": 3432,
        "lot_size": 900,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TATAMOTORS",
        "code": 3456,
        "lot_size": 1425,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TATAPOWER",
        "code": 3426,
        "lot_size": 3375,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TATASTEEL",
        "code": 3499,
        "lot_size": 5500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TCS",
        "code": 11536,
        "lot_size": 175,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TECHM",
        "code": 13538,
        "lot_size": 600,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TITAN",
        "code": 3506,
        "lot_size": 375,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TORNTPHARM",
        "code": 3518,
        "lot_size": 500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TORNTPOWER",
        "code": 13786,
        "lot_size": 1500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TRENT",
        "code": 1964,
        "lot_size": 400,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "TVSMOTOR",
        "code": 8479,
        "lot_size": 700,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "UBL",
        "code": 16713,
        "lot_size": 400,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ULTRACEMCO",
        "code": 11532,
        "lot_size": 100,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "UPL",
        "code": 11287,
        "lot_size": 1300,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "VEDL",
        "code": 3063,
        "lot_size": 1550,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "VOLTAS",
        "code": 3718,
        "lot_size": 600,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "WHIRLPOOL",
        "code": 18011,
        "lot_size": 350,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "WIPRO",
        "code": 3787,
        "lot_size": 1500,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ZEEL",
        "code": 3812,
        "lot_size": 3000,
        "exchange": "NSE"
    },
    {
        "trading_symbol": "ZYDUSLIFE",
        "code": 7929,
        "lot_size": 1800,
        "exchange": "NSE"
    }
]

let temp_positive = [];
let temp_negative = [];

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

const OptionStrategies = (() => {
    //meta title
    document.title = "Option Strategies | Algobridge";


    const table_style = {
        border: "none",
        textAlign: "center"
    }

    const call_heading_style = {
        backgroundColor: "Lime",
        color: "White",
        border: "none",
        textAlign: "center",
        padding: "10px"
    }

    const put_heading_style = {
        backgroundColor: "Red",
        color: "White",
        border: "none",
        textAlign: "center",
        padding: "10px"
    }


    //store chaindata
    const [originalChainData, SetoriginalChainData] = useState(null)

    const [filterchainData, SetfilterchainData] = useState(null)

    useEffect(() => {
        LoadChainData(symbol.exchange, symbol.token);
    }, []);

    function LoadChainData(exchange, token) {

        const headers = {
            'Content-Type': 'application/json'
        };

        var url = API_URL + "/trading/getOptionChainData/" + exchange + "/" + token + "/" + count;

        axios.get(url, { headers }).then(response => {
            //console.log(response)
            var response = response.data;

            if (response.result == "success") {
                //var temp = response.data;
                //console.log(temp.length);

                localStorage.removeItem("optionstrategies_record");

                SetoriginalChainData(response.data);
            }
            else {
                SetapiError(response.message);

                SetoriginalChainData(null);
            }

        });

    }

    async function FilterChainData() {

        if (originalChainData) {

            try {

                //console.log("symbolExpiry=" + symbolExpiry);

                //debugger;

                var filterData = await originalChainData.filter(function (el) {
                    return el.expiry_date == symbolExpiry;
                });

                //console.log(JSON.stringify(filterData));            
                //console.log(filterData.length);

                if (filterData.length > 0) {

                    // var output = [{
                    //     "expiry_date": filterData[0].expiry_date
                    // }];

                    // var filterStrikes = [];

                    // if (ltp_range_from > 0 && ltp_range_to > 0) {
                    //     filterStrikes = await filterData[0].strikes.filter(function (item) {
                    //         var from = ltp_range_from;
                    //         var to = ltp_range_to;
                    //         if (ltp_range_from > ltp_range_to) {
                    //             from = ltp_range_to;
                    //             to = ltp_range_from;
                    //         }
                    //         if (item.call_option) {
                    //             return item.call_option.ltp >= from && item.call_option.ltp <= to
                    //         }
                    //         if (item.put_option) {
                    //             return item.put_option.ltp >= from && item.put_option.ltp <= to
                    //         }
                    //     });

                    //     if (filterStrikes.length > 0) {
                    //         output[0].strikes = filterStrikes;
                    //         SetfilterchainData(output);
                    //     }
                    //     else {
                    //         SetfilterchainData(null);
                    //         localStorage.removeItem("optionstrategies_record");
                    //     }

                    // } else {
                    //     SetfilterchainData(filterData);
                    // }

                    SetfilterchainData(filterData);

                }
                else {
                    SetfilterchainData(null);
                    localStorage.removeItem("optionstrategies_record");
                }

            } catch (error) {
                console.log("FilterChainData");
                console.log(error);
            }

        }
    }

    useEffect(() => {
        if (originalChainData) {
            try {
                var expiry_date = [];
                for (var i = 0; i < originalChainData.length; i++) {
                    expiry_date.push(originalChainData[i].expiry_date);
                    if (i == 5) break;
                }
                SetexpiryData(expiry_date);

                var strike_data = [];
                var single_date_data = originalChainData[0].strikes;
                for (var i = 0; i < single_date_data.length; i++) {
                    strike_data.push(single_date_data[i].strike_price);
                }
                SetstrikeData(strike_data);

                SetsymbolExpiry(originalChainData[0].expiry_date);

                //FilterChainData();

            }
            catch (error) {
                console.log("useEffect expiry");
                console.log(error);
            }
        }
    }, [originalChainData]);


    //api response
    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    useEffect(() => {
        if (apiSuccess) {
            setTimeout(() => SetapiSuccess(null), 2000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError) {
            setTimeout(() => SetapiError(null), 2000)
        }
    }, [apiError]);


    //input variables
    const [symbol, Setsymbol] = useState({
        exchange: "NSE",
        symbol: "NIFTY",
        token: "26000",
    })

    const [expiryData, SetexpiryData] = useState(null)

    const [count, Setcount] = useState(18)

    const [strikeData, SetstrikeData] = useState(null)

    const [symbolExpiry, SetsymbolExpiry] = useState("")

    const [timeframe, Settimeframe] = useState("M1")

    const [timeframe_start_time, Settimeframe_start_time] = useState("9:15")

    const [timeframe_end_time, Settimeframe_end_time] = useState("9:16")

    const [percentage_range_from, SetPercentageRangeFrom] = useState(0)
    const [percentage_range_to, SetPercentageRangeTo] = useState(0)

    const [ltp_range_from, SetLtpRangeFrom] = useState(0)
    const [ltp_range_to, SetLtpRangeTo] = useState(0)

    function handleSymbolChange(exchange, symbol, token) {
        //console.log("exchange=" + exchange + ",symbol=" + symbol + ",token=" + token);
        Setsymbol({
            exchange: exchange,
            symbol: symbol,
            token: token
        });
        LoadChainData(exchange, token);
    }

    function changeOptions(data, event) {
        //console.log("data=" + data + ",event=" + event);
        if (event == "change-expiry") {
            SetsymbolExpiry(data);
        }
    }


    useEffect(() => {
        if (symbolExpiry != "") {
            FilterChainData();
        }
    }, [symbolExpiry]);

    useEffect(() => {
        if (ltp_range_from > 0 && ltp_range_to > 0) {
            CheckMatchedCandles();
        } else {
            CheckMatchedCandles();
        }
    }, [ltp_range_from, ltp_range_to]);



    function UpdateTimeframeChange(e) {

        var value = e.target.value;

        var start_time = e.target.selectedOptions[0].getAttribute('data-starttime');

        var end_time = e.target.selectedOptions[0].getAttribute('data-endtime');

        Settimeframe_start_time(start_time);
        Settimeframe_end_time(end_time);

        Settimeframe(value);


        //alert("value=" + value + ",start_time=" + start_time + ",end_time=" + end_time);

    }


    //cart data
    const [cartData, SetcartData] = useState([])

    function addToCart(strike_price, token, transactionType, strikeType, price, exchange) {

        var symbolName = symbol.symbol + " " + symbolExpiry + " " + strike_price + " " + strikeType;

        // var data = {
        //     id: cartData.length + 1,
        //     strike_price: strike_price,
        //     token: token,
        //     transactionType: transactionType,
        //     strikeType: strikeType,
        //     symbolName: symbolName,
        //     ltp: ltp,
        //     qty: 50,
        //     tradeType: "MIS",
        //     orderType: "MARKET"
        // }

        var tradeObject = {
            id: cartData.length + 1,
            strike_price: strike_price,
            strikeType: strikeType,
            symbolName: symbolName,
            symbol: symbol.symbol,
            transactionType: transactionType,
            qty: 50,
            sessionType: "DAY",
            tradeType: "MIS",
            productType: "MARKET",
            orderType: "REGULAR",
            price: price,
            triggerPrice: "0",
            targetPrice: "0",
            stopPrice: "0",
            token: token,
            exchange: exchange
        }

        cartData.push(tradeObject);

        //console.log(JSON.stringify(cartData));

        SetcartData(cartData);

    }

    function removeFromCart(id) {

        //debugger;

        //var temp = cartData;

        var index = cartData.findIndex(function (item, i) {
            return item.id === id
        });

        cartData.splice(index, 1);

        //SetcartData(cartData);

        //console.log(JSON.stringify(cartData));

    }


    //modal popup
    const [modal_center, setmodal_center] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    //websocket process


    const [socketDataTrigger, SetsocketDataTrigger] = useState(0)

    useEffect(() => {
        socket_io.on("socket-marketdata", (result) => {
            //console.log("data=" + JSON.stringify(result));

            var optionstrategies_record = localStorage.getItem("optionstrategies_record");
            if (optionstrategies_record) {

                optionstrategies_record = JSON.parse(optionstrategies_record);

                var index = optionstrategies_record.findIndex(function (e) { return e.instrumentToken == result.instrumentToken });

                //console.log("index=" + index);

                //debugger;

                if (index == -1) {
                    optionstrategies_record.push(result);
                    optionstrategies_record = JSON.stringify(optionstrategies_record);
                    localStorage.setItem("optionstrategies_record", optionstrategies_record);
                }
                else {
                    optionstrategies_record[index] = result;
                    optionstrategies_record = JSON.stringify(optionstrategies_record);
                    localStorage.setItem("optionstrategies_record", optionstrategies_record);
                }

            }
            else {
                var input = [];
                input.push(result)
                input = JSON.stringify(input);
                localStorage.setItem("optionstrategies_record", input);
            }

            SetsocketDataTrigger(prevCount => prevCount + 1);

        });
    }, []);

    useEffect(() => {
        if (socketDataTrigger > 0) {
            process_fillSockeLiveData();
        }
    }, [socketDataTrigger]);


    function FilterPos(input) {
        const historyPositiveCandleData_temp = input;

        for (var i = 0; i < historyPositiveCandleData_temp.length; i++) {

            //console.log("data.instrumentToken=" + data.instrumentToken);

            var strikesData = historyPositiveCandleData_temp[i].strikes;

            var call_valid = false;
            var put_valid = false;

            for (var j = 0; j < strikesData.length; j++) {

                if (historyPositiveCandleData_temp[i].strikes[j].call_option) {

                    var element = historyPositiveCandleData_temp[i].strikes[j].call_option;

                    if (percentage_range_from > 0 && percentage_range_to > 0 && ltp_range_from > 0 && ltp_range_to > 0) {

                        if (element.changePercentage > 0 && element.changePercentage >= percentage_range_from && element.changePercentage <= percentage_range_to && element.ltp > 0 && element.ltp >= ltp_range_from && element.ltp <= ltp_range_to) {
                            call_valid = true;
                        }
                    }
                    else if (percentage_range_from > 0 && percentage_range_to > 0) {
                        if (element.changePercentage > 0 && element.changePercentage >= percentage_range_from && element.changePercentage <= percentage_range_to) {
                            call_valid = true;
                        }
                    }
                    else if (ltp_range_from > 0 && ltp_range_to > 0) {
                        if (element.ltp > 0 && element.ltp >= ltp_range_from && element.ltp <= ltp_range_to) {
                            call_valid = true;
                        }
                    }
                }

                if (historyPositiveCandleData_temp[i].strikes[j].put_option) {

                    var element = historyPositiveCandleData_temp[i].strikes[j].put_option;

                    if (percentage_range_from > 0 && percentage_range_to > 0 && ltp_range_from > 0 && ltp_range_to > 0) {

                        if (element.changePercentage > 0 && element.changePercentage >= percentage_range_from && element.changePercentage <= percentage_range_to && element.ltp > 0 && element.ltp >= ltp_range_from && element.ltp <= ltp_range_to) {
                            put_valid = true;
                        }
                    }
                    else if (percentage_range_from > 0 && percentage_range_to > 0) {
                        if (element.changePercentage > 0 && element.changePercentage >= percentage_range_from && element.changePercentage <= percentage_range_to) {
                            put_valid = true;
                        }
                    }
                    else if (ltp_range_from > 0 && ltp_range_to > 0) {
                        if (element.ltp > 0 && element.ltp >= ltp_range_from && element.ltp <= ltp_range_to) {
                            put_valid = true;
                        }
                    }

                }

            }

            if (call_valid == false && put_valid == false) {
                delete historyPositiveCandleData_temp[i];
                historyPositiveCandleData_temp.splice(i, 1);  //deleting
            }
        }

        SethistoryPositiveCandleData(historyPositiveCandleData_temp);
    }

    function FilterNeg(input) {
        //const historyNegativeCandleData_temp = [...historyNegativeCandleData];

        const historyNegativeCandleData_temp = input;

        for (var i = 0; i < historyNegativeCandleData_temp.length; i++) {

            //console.log("data.instrumentToken=" + data.instrumentToken);

            var strikesData = historyNegativeCandleData_temp[i].strikes;

            var call_valid = false;
            var put_valid = false;

            for (var j = 0; j < strikesData.length; j++) {

                if (historyNegativeCandleData_temp[i].strikes[j].call_option) {

                    var element = historyNegativeCandleData_temp[i].strikes[j].call_option;

                    if (percentage_range_from > 0 && percentage_range_to > 0 && ltp_range_from > 0 && ltp_range_to > 0) {

                        if (element.changePercentage < 0 && Math.abs(element.changePercentage) >= percentage_range_from && Math.abs(element.changePercentage) <= percentage_range_to && element.ltp > 0 && element.ltp >= ltp_range_from && element.ltp <= ltp_range_to) {
                            call_valid = true;
                        }
                    }
                    else if (percentage_range_from > 0 && percentage_range_to > 0) {
                        if (element.changePercentage < 0 && Math.abs(element.changePercentage) >= percentage_range_from && Math.abs(element.changePercentage) <= percentage_range_to) {
                            call_valid = true;
                        }
                    }
                    else if (ltp_range_from > 0 && ltp_range_to > 0) {
                        if (element.ltp > 0 && element.ltp >= ltp_range_from && element.ltp <= ltp_range_to) {
                            call_valid = true;
                        }
                    }

                }

                if (historyNegativeCandleData_temp[i].strikes[j].put_option) {

                    var element = historyNegativeCandleData_temp[i].strikes[j].put_option;

                    if (percentage_range_from > 0 && percentage_range_to > 0 && ltp_range_from > 0 && ltp_range_to > 0) {

                        if (element.changePercentage < 0 && Math.abs(element.changePercentage) >= percentage_range_from && Math.abs(element.changePercentage) <= percentage_range_to && element.ltp > 0 && element.ltp >= ltp_range_from && element.ltp <= ltp_range_to) {
                            put_valid = true;
                        }
                    }
                    else if (percentage_range_from > 0 && percentage_range_to > 0) {
                        if (element.changePercentage < 0 && Math.abs(element.changePercentage) >= percentage_range_from && Math.abs(element.changePercentage) <= percentage_range_to) {
                            put_valid = true;
                        }
                    }
                    else if (ltp_range_from > 0 && ltp_range_to > 0) {
                        if (element.ltp > 0 && element.ltp >= ltp_range_from && element.ltp <= ltp_range_to) {
                            put_valid = true;
                        }
                    }
                }

            }

            if (call_valid == false && put_valid == false) {
                delete historyNegativeCandleData_temp[i];
                historyNegativeCandleData_temp.splice(i, 1);  //deleting                       
            }
        }

        SethistoryNegativeCandleData(historyNegativeCandleData_temp);
    }

    useEffect(() => {

        if ((percentage_range_from > 0 && percentage_range_to > 0) || (ltp_range_from > 0 && ltp_range_to > 0)) {
            if (historyPositiveCandleData) {
                FilterPos(historyPositiveCandleData);
            }

            if (historyNegativeCandleData) {
                FilterNeg(historyNegativeCandleData);
            }
        }
        else {
            if (temp_positive.length > 0) {
                FilterPos(temp_positive);
            }
            if (temp_negative.length > 0) {
                FilterNeg(temp_negative);
            }
        }

        // if (temp_positive.length > 0) {

        //     if ((percentage_range_from > 0 && percentage_range_to > 0) || (ltp_range_from > 0 && ltp_range_to > 0)) {

        //         var temp_positive_new = temp_positive.filter(function (element) {
        //             if (percentage_range_from > 0 && percentage_range_to > 0 && ltp_range_from > 0 && ltp_range_to > 0) {
        //                 return element.changePercentage > 0 && element.changePercentage >= percentage_range_from && element.changePercentage <= percentage_range_to
        //                     && element.lastTradedPrice > 0 && element.lastTradedPrice >= ltp_range_from && element.lastTradedPrice <= ltp_range_to;
        //             }
        //             else if (percentage_range_from > 0 && percentage_range_to > 0) {
        //                 return element.changePercentage > 0 && element.changePercentage >= percentage_range_from && element.changePercentage <= percentage_range_to;
        //             }
        //             else if (ltp_range_from > 0 && ltp_range_to > 0) {
        //                 return element.lastTradedPrice > 0 && element.lastTradedPrice >= ltp_range_from && element.lastTradedPrice <= ltp_range_to;
        //             }
        //         });

        //         SethistoryPositiveCandleData((temp_positive_new.length > 0) ? temp_positive_new : null);

        //     }
        //     else {
        //         SethistoryPositiveCandleData(temp_positive)
        //     }

        // }

        // if (temp_negative.length > 0) {

        //     if ((percentage_range_from > 0 && percentage_range_to > 0) || (ltp_range_from > 0 && ltp_range_to > 0)) {

        //         var temp_negative_new = temp_negative.filter(function (element) {
        //             if (percentage_range_from > 0 && percentage_range_to > 0 && ltp_range_from > 0 && ltp_range_to > 0) {
        //                 return element.changePercentage < 0 && Math.abs(element.changePercentage) >= percentage_range_from && Math.abs(element.changePercentage) <= percentage_range_to
        //                     && element.lastTradedPrice > 0 && element.lastTradedPrice >= ltp_range_from && element.lastTradedPrice <= ltp_range_to;
        //             }
        //             else if (percentage_range_from > 0 && percentage_range_to > 0) {
        //                 return element.changePercentage < 0 && Math.abs(element.changePercentage) >= percentage_range_from && Math.abs(element.changePercentage) <= percentage_range_to;
        //             }
        //             else if (ltp_range_from > 0 && ltp_range_to > 0) {
        //                 return element.lastTradedPrice > 0 && element.lastTradedPrice >= ltp_range_from && element.lastTradedPrice <= ltp_range_to;
        //             }
        //         });

        //         SethistoryNegativeCandleData((temp_negative_new.length > 0) ? temp_negative_new : null);

        //     }
        //     else {
        //         SethistoryNegativeCandleData(temp_negative)
        //     }

        //     // if (percentage_range_from > 0 && percentage_range_to > 0) {
        //     //     var temp_negative_new = temp_negative.filter((element, index) => element.changePercentage < 0 && element.changePercentage >= percentage_range_from && element.changePercentage <= percentage_range_to);
        //     //     SethistoryNegativeCandleData((temp_negative_new.length > 0) ? temp_negative_new : null);
        //     // }

        //     // if (percentage_range_from > 0 && percentage_range_to > 0) {
        //     //     var temp_negative_new = temp_negative.filter((element, index) => element.changePercentage < 0 && element.changePercentage >= percentage_range_from && element.changePercentage <= percentage_range_to);
        //     //     SethistoryNegativeCandleData((temp_negative_new.length > 0) ? temp_negative_new : null);
        //     // }
        //     // else {
        //     //     //SethistoryNegativeCandleData(temp_negative);
        //     // }

        // }

        //process_fillSockeLiveData();

    }, [percentage_range_from, percentage_range_to, ltp_range_from, ltp_range_to]);

    function collectInstrumentTokensFromUI(data) {

        if (!data) return;

        var instruments_array = getInstrumentsTokesFromCards(data);
        if (instruments_array.length > 0) {
            AddToMarketDataSocketInstruments(instruments_array);
        }
        //console.log("1");
    }

    function getInstrumentsTokesFromCards(data) {

        try {

            var instruments = [];


            for (var i = 0; i < data.length; i++) {

                if (data[i].strikes.length > 0) {

                    var strikesData = data[i].strikes;

                    for (var j = 0; j < strikesData.length; j++) {

                        if (strikesData[j].call_option) {

                            var exchange_code = parseInt(getExchangeCode(strikesData[j].call_option.exchange).code);

                            var call_instrument_token = parseInt(strikesData[j].call_option.token);

                            instruments.push([exchange_code, call_instrument_token]);

                        }

                        if (strikesData[j].put_option) {

                            var exchange_code = parseInt(getExchangeCode(strikesData[j].put_option.exchange).code);

                            var put_instrument_token = parseInt(strikesData[j].put_option.token);

                            instruments.push([exchange_code, put_instrument_token]);

                        }
                    }

                }

            }

            return instruments;
        }
        catch (error) {
            console.log("getInstrumentsTokesFromCards");
            console.log(error);
            return [];
        }
    }

    function getExchangeCode(exchange) {
        var result = {}
        result.exchange = exchange;
        if (exchange == "NSE") {
            result.code = 1;
            result.multiplier = 100;
        }
        else if (exchange == "NFO") {
            result.code = 2;
            result.multiplier = 100;
        }
        else if (exchange == "CDS") {
            result.code = 3;
            result.multiplier = 10000000;
        }
        else if (exchange == "MCX") {
            result.code = 4;
            result.multiplier = 100;
        }
        else if (exchange == "BSE") {
            result.code = 6;
            result.multiplier = 100;
        }
        else if (exchange == "BFO") {
            result.code = 7;
            result.multiplier = 100;
        }
        return result;
    }

    function fillSockeLiveData(data) {

        try {

            updateSocketData(data);

        }
        catch (error) {
            console.log("fillSockeLiveData");
            console.log(error);
        }

    }

    function process_fillSockeLiveData() {
        var optionstrategies_record = localStorage.getItem("optionstrategies_record");
        if (optionstrategies_record) {
            optionstrategies_record = JSON.parse(optionstrategies_record);
            for (var i = 0; i < optionstrategies_record.length; i++) {
                fillSockeLiveData(optionstrategies_record[i]);
            }
        }
    }

    //UI HOVER BUTTON
    function CallMouseOver(event) {
        $(event.target).closest("tr").find(".div-call-trade").show();
    }
    function CallMouseOut(event) {
        $(event.target).closest("tr").find(".div-call-trade").hide();
    }

    function PutMouseOver(event) {
        $(event.target).closest("tr").find(".div-put-trade").show();
    }
    function PutMouseOut(event) {
        $(event.target).closest("tr").find(".div-put-trade").hide();
    }

    //tab navigation
    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };


    //place order
    function PlaceBasketOrder() {

        if (cartData.length > 0) {

            //console.log(JSON.stringify(cartData));

            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            var obj = {
                "email": email,
                "basketOrders": cartData
            };

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/trading/placeBasketOrders', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    SetcartData([]);
                    setmodal_center(false);

                }
                else {
                    SetapiError(output.message);
                }

            });
        }

    }

    //OH OL Required Instrument
    const [required_instruments, Setrequired_instruments] = useState([])

    const [historyCandleData, SethistoryCandleData] = useState([])

    const [historyOHCandleData, SethistoryOHCandleData] = useState(null)

    const [historyOLCandleData, SethistoryOLCandleData] = useState(null)

    const [historyPositiveCandleData, SethistoryPositiveCandleData] = useState(null)

    const [historyNegativeCandleData, SethistoryNegativeCandleData] = useState(null)

    useEffect(() => {
        if (filterchainData) {

            collectInstrumentTokensFromUI(filterchainData);

            for (var i = 0; i < filterchainData.length; i++) {

                if (filterchainData[i].strikes.length > 0) {

                    var strikesData = filterchainData[i].strikes;

                    var temp = [];

                    for (var j = 0; j < strikesData.length; j++) {

                        var call_exchange = strikesData[j].call_option.exchange;

                        var call_token = strikesData[j].call_option.token;

                        var put_exchange = strikesData[j].put_option.exchange;

                        var put_token = strikesData[j].put_option.token;

                        //console.log("expiry_date="+expiry_date);

                        temp.push({ "exchange": call_exchange, "token": call_token });

                        temp.push({ "exchange": put_exchange, "token": put_token });

                    }

                    //console.log(JSON.stringify(temp));

                    //console.log(temp.length);

                    Setrequired_instruments(temp);

                }
            }
        }
    }, [filterchainData]);

    useEffect(() => {
        if (required_instruments.length > 0 && timeframe != "") {
            SethistoryCandleData([]);
            SethistoryOHCandleData(null);
            SethistoryOLCandleData(null);
            let historyCandleData_temp = [];
            var last_index = 0;
            for (var i = 0; i < required_instruments.length; i++) {
                LoadCandleHistoryData(i, required_instruments[i].exchange, required_instruments[i].token, timeframe_start_time, timeframe_end_time, function (response) {
                    if (response) {
                        //SethistoryCandleData(oldArray => [...oldArray, response]);
                        historyCandleData_temp.push(response);
                        last_index++;
                        //console.log("last_index=" + last_index + ",length=" + required_instruments.length)
                        if (last_index == required_instruments.length) {
                            //console.log("last data");
                            SethistoryCandleData(historyCandleData_temp);
                        }
                    }
                });
            }
        }
    }, [required_instruments, timeframe]);

    function LoadCandleHistoryData(i, exchange, token, start_time, end_time, callback) {

        //console.log("i=" + i + ",exchange=" + exchange + ",token=" + token + ",start_time=" + start_time + ",end_time=" + end_time);

        if (exchange != "" && token != "" && start_time != "" && end_time != "") {

            const headers = {
                'Content-Type': 'application/json'
            };

            var url = API_URL + "/trading/getCandlesData/" + exchange + "/" + token + "/" + start_time + "/" + end_time;

            axios.get(url, { headers }).then(response => {

                var output = response.data;

                //console.log(output);
                //console.log("result=" + output.result);
                //console.log("data=" + output.data);
                //console.log("message=" + output.message);

                if (output.result == "success") {

                    //SethistoryCandleData(oldArray => [...oldArray, output.data]);

                    //console.log("historyCandleData length=" + historyCandleData.length);

                    callback(output.data);

                }
                else {
                    //SetapiError(output.message);
                    callback(null);
                }

            });

        }

    }

    useEffect(() => {
        //console.log("historyCandleData length=" + historyCandleData.length);
        if (historyCandleData.length == required_instruments.length && required_instruments.length > 0) {
            CheckMatchedCandles();
        }
    }, [historyCandleData]);

    function CheckMatchedCandles() {

        try {
            //console.log("required_instruments.length=" + required_instruments.length + ",historyCandleData.length=" + historyCandleData.length);
            if (historyCandleData.length > 0 && historyCandleData.length == required_instruments.length) {
                //console.log("final !!!");
                //console.log("required_instruments length=" + required_instruments.length);
                //console.log("historyCandleData length=" + historyCandleData.length);
                //console.log(JSON.stringify(historyCandleData));

                //debugger;

                var historyOHCandleData_temp = [];
                var historyOLCandleData_temp = [];

                for (var i = 0; i < historyCandleData.length; i++) {

                    var candles = historyCandleData[i].candles;

                    for (var j = 0; j < candles.length; j++) {

                        var open = candles[j][1];
                        var high = candles[j][2];
                        var low = candles[j][3];
                        var close = candles[j][4];

                        if (open == high) {
                            historyOHCandleData_temp.push(historyCandleData[i]);
                        }

                        if (open == low) {
                            historyOLCandleData_temp.push(historyCandleData[i]);
                        }

                    }
                }

                //console.log("total strikes=" + historyCandleData.length);

                //console.log("total oh same strikes=" + historyOHCandleData_temp.length);

                //console.log("total ol same strikes=" + historyOLCandleData_temp.length);

                //console.log("historyOHCandleData_temp");
                //console.log(historyOHCandleData_temp);

                //console.log("historyOLCandleData_temp");
                //console.log(historyOLCandleData_temp);

                //SethistoryOHCandleData(historyOHCandleData_temp);
                //SethistoryOLCandleData(historyOLCandleData_temp);

                var temp_oh = [];

                var temp_ol = [];

                //debugger;

                for (var k = 0; k < historyOHCandleData_temp.length; k++) {

                    var required_token = historyOHCandleData_temp[k].token;

                    for (var i = 0; i < filterchainData.length; i++) {

                        if (filterchainData[i].strikes.length > 0) {

                            var strikesData = filterchainData[i].strikes;

                            var matched = false;

                            for (var j = 0; j < strikesData.length; j++) {

                                var call_token = strikesData[j].call_option.token;

                                var put_token = strikesData[j].put_option.token;

                                var temp = {
                                    expiry_date: filterchainData[i].expiry_date,
                                    strikes: []
                                }

                                if (call_token == required_token) {

                                    var ltp_valid = true;
                                    if (ltp_range_from > 0 && ltp_range_to > 0) {
                                        var from = ltp_range_from;
                                        var to = ltp_range_to;
                                        if (ltp_range_from > ltp_range_to) {
                                            from = ltp_range_to;
                                            to = ltp_range_from;
                                        }
                                        if (strikesData[j].call_option.ltp >= from && strikesData[j].call_option.ltp <= to) {
                                            ltp_valid = true;
                                        } else {
                                            ltp_valid = false;
                                        }
                                    }
                                    if (ltp_valid) {
                                        var test = {
                                            strike_price: strikesData[j].strike_price,
                                            call_option: strikesData[j].call_option
                                        }

                                        temp.strikes.push(test);
                                    }

                                }
                                else if (put_token == required_token) {

                                    var ltp_valid = true;
                                    if (ltp_range_from > 0 && ltp_range_to > 0) {
                                        var from = ltp_range_from;
                                        var to = ltp_range_to;
                                        if (ltp_range_from > ltp_range_to) {
                                            from = ltp_range_to;
                                            to = ltp_range_from;
                                        }
                                        if (strikesData[j].put_option.ltp >= from && strikesData[j].put_option.ltp <= to) {
                                            ltp_valid = true;
                                        } else {
                                            ltp_valid = false;
                                        }
                                    }
                                    if (ltp_valid) {
                                        var test = {
                                            strike_price: strikesData[j].strike_price,
                                            put_option: strikesData[j].put_option
                                        }

                                        temp.strikes.push(test);
                                    }

                                } else {
                                    continue;
                                }

                                if (temp.strikes.length > 0) {
                                    temp_oh.push(temp);
                                    matched = true;
                                    break;
                                }

                            }

                            if (matched) {
                                break;
                            }
                        }
                    }

                }

                for (var k = 0; k < historyOLCandleData_temp.length; k++) {

                    var required_token = historyOLCandleData_temp[k].token;

                    for (var i = 0; i < filterchainData.length; i++) {

                        if (filterchainData[i].strikes.length > 0) {

                            var strikesData = filterchainData[i].strikes;

                            var matched = false;

                            for (var j = 0; j < strikesData.length; j++) {

                                var call_token = strikesData[j].call_option.token;

                                var put_token = strikesData[j].put_option.token;

                                var temp = {
                                    expiry_date: filterchainData[i].expiry_date,
                                    strikes: []
                                }

                                if (call_token == required_token) {

                                    var ltp_valid = true;
                                    if (ltp_range_from > 0 && ltp_range_to > 0) {
                                        var from = ltp_range_from;
                                        var to = ltp_range_to;
                                        if (ltp_range_from > ltp_range_to) {
                                            from = ltp_range_to;
                                            to = ltp_range_from;
                                        }
                                        if (strikesData[j].call_option.ltp >= from && strikesData[j].call_option.ltp <= to) {
                                            ltp_valid = true;
                                        } else {
                                            ltp_valid = false;
                                        }
                                    }
                                    if (ltp_valid) {
                                        var test = {
                                            strike_price: strikesData[j].strike_price,
                                            call_option: strikesData[j].call_option
                                        }

                                        temp.strikes.push(test);
                                    }

                                }
                                else if (put_token == required_token) {

                                    var ltp_valid = true;
                                    if (ltp_range_from > 0 && ltp_range_to > 0) {
                                        var from = ltp_range_from;
                                        var to = ltp_range_to;
                                        if (ltp_range_from > ltp_range_to) {
                                            from = ltp_range_to;
                                            to = ltp_range_from;
                                        }
                                        if (strikesData[j].put_option.ltp >= from && strikesData[j].put_option.ltp <= to) {
                                            ltp_valid = true;
                                        } else {
                                            ltp_valid = false;
                                        }
                                    }
                                    if (ltp_valid) {
                                        var test = {
                                            strike_price: strikesData[j].strike_price,
                                            put_option: strikesData[j].put_option
                                        }

                                        temp.strikes.push(test);
                                    }

                                } else {
                                    continue;
                                }

                                if (temp.strikes.length > 0) {
                                    temp_ol.push(temp);
                                    matched = true;
                                    break;
                                }

                            }

                            if (matched) {
                                break;
                            }

                        }
                    }

                }

                //console.log("temp_oh");
                //console.log(temp_oh);

                //console.log("temp_ol");
                //console.log(temp_ol);

                //temp_oh = temp_oh.sort((a, b) => a.expiry_date > b.expiry_date ? 1 : -1);

                //temp_ol = temp_ol.sort((a, b) => a.expiry_date > b.expiry_date ? 1 : -1);

                SethistoryOHCandleData(temp_oh);

                SethistoryOLCandleData(temp_ol);

            }
        }
        catch (error) {
            console.log("CheckMatchedCandles");
            console.log(error);
        }
    }

    function updateSocketData(data) {

        if (!data)
            return;

        //console.log("data.instrumentToken=" + data.instrumentToken);

        data.instrumentToken = data.instrumentToken.toString();

        data.lastTradedPrice = data.lastTradedPrice / 100;
        data.openPrice = data.openPrice / 100;
        data.highPrice = data.highPrice / 100;
        data.lowPrice = data.lowPrice / 100;
        data.closePrice = data.closePrice / 100;

        data.change = data.lastTradedPrice - data.closePrice;

        data.changePercentage = parseFloat((data.change * 100 / data.closePrice).toFixed(2));


        if (historyOHCandleData) {

            const historyOHCandleData_temp = [...historyOHCandleData];

            for (var i = 0; i < historyOHCandleData_temp.length; i++) {

                //console.log("data.instrumentToken=" + data.instrumentToken);

                var strikesData = historyOHCandleData_temp[i].strikes;

                for (var j = 0; j < strikesData.length; j++) {

                    if (historyOHCandleData_temp[i].strikes[j].call_option) {
                        if (historyOHCandleData_temp[i].strikes[j].call_option.token == data.instrumentToken) {
                            historyOHCandleData_temp[i].strikes[j].call_option.past_ltp = historyOHCandleData_temp[i].strikes[j].call_option.ltp;
                            historyOHCandleData_temp[i].strikes[j].call_option.ltp = data.lastTradedPrice;
                            if (historyOHCandleData_temp[i].strikes[j].call_option.ltp > historyOHCandleData_temp[i].strikes[j].call_option.past_ltp) {
                                historyOHCandleData_temp[i].strikes[j].call_option.ltp_color = "green";
                            }
                            else if (historyOHCandleData_temp[i].strikes[j].call_option.ltp < historyOHCandleData_temp[i].strikes[j].call_option.past_ltp) {
                                historyOHCandleData_temp[i].strikes[j].call_option.ltp_color = "red";
                            }
                            historyOHCandleData_temp[i].strikes[j].call_option.volume = data.volume;
                            historyOHCandleData_temp[i].strikes[j].call_option.changePercentage = data.changePercentage;
                            if (data.changePercentage > 0) {
                                historyOHCandleData_temp[i].strikes[j].call_option.changePercentage_color = "green";
                            }
                            else {
                                historyOHCandleData_temp[i].strikes[j].call_option.changePercentage_color = "red";
                            }
                        }
                    }

                    if (historyOHCandleData_temp[i].strikes[j].put_option) {
                        if (historyOHCandleData_temp[i].strikes[j].put_option.token == data.instrumentToken) {
                            historyOHCandleData_temp[i].strikes[j].put_option.past_ltp = historyOHCandleData_temp[i].strikes[j].put_option.ltp;
                            historyOHCandleData_temp[i].strikes[j].put_option.ltp = data.lastTradedPrice;
                            if (historyOHCandleData_temp[i].strikes[j].put_option.ltp > historyOHCandleData_temp[i].strikes[j].put_option.past_ltp) {
                                historyOHCandleData_temp[i].strikes[j].put_option.ltp_color = "green";
                            }
                            else if (historyOHCandleData_temp[i].strikes[j].put_option.ltp < historyOHCandleData_temp[i].strikes[j].put_option.past_ltp) {
                                historyOHCandleData_temp[i].strikes[j].put_option.ltp_color = "red";
                            }
                            historyOHCandleData_temp[i].strikes[j].put_option.volume = data.volume;
                            historyOHCandleData_temp[i].strikes[j].put_option.changePercentage = data.changePercentage;
                            if (data.changePercentage > 0) {
                                historyOHCandleData_temp[i].strikes[j].put_option.changePercentage_color = "green";
                            }
                            else {
                                historyOHCandleData_temp[i].strikes[j].put_option.changePercentage_color = "red";
                            }

                        }
                    }

                }
            }

            SethistoryOHCandleData(historyOHCandleData_temp);
        }

        if (historyOLCandleData) {

            const historyOLCandleData_temp = [...historyOLCandleData];

            for (var i = 0; i < historyOLCandleData_temp.length; i++) {

                //console.log("data.instrumentToken=" + data.instrumentToken);

                var strikesData = historyOLCandleData_temp[i].strikes;

                for (var j = 0; j < strikesData.length; j++) {

                    if (historyOLCandleData_temp[i].strikes[j].call_option && historyOLCandleData_temp[i].strikes[j].call_option.token == data.instrumentToken) {
                        historyOLCandleData_temp[i].strikes[j].call_option.past_ltp = historyOLCandleData_temp[i].strikes[j].call_option.ltp;
                        historyOLCandleData_temp[i].strikes[j].call_option.ltp = data.lastTradedPrice;
                        if (historyOLCandleData_temp[i].strikes[j].call_option.ltp > historyOLCandleData_temp[i].strikes[j].call_option.past_ltp) {
                            historyOLCandleData_temp[i].strikes[j].call_option.ltp_color = "green";
                        }
                        else if (historyOLCandleData_temp[i].strikes[j].call_option.ltp < historyOLCandleData_temp[i].strikes[j].call_option.past_ltp) {
                            historyOLCandleData_temp[i].strikes[j].call_option.ltp_color = "red";
                        }
                        historyOLCandleData_temp[i].strikes[j].call_option.volume = data.volume;
                        historyOLCandleData_temp[i].strikes[j].call_option.changePercentage = data.changePercentage;
                        if (data.changePercentage > 0) {
                            historyOLCandleData_temp[i].strikes[j].call_option.changePercentage_color = "green";
                        }
                        else {
                            historyOLCandleData_temp[i].strikes[j].call_option.changePercentage_color = "red";
                        }

                    }

                    if (historyOLCandleData_temp[i].strikes[j].put_option && historyOLCandleData_temp[i].strikes[j].put_option.token == data.instrumentToken) {
                        historyOLCandleData_temp[i].strikes[j].put_option.past_ltp = historyOLCandleData_temp[i].strikes[j].put_option.ltp;
                        historyOLCandleData_temp[i].strikes[j].put_option.ltp = data.lastTradedPrice;
                        if (historyOLCandleData_temp[i].strikes[j].put_option.ltp > historyOLCandleData_temp[i].strikes[j].put_option.past_ltp) {
                            historyOLCandleData_temp[i].strikes[j].put_option.ltp_color = "green";
                        }
                        else if (historyOLCandleData_temp[i].strikes[j].put_option.ltp < historyOLCandleData_temp[i].strikes[j].put_option.past_ltp) {
                            historyOLCandleData_temp[i].strikes[j].put_option.ltp_color = "red";
                        }
                        historyOLCandleData_temp[i].strikes[j].put_option.volume = data.volume;
                        historyOLCandleData_temp[i].strikes[j].put_option.changePercentage = data.changePercentage;
                        if (data.changePercentage > 0) {
                            historyOLCandleData_temp[i].strikes[j].put_option.changePercentage_color = "green";
                        }
                        else {
                            historyOLCandleData_temp[i].strikes[j].put_option.changePercentage_color = "red";
                        }

                    }

                }
            }

            SethistoryOLCandleData(historyOLCandleData_temp);

        }

        if (filterchainData) {

            var required_token = data.instrumentToken;

            // var temp_positive = [];

            // if(historyPositiveCandleData){
            //     temp_positive = [...historyPositiveCandleData];
            // }

            // var temp_negative = [];

            // if(historyNegativeCandleData){
            //     temp_negative = [...historyNegativeCandleData];
            // }

            for (var i = 0; i < filterchainData.length; i++) {

                if (filterchainData[i].strikes.length > 0) {

                    var strikesData = filterchainData[i].strikes;

                    var matched = false;

                    for (var j = 0; j < strikesData.length; j++) {

                        var call_token = strikesData[j].call_option.token;

                        var put_token = strikesData[j].put_option.token;

                        var temp_pos = {
                            expiry_date: filterchainData[i].expiry_date,
                            strikes: [],
                            changePercentage: data.changePercentage
                        }

                        var temp_neg = {
                            expiry_date: filterchainData[i].expiry_date,
                            strikes: []
                        }

                        if (call_token == required_token) {

                            if (data.changePercentage > 0) {

                                var percentage_valid = true; var ltp_valid = true;

                                // if (percentage_range_from > 0 && percentage_range_to > 0) {
                                //     if (data.changePercentage >= percentage_range_from && data.changePercentage <= percentage_range_to) {
                                //         percentage_valid = true;
                                //     } else {
                                //         percentage_valid = false;
                                //     }
                                // }

                                // if (ltp_range_from > 0 && ltp_range_to > 0) {
                                //     var from = ltp_range_from;
                                //     var to = ltp_range_to;
                                //     if (ltp_range_from > ltp_range_to) {
                                //         from = ltp_range_to;
                                //         to = ltp_range_from;
                                //     }
                                //     if (data.lastTradedPrice >= from && data.lastTradedPrice <= to) {
                                //         ltp_valid = true;
                                //     } else {
                                //         ltp_valid = false;
                                //     }
                                // }

                                if (percentage_valid && ltp_valid) {
                                    var test = {
                                        strike_price: strikesData[j].strike_price,
                                        call_option: strikesData[j].call_option
                                    }

                                    temp_pos.strikes.push(test);
                                }

                            } else {
                                var percentage_valid = true; var ltp_valid = true;

                                // if (percentage_range_from > 0 && percentage_range_to > 0) {
                                //     if (Math.abs(data.changePercentage) >= percentage_range_from && Math.abs(data.changePercentage) <= percentage_range_to) {
                                //         percentage_valid = true;
                                //     } else {
                                //         percentage_valid = false;
                                //     }
                                // }

                                // if (ltp_range_from > 0 && ltp_range_to > 0) {
                                //     var from = ltp_range_from;
                                //     var to = ltp_range_to;
                                //     if (ltp_range_from > ltp_range_to) {
                                //         from = ltp_range_to;
                                //         to = ltp_range_from;
                                //     }
                                //     if (data.lastTradedPrice >= from && data.lastTradedPrice <= to) {
                                //         ltp_valid = true;
                                //     } else {
                                //         ltp_valid = false;
                                //     }
                                // }

                                if (percentage_valid && ltp_valid) {
                                    var test = {
                                        strike_price: strikesData[j].strike_price,
                                        call_option: strikesData[j].call_option
                                    }

                                    temp_neg.strikes.push(test);
                                }
                            }

                            // if (data.changePercentage > 0 && data.changePercentage >= percentage_range_from && data.changePercentage <= percentage_range_to && percentage_range_from > 0 && percentage_range_to > 0) {

                            //     var test = {
                            //         strike_price: strikesData[j].strike_price,
                            //         call_option: strikesData[j].call_option
                            //     }

                            //     temp_pos.strikes.push(test);

                            // }
                            // else if (data.changePercentage < 0 && data.changePercentage >= percentage_range_from && data.changePercentage <= percentage_range_to && percentage_range_from > 0 && percentage_range_to > 0) {

                            //     var test = {
                            //         strike_price: strikesData[j].strike_price,
                            //         call_option: strikesData[j].call_option
                            //     }

                            //     temp_neg.strikes.push(test);
                            // }

                        }
                        else if (put_token == required_token) {

                            if (data.changePercentage > 0) {

                                var percentage_valid = true; var ltp_valid = true;

                                // if (percentage_range_from > 0 && percentage_range_to > 0) {
                                //     if (data.changePercentage >= percentage_range_from && data.changePercentage <= percentage_range_to) {
                                //         percentage_valid = true;
                                //     } else {
                                //         percentage_valid = false;
                                //     }
                                // }

                                // if (ltp_range_from > 0 && ltp_range_to > 0) {
                                //     var from = ltp_range_from;
                                //     var to = ltp_range_to;
                                //     if (ltp_range_from > ltp_range_to) {
                                //         from = ltp_range_to;
                                //         to = ltp_range_from;
                                //     }
                                //     if (data.lastTradedPrice >= from && data.lastTradedPrice <= to) {
                                //         ltp_valid = true;
                                //     } else {
                                //         ltp_valid = false;
                                //     }
                                // }

                                if (percentage_valid && ltp_valid) {
                                    var test = {
                                        strike_price: strikesData[j].strike_price,
                                        put_option: strikesData[j].put_option
                                    }

                                    temp_pos.strikes.push(test);
                                }

                            } else {
                                var percentage_valid = true; var ltp_valid = true;

                                // if (percentage_range_from > 0 && percentage_range_to > 0) {
                                //     if (Math.abs(data.changePercentage) >= percentage_range_from && Math.abs(data.changePercentage) <= percentage_range_to) {
                                //         percentage_valid = true;
                                //     } else {
                                //         percentage_valid = false;
                                //     }
                                // }

                                // if (ltp_range_from > 0 && ltp_range_to > 0) {
                                //     var from = ltp_range_from;
                                //     var to = ltp_range_to;
                                //     if (ltp_range_from > ltp_range_to) {
                                //         from = ltp_range_to;
                                //         to = ltp_range_from;
                                //     }
                                //     if (data.lastTradedPrice >= from && data.lastTradedPrice <= to) {
                                //         ltp_valid = true;
                                //     } else {
                                //         ltp_valid = false;
                                //     }
                                // }

                                if (percentage_valid && ltp_valid) {
                                    var test = {
                                        strike_price: strikesData[j].strike_price,
                                        put_option: strikesData[j].put_option
                                    }

                                    temp_neg.strikes.push(test);
                                }
                            }

                            // if (data.changePercentage > 0 && data.changePercentage >= percentage_range_from && data.changePercentage <= percentage_range_to && percentage_range_from > 0 && percentage_range_to > 0) {

                            //     var test = {
                            //         strike_price: strikesData[j].strike_price,
                            //         put_option: strikesData[j].put_option
                            //     }

                            //     temp_pos.strikes.push(test);

                            // }
                            // else if (data.changePercentage < 0 && data.changePercentage >= percentage_range_from && data.changePercentage <= percentage_range_to && percentage_range_from > 0 && percentage_range_to > 0) {

                            //     var test = {
                            //         strike_price: strikesData[j].strike_price,
                            //         put_option: strikesData[j].put_option
                            //     }

                            //     temp_neg.strikes.push(test);
                            // }

                        } else {
                            continue;
                        }

                        if (temp_pos.strikes.length > 0) {

                            var index = temp_positive.findIndex(function (item, i) {
                                if (item.strikes[0].call_option) {
                                    return item.strikes[0].call_option.token == required_token
                                }
                                else if (item.strikes[0].put_option) {
                                    return item.strikes[0].put_option.token == required_token
                                }
                                else {
                                    return -1;
                                }
                            });
                            if (index > -1) {
                                //temp_positive[index] = temp_pos;

                                if (temp_pos.strikes[0].call_option) {
                                    temp_positive[index].strikes[0].call_option = temp_pos.strikes[0].call_option;
                                }
                                else if (temp_pos.strikes[0].put_option) {
                                    temp_positive[index].strikes[0].put_option = temp_pos.strikes[0].put_option;
                                }

                            } else {
                                //console.log(temp_pos);
                                //temp_positive.push(temp_pos);
                                var find_index = temp_positive.findIndex(function (item, i) { return item.strikes[0].strike_price == temp_pos.strikes[0].strike_price; });
                                if (find_index > -1) {

                                    if (temp_pos.strikes[0].call_option) {
                                        temp_positive[find_index].strikes[0].call_option = temp_pos.strikes[0].call_option;
                                    }
                                    else if (temp_pos.strikes[0].put_option) {
                                        temp_positive[find_index].strikes[0].put_option = temp_pos.strikes[0].put_option;
                                    }

                                }
                                else {
                                    temp_positive.push(temp_pos);
                                }
                            }

                            //temp_positive.push(temp_pos);

                            matched = true;
                        }

                        if (temp_neg.strikes.length > 0) {

                            var index = temp_negative.findIndex(function (item, i) {
                                if (item.strikes[0].call_option) {
                                    return item.strikes[0].call_option.token == required_token
                                }
                                else if (item.strikes[0].put_option) {
                                    return item.strikes[0].put_option.token == required_token
                                }
                                else {
                                    return -1;
                                }
                            });
                            if (index > -1) {
                                //temp_negative[index] = temp_neg;

                                if (temp_neg.strikes[0].call_option) {
                                    temp_negative[index].strikes[0].call_option = temp_neg.strikes[0].call_option;
                                }
                                else if (temp_neg.strikes[0].put_option) {
                                    temp_negative[index].strikes[0].put_option = temp_neg.strikes[0].put_option;
                                }

                            } else {
                                //console.log(temp_neg);
                                //temp_negative.push(temp_neg);
                                var find_index = temp_negative.findIndex(function (item, i) { return item.strikes[0].strike_price == temp_neg.strikes[0].strike_price; });
                                if (find_index > -1) {

                                    if (temp_neg.strikes[0].call_option) {
                                        temp_negative[find_index].strikes[0].call_option = temp_neg.strikes[0].call_option;
                                    }
                                    else if (temp_neg.strikes[0].put_option) {
                                        temp_negative[find_index].strikes[0].put_option = temp_neg.strikes[0].put_option;
                                    }

                                }
                                else {
                                    temp_negative.push(temp_neg);
                                }
                            }

                            //temp_negative.push(temp_neg);

                            matched = true;
                        }

                        // if (matched) {
                        //     break;
                        // }

                    }

                    // if (matched) {
                    //     break;
                    // }
                }
            }

            //console.log("temp_positive");
            //console.log(temp_positive);

            //console.log("temp_negative");
            //console.log(temp_negative);

            if (temp_positive.length > 0) {
                if ((percentage_range_from > 0 && percentage_range_to > 0) || (ltp_range_from > 0 && ltp_range_to > 0)) {
                    FilterPos(temp_positive);
                } else {
                    SethistoryPositiveCandleData(temp_positive);
                }
            }

            if (temp_negative.length > 0) {
                if ((percentage_range_from > 0 && percentage_range_to > 0) || (ltp_range_from > 0 && ltp_range_to > 0)) {
                    FilterNeg(temp_negative);
                }
                else {
                    SethistoryNegativeCandleData(temp_negative);
                }
            }

        }

        if (historyPositiveCandleData) {

            //const historyPositiveCandleData_temp = [...historyPositiveCandleData];

            const historyPositiveCandleData_temp = historyPositiveCandleData;

            for (var i = 0; i < historyPositiveCandleData_temp.length; i++) {

                //console.log("data.instrumentToken=" + data.instrumentToken);

                var strikesData = historyPositiveCandleData_temp[i].strikes;

                for (var j = 0; j < strikesData.length; j++) {

                    if (historyPositiveCandleData_temp[i].strikes[j].call_option) {
                        if (historyPositiveCandleData_temp[i].strikes[j].call_option.token == data.instrumentToken) {
                            historyPositiveCandleData_temp[i].strikes[j].call_option.past_ltp = historyPositiveCandleData_temp[i].strikes[j].call_option.ltp;
                            historyPositiveCandleData_temp[i].strikes[j].call_option.ltp = data.lastTradedPrice;
                            if (historyPositiveCandleData_temp[i].strikes[j].call_option.ltp > historyPositiveCandleData_temp[i].strikes[j].call_option.past_ltp) {
                                historyPositiveCandleData_temp[i].strikes[j].call_option.ltp_color = "green";
                            }
                            else if (historyPositiveCandleData_temp[i].strikes[j].call_option.ltp < historyPositiveCandleData_temp[i].strikes[j].call_option.past_ltp) {
                                historyPositiveCandleData_temp[i].strikes[j].call_option.ltp_color = "red";
                            }
                            historyPositiveCandleData_temp[i].strikes[j].call_option.volume = data.volume;
                            historyPositiveCandleData_temp[i].strikes[j].call_option.changePercentage = data.changePercentage;
                            if (data.changePercentage > 0) {
                                historyPositiveCandleData_temp[i].strikes[j].call_option.changePercentage_color = "green";
                            }
                            else {
                                historyPositiveCandleData_temp[i].strikes[j].call_option.changePercentage_color = "red";
                            }
                        }
                    }

                    if (historyPositiveCandleData_temp[i].strikes[j].put_option) {
                        if (historyPositiveCandleData_temp[i].strikes[j].put_option.token == data.instrumentToken) {
                            historyPositiveCandleData_temp[i].strikes[j].put_option.past_ltp = historyPositiveCandleData_temp[i].strikes[j].put_option.ltp;
                            historyPositiveCandleData_temp[i].strikes[j].put_option.ltp = data.lastTradedPrice;
                            if (historyPositiveCandleData_temp[i].strikes[j].put_option.ltp > historyPositiveCandleData_temp[i].strikes[j].put_option.past_ltp) {
                                historyPositiveCandleData_temp[i].strikes[j].put_option.ltp_color = "green";
                            }
                            else if (historyPositiveCandleData_temp[i].strikes[j].put_option.ltp < historyPositiveCandleData_temp[i].strikes[j].put_option.past_ltp) {
                                historyPositiveCandleData_temp[i].strikes[j].put_option.ltp_color = "red";
                            }
                            historyPositiveCandleData_temp[i].strikes[j].put_option.volume = data.volume;
                            historyPositiveCandleData_temp[i].strikes[j].put_option.changePercentage = data.changePercentage;
                            if (data.changePercentage > 0) {
                                historyPositiveCandleData_temp[i].strikes[j].put_option.changePercentage_color = "green";
                            }
                            else {
                                historyPositiveCandleData_temp[i].strikes[j].put_option.changePercentage_color = "red";
                            }

                        }
                    }

                }
            }

            SethistoryPositiveCandleData(historyPositiveCandleData_temp);
        }

        if (historyNegativeCandleData) {

            //const historyNegativeCandleData_temp = [...historyNegativeCandleData];

            const historyNegativeCandleData_temp = historyNegativeCandleData;

            for (var i = 0; i < historyNegativeCandleData_temp.length; i++) {

                //console.log("data.instrumentToken=" + data.instrumentToken);

                var strikesData = historyNegativeCandleData_temp[i].strikes;

                for (var j = 0; j < strikesData.length; j++) {

                    if (historyNegativeCandleData_temp[i].strikes[j].call_option && historyNegativeCandleData_temp[i].strikes[j].call_option.token == data.instrumentToken) {
                        historyNegativeCandleData_temp[i].strikes[j].call_option.past_ltp = historyNegativeCandleData_temp[i].strikes[j].call_option.ltp;
                        historyNegativeCandleData_temp[i].strikes[j].call_option.ltp = data.lastTradedPrice;
                        if (historyNegativeCandleData_temp[i].strikes[j].call_option.ltp > historyNegativeCandleData_temp[i].strikes[j].call_option.past_ltp) {
                            historyNegativeCandleData_temp[i].strikes[j].call_option.ltp_color = "green";
                        }
                        else if (historyNegativeCandleData_temp[i].strikes[j].call_option.ltp < historyNegativeCandleData_temp[i].strikes[j].call_option.past_ltp) {
                            historyNegativeCandleData_temp[i].strikes[j].call_option.ltp_color = "red";
                        }
                        historyNegativeCandleData_temp[i].strikes[j].call_option.volume = data.volume;
                        historyNegativeCandleData_temp[i].strikes[j].call_option.changePercentage = data.changePercentage;
                        if (data.changePercentage > 0) {
                            historyNegativeCandleData_temp[i].strikes[j].call_option.changePercentage_color = "green";
                        }
                        else {
                            historyNegativeCandleData_temp[i].strikes[j].call_option.changePercentage_color = "red";
                        }

                    }

                    if (historyNegativeCandleData_temp[i].strikes[j].put_option && historyNegativeCandleData_temp[i].strikes[j].put_option.token == data.instrumentToken) {
                        historyNegativeCandleData_temp[i].strikes[j].put_option.past_ltp = historyNegativeCandleData_temp[i].strikes[j].put_option.ltp;
                        historyNegativeCandleData_temp[i].strikes[j].put_option.ltp = data.lastTradedPrice;
                        if (historyNegativeCandleData_temp[i].strikes[j].put_option.ltp > historyNegativeCandleData_temp[i].strikes[j].put_option.past_ltp) {
                            historyNegativeCandleData_temp[i].strikes[j].put_option.ltp_color = "green";
                        }
                        else if (historyNegativeCandleData_temp[i].strikes[j].put_option.ltp < historyNegativeCandleData_temp[i].strikes[j].put_option.past_ltp) {
                            historyNegativeCandleData_temp[i].strikes[j].put_option.ltp_color = "red";
                        }
                        historyNegativeCandleData_temp[i].strikes[j].put_option.volume = data.volume;
                        historyNegativeCandleData_temp[i].strikes[j].put_option.changePercentage = data.changePercentage;
                        if (data.changePercentage > 0) {
                            historyNegativeCandleData_temp[i].strikes[j].put_option.changePercentage_color = "green";
                        }
                        else {
                            historyNegativeCandleData_temp[i].strikes[j].put_option.changePercentage_color = "red";
                        }

                    }

                }
            }

            SethistoryNegativeCandleData(historyNegativeCandleData_temp);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>

                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="fas fa-home"></i>
                                                </span>
                                                <span className="d-none d-sm-block">OHLC  Strategy</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-user"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Percent Change Strategy</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <Row style={{ width: "100%", marginTop: "27px", marginLeft: "9px", paddingRight: "9px" }}>
                                        <Col md={2}>
                                            <div className="form-floating mb-3">
                                                <select name="symbol" className="form-select" onChange={(e) => handleSymbolChange(e.target.selectedOptions[0].getAttribute('data-exchange'), e.target.selectedOptions[0].getAttribute('data-symbol'), e.target.selectedOptions[0].getAttribute('data-token'))}>
                                                    {nse_symbol_data && nse_symbol_data.map((data, key) => (
                                                        <option key={key} data-exchange={data.exchange} data-symbol={data.trading_symbol} data-token={data.code} value={data.code}>{data.trading_symbol}</option>
                                                    ))}
                                                </select>
                                                <label htmlFor="floatingSelectGrid">SELECT SYMBOL</label></div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="form-floating mb-3">
                                                <select name="expiry" className="form-select" onChange={(e) => { changeOptions(e.target.value, "change-expiry"); }}>
                                                    {expiryData && expiryData.map((data, key) => (
                                                        <option key={key} value={data}>{data}</option>
                                                    ))}
                                                </select>
                                                <label htmlFor="floatingSelectGrid">SELECT EXPIRY</label></div>
                                        </Col>
                                        {customActiveTab == "1" && (
                                            <Col md={2}>
                                                <div className="form-floating mb-3">
                                                    <select name="timeframe" value={timeframe} className="form-select" onChange={(e) => { UpdateTimeframeChange(e); }}>
                                                        {TimeframesData && TimeframesData.map((data, key) => (
                                                            <option key={key} data-starttime={data.start_time} data-endtime={data.end_time} value={data.value}>{data.name}</option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="floatingSelectGrid">SELECT TIMEFRAME</label></div>
                                            </Col>
                                        )}
                                        {customActiveTab == "2" && (
                                            <>

                                                <Col md={2}>
                                                    <div className="form-floating mb-3">
                                                        <input type="number" onChange={(e) => { if (e.target.value != "") SetPercentageRangeFrom(parseFloat(e.target.value)); }} className="form-control form-control-sm" value={percentage_range_from}></input>
                                                        <label htmlFor="floatingSelectGrid">% RANGE FROM</label>
                                                    </div>
                                                </Col><Col md={2}>
                                                    <div className="form-floating mb-3">
                                                        <input type="number" onChange={(e) => { if (e.target.value != "") SetPercentageRangeTo(parseFloat(e.target.value)); }} className="form-control form-control-sm" value={percentage_range_to}></input>
                                                        <label htmlFor="floatingSelectGrid">% RANGE TO</label>
                                                    </div>
                                                </Col>

                                            </>

                                        )}

                                        <Col md={2}>
                                            <div className="form-floating mb-3">
                                                <input type="number" onChange={(e) => { if (e.target.value != "") SetLtpRangeFrom(parseFloat(e.target.value)) }} className="form-control form-control-sm" value={ltp_range_from}></input>
                                                <label htmlFor="floatingSelectGrid">LTP RANGE FROM</label></div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="form-floating mb-3">
                                                <input type="number" onChange={(e) => { if (e.target.value != "") SetLtpRangeTo(parseFloat(e.target.value)) }} className="form-control form-control-sm" value={ltp_range_to}></input>
                                                <label htmlFor="floatingSelectGrid">LTP RANGE TO</label></div>
                                        </Col>

                                        {cartData && cartData.length > 0 && (
                                            <Col md={6} style={{ textAlign: "right" }}>
                                                <button onClick={() => { tog_center(); }} className="btn btn-info"><i className="fas fa-shopping-basket"></i><span className="badge" style={{
                                                    position: "absolute",
                                                    top: "-10px",
                                                    right: "5px",
                                                    padding: "5px 7px",
                                                    borderRadius: "50%",
                                                    background: "red",
                                                    color: "white",
                                                }}>{cartData.length}</span></button>
                                            </Col>
                                        )}
                                    </Row>

                                    <TabContent
                                        activeTab={customActiveTab}
                                        className="p-3 text-muted"
                                    >
                                        <TabPane tabId="1">

                                            <Row>
                                                <Col>
                                                    <h5 className="mb-3">
                                                        Open & High Same
                                                    </h5>
                                                    <Card className="border shadow-none mb-0">
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12} style={{ display: "inline-flex" }}>
                                                                    <Col md={6} style={call_heading_style}>
                                                                        CALL
                                                                    </Col>
                                                                    <Col md={6} style={put_heading_style}>
                                                                        PUT
                                                                    </Col>
                                                                </Col>

                                                                <div className="table-rep-plugin">
                                                                    <div
                                                                        className="table-responsive mb-0"
                                                                        data-pattern="priority-columns"
                                                                    >
                                                                        <Table
                                                                            id="tech-companies-1"
                                                                            className="table table-striped table-bordered" style={table_style}
                                                                        >
                                                                            <Thead style={table_style}>
                                                                                <Tr style={table_style}>
                                                                                    <Th style={table_style} data-priority="1">OI</Th>
                                                                                    <Th data-priority="2">CHANGE %</Th>
                                                                                    <Th data-priority="4">VOLUME</Th>
                                                                                    <Th data-priority="5">LTP</Th>
                                                                                    <Th data-priority="6">STRIKE</Th>
                                                                                    <Th data-priority="7">LTP</Th>
                                                                                    <Th data-priority="8">VOLUME</Th>
                                                                                    <Th data-priority="10">CHANGE %</Th>
                                                                                    <Th data-priority="11">OI</Th>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                {historyOHCandleData && historyOHCandleData.map((strikeData) => {

                                                                                    return (
                                                                                        strikeData.strikes.map((data, key) => (

                                                                                            <Tr key={key} style={table_style}>

                                                                                                {data.call_option ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut}>{data.call_option.currentOpenInterest || 0}</Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ color: data.call_option.changePercentage_color }}>{data.call_option.changePercentage || 0}</Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ position: "relative" }}>{data.call_option.volume || 0}
                                                                                                                <div className="div-call-trade" style={{
                                                                                                                    textAlign: "right",
                                                                                                                    position: "absolute",
                                                                                                                    right: 0,
                                                                                                                    top: 0,
                                                                                                                    backgroundColor: "white",
                                                                                                                    width: "100%",
                                                                                                                    height: "100%",
                                                                                                                    paddingTop: "7px",
                                                                                                                    display: "none"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "BUY", "CE", data.call_option.ltp, data.call_option.exchange); }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                        B
                                                                                                                    </button>
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "SELL", "CE", data.call_option.ltp, data.call_option.exchange); }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                        S
                                                                                                                    </button>

                                                                                                                </div>
                                                                                                            </Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ fontWeight: "bold", color: data.call_option.ltp_color }}>{data.call_option.ltp || 0}</Td>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (<Td colSpan="4">
                                                                                                        -
                                                                                                    </Td>)}

                                                                                                <Td>{data.strike_price || 0}</Td>

                                                                                                {data.put_option ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ fontWeight: "bold", color: data.put_option.ltp_color }}>{data.put_option.ltp || 0}</Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ position: "relative" }}>{data.put_option.volume || 0}
                                                                                                                <div className="div-put-trade" style={{
                                                                                                                    textAlign: "left",
                                                                                                                    position: "absolute",
                                                                                                                    left: 0,
                                                                                                                    top: 0,
                                                                                                                    backgroundColor: "white",
                                                                                                                    width: "100%",
                                                                                                                    height: "100%",
                                                                                                                    paddingTop: "7px",
                                                                                                                    display: "none"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "BUY", "PE", data.put_option.ltp, data.put_option.exchange); }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                        B
                                                                                                                    </button>
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "SELL", "PE", data.put_option.ltp, data.put_option.exchange); }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                        S
                                                                                                                    </button>

                                                                                                                </div>
                                                                                                            </Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ color: data.put_option.changePercentage_color }}>{data.put_option.changePercentage || 0}</Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut}>{data.put_option.currentOpenInterest || 0}</Td>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (<Td colSpan="4">
                                                                                                        -
                                                                                                    </Td>)}

                                                                                            </Tr>

                                                                                        ))
                                                                                    );

                                                                                })}
                                                                            </Tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Col>
                                                    <h5 className="mb-3">
                                                        Open & Low Same
                                                    </h5>
                                                    <Card className="border shadow-none mb-0">
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12} style={{ display: "inline-flex" }}>
                                                                    <Col md={6} style={call_heading_style}>
                                                                        CALL
                                                                    </Col>
                                                                    <Col md={6} style={put_heading_style}>
                                                                        PUT
                                                                    </Col>
                                                                </Col>

                                                                <div className="table-rep-plugin">
                                                                    <div
                                                                        className="table-responsive mb-0"
                                                                        data-pattern="priority-columns"
                                                                    >
                                                                        <Table
                                                                            id="tech-companies-1"
                                                                            className="table table-striped table-bordered" style={table_style}
                                                                        >
                                                                            <Thead style={table_style}>
                                                                                <Tr style={table_style}>
                                                                                    <Th style={table_style} data-priority="1">OI</Th>
                                                                                    <Th data-priority="2">CHANGE %</Th>
                                                                                    <Th data-priority="4">VOLUME</Th>
                                                                                    <Th data-priority="5">LTP</Th>
                                                                                    <Th data-priority="6">STRIKE</Th>
                                                                                    <Th data-priority="7">LTP</Th>
                                                                                    <Th data-priority="8">VOLUME</Th>
                                                                                    <Th data-priority="10">CHANGE %</Th>
                                                                                    <Th data-priority="11">OI</Th>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                {historyOLCandleData && historyOLCandleData.map((strikeData) => {

                                                                                    return (
                                                                                        strikeData.strikes.map((data, key) => (

                                                                                            <Tr key={key} style={table_style}>

                                                                                                {data.call_option ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut}>{data.call_option.currentOpenInterest || 0}</Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ color: data.call_option.changePercentage_color }}>{data.call_option.changePercentage || 0}</Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ position: "relative" }}>{data.call_option.volume || 0}
                                                                                                                <div className="div-call-trade" style={{
                                                                                                                    textAlign: "right",
                                                                                                                    position: "absolute",
                                                                                                                    right: 0,
                                                                                                                    top: 0,
                                                                                                                    backgroundColor: "white",
                                                                                                                    width: "100%",
                                                                                                                    height: "100%",
                                                                                                                    paddingTop: "7px",
                                                                                                                    display: "none"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "BUY", "CE", data.call_option.ltp, data.call_option.exchange); }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                        B
                                                                                                                    </button>
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "SELL", "CE", data.call_option.ltp, data.call_option.exchange); }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                        S
                                                                                                                    </button>

                                                                                                                </div>
                                                                                                            </Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ fontWeight: "bold", color: data.call_option.ltp_color }}>{data.call_option.ltp || 0}</Td>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (<Td colSpan="4">
                                                                                                        -
                                                                                                    </Td>)}

                                                                                                <Td>{data.strike_price || 0}</Td>

                                                                                                {data.put_option ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ fontWeight: "bold", color: data.put_option.ltp_color }}>{data.put_option.ltp || 0}</Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ position: "relative" }}>{data.put_option.volume || 0}
                                                                                                                <div className="div-put-trade" style={{
                                                                                                                    textAlign: "left",
                                                                                                                    position: "absolute",
                                                                                                                    left: 0,
                                                                                                                    top: 0,
                                                                                                                    backgroundColor: "white",
                                                                                                                    width: "100%",
                                                                                                                    height: "100%",
                                                                                                                    paddingTop: "7px",
                                                                                                                    display: "none"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "BUY", "PE", data.put_option.ltp, data.put_option.exchange); }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                        B
                                                                                                                    </button>
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "SELL", "PE", data.put_option.ltp, data.put_option.exchange); }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                        S
                                                                                                                    </button>

                                                                                                                </div>
                                                                                                            </Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ color: data.put_option.changePercentage_color }}>{data.put_option.changePercentage || 0}</Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut}>{data.put_option.currentOpenInterest || 0}</Td>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (<Td colSpan="4">
                                                                                                        -
                                                                                                    </Td>)}

                                                                                            </Tr>

                                                                                        ))
                                                                                    );

                                                                                })}
                                                                            </Tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>

                                        </TabPane>
                                        <TabPane tabId="2">


                                            <Row>
                                                <Col>
                                                    <h5 className="mb-3">
                                                        Positive %
                                                    </h5>
                                                    <Card className="border shadow-none mb-0">
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12} style={{ display: "inline-flex" }}>
                                                                    <Col md={6} style={call_heading_style}>
                                                                        CALL
                                                                    </Col>
                                                                    <Col md={6} style={put_heading_style}>
                                                                        PUT
                                                                    </Col>
                                                                </Col>

                                                                <div className="table-rep-plugin">
                                                                    <div
                                                                        className="table-responsive mb-0"
                                                                        data-pattern="priority-columns"
                                                                    >
                                                                        <Table
                                                                            id="tech-companies-1"
                                                                            className="table table-striped table-bordered" style={table_style}
                                                                        >
                                                                            <Thead style={table_style}>
                                                                                <Tr style={table_style}>
                                                                                    <Th style={table_style} data-priority="1">OI</Th>
                                                                                    <Th data-priority="2">CHANGE %</Th>
                                                                                    <Th data-priority="4">VOLUME</Th>
                                                                                    <Th data-priority="5">LTP</Th>
                                                                                    <Th data-priority="6">STRIKE</Th>
                                                                                    <Th data-priority="7">LTP</Th>
                                                                                    <Th data-priority="8">VOLUME</Th>
                                                                                    <Th data-priority="10">CHANGE %</Th>
                                                                                    <Th data-priority="11">OI</Th>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                {historyPositiveCandleData && historyPositiveCandleData.map((strikeData) => {

                                                                                    return (
                                                                                        strikeData.strikes.map((data, key) => (

                                                                                            <Tr key={key} style={table_style}>

                                                                                                {data.call_option ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut}>{data.call_option.currentOpenInterest || 0}</Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ color: data.call_option.changePercentage_color }}>{data.call_option.changePercentage || 0}</Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ position: "relative" }}>{data.call_option.volume || 0}
                                                                                                                <div className="div-call-trade" style={{
                                                                                                                    textAlign: "right",
                                                                                                                    position: "absolute",
                                                                                                                    right: 0,
                                                                                                                    top: 0,
                                                                                                                    backgroundColor: "white",
                                                                                                                    width: "100%",
                                                                                                                    height: "100%",
                                                                                                                    paddingTop: "7px",
                                                                                                                    display: "none"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "BUY", "CE", data.call_option.ltp, data.call_option.exchange); }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                        B
                                                                                                                    </button>
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "SELL", "CE", data.call_option.ltp, data.call_option.exchange); }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                        S
                                                                                                                    </button>

                                                                                                                </div>
                                                                                                            </Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ fontWeight: "bold", color: data.call_option.ltp_color }}>{data.call_option.ltp || 0}</Td>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (<Td colSpan="4">
                                                                                                        -
                                                                                                    </Td>)}

                                                                                                <Td>{data.strike_price || 0}</Td>

                                                                                                {data.put_option ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ fontWeight: "bold", color: data.put_option.ltp_color }}>{data.put_option.ltp || 0}</Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ position: "relative" }}>{data.put_option.volume || 0}
                                                                                                                <div className="div-put-trade" style={{
                                                                                                                    textAlign: "left",
                                                                                                                    position: "absolute",
                                                                                                                    left: 0,
                                                                                                                    top: 0,
                                                                                                                    backgroundColor: "white",
                                                                                                                    width: "100%",
                                                                                                                    height: "100%",
                                                                                                                    paddingTop: "7px",
                                                                                                                    display: "none"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "BUY", "PE", data.put_option.ltp, data.put_option.exchange); }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                        B
                                                                                                                    </button>
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "SELL", "PE", data.put_option.ltp, data.put_option.exchange); }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                        S
                                                                                                                    </button>

                                                                                                                </div>
                                                                                                            </Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ color: data.put_option.changePercentage_color }}>{data.put_option.changePercentage || 0}</Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut}>{data.put_option.currentOpenInterest || 0}</Td>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (<Td colSpan="4">
                                                                                                        -
                                                                                                    </Td>)}

                                                                                            </Tr>

                                                                                        ))
                                                                                    );

                                                                                })}
                                                                            </Tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Col>
                                                    <h5 className="mb-3">
                                                        Negative %
                                                    </h5>
                                                    <Card className="border shadow-none mb-0">
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12} style={{ display: "inline-flex" }}>
                                                                    <Col md={6} style={call_heading_style}>
                                                                        CALL
                                                                    </Col>
                                                                    <Col md={6} style={put_heading_style}>
                                                                        PUT
                                                                    </Col>
                                                                </Col>

                                                                <div className="table-rep-plugin">
                                                                    <div
                                                                        className="table-responsive mb-0"
                                                                        data-pattern="priority-columns"
                                                                    >
                                                                        <Table
                                                                            id="tech-companies-1"
                                                                            className="table table-striped table-bordered" style={table_style}
                                                                        >
                                                                            <Thead style={table_style}>
                                                                                <Tr style={table_style}>
                                                                                    <Th style={table_style} data-priority="1">OI</Th>
                                                                                    <Th data-priority="2">CHANGE %</Th>
                                                                                    <Th data-priority="4">VOLUME</Th>
                                                                                    <Th data-priority="5">LTP</Th>
                                                                                    <Th data-priority="6">STRIKE</Th>
                                                                                    <Th data-priority="7">LTP</Th>
                                                                                    <Th data-priority="8">VOLUME</Th>
                                                                                    <Th data-priority="10">CHANGE %</Th>
                                                                                    <Th data-priority="11">OI</Th>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                {historyNegativeCandleData && historyNegativeCandleData.map((strikeData) => {

                                                                                    return (
                                                                                        strikeData.strikes.map((data, key) => (

                                                                                            <Tr key={key} style={table_style}>

                                                                                                {data.call_option ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut}>{data.call_option.currentOpenInterest || 0}</Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ color: data.call_option.changePercentage_color }}>{data.call_option.changePercentage || 0}</Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ position: "relative" }}>{data.call_option.volume || 0}
                                                                                                                <div className="div-call-trade" style={{
                                                                                                                    textAlign: "right",
                                                                                                                    position: "absolute",
                                                                                                                    right: 0,
                                                                                                                    top: 0,
                                                                                                                    backgroundColor: "white",
                                                                                                                    width: "100%",
                                                                                                                    height: "100%",
                                                                                                                    paddingTop: "7px",
                                                                                                                    display: "none"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "BUY", "CE", data.call_option.ltp, data.call_option.exchange); }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                        B
                                                                                                                    </button>
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.call_option.token, "SELL", "CE", data.call_option.ltp, data.call_option.exchange); }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                        S
                                                                                                                    </button>

                                                                                                                </div>
                                                                                                            </Td><Td onMouseOver={CallMouseOver} onMouseOut={CallMouseOut} style={{ fontWeight: "bold", color: data.call_option.ltp_color }}>{data.call_option.ltp || 0}</Td>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (<Td colSpan="4">
                                                                                                        -
                                                                                                    </Td>)}

                                                                                                <Td>{data.strike_price || 0}</Td>

                                                                                                {data.put_option ?
                                                                                                    (
                                                                                                        <>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ fontWeight: "bold", color: data.put_option.ltp_color }}>{data.put_option.ltp || 0}</Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ position: "relative" }}>{data.put_option.volume || 0}
                                                                                                                <div className="div-put-trade" style={{
                                                                                                                    textAlign: "left",
                                                                                                                    position: "absolute",
                                                                                                                    left: 0,
                                                                                                                    top: 0,
                                                                                                                    backgroundColor: "white",
                                                                                                                    width: "100%",
                                                                                                                    height: "100%",
                                                                                                                    paddingTop: "7px",
                                                                                                                    display: "none"
                                                                                                                }}
                                                                                                                >
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "BUY", "PE", data.put_option.ltp, data.put_option.exchange); }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                                                                                                        B
                                                                                                                    </button>
                                                                                                                    <button type="button" onClick={() => { addToCart(data.strike_price, data.put_option.token, "SELL", "PE", data.put_option.ltp, data.put_option.exchange); }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                                                                                                        S
                                                                                                                    </button>

                                                                                                                </div>
                                                                                                            </Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut} style={{ color: data.put_option.changePercentage_color }}>{data.put_option.changePercentage || 0}</Td>
                                                                                                            <Td onMouseOver={PutMouseOver} onMouseOut={PutMouseOut}>{data.put_option.currentOpenInterest || 0}</Td>
                                                                                                        </>
                                                                                                    )
                                                                                                    :
                                                                                                    (<Td colSpan="4">
                                                                                                        -
                                                                                                    </Td>)}

                                                                                            </Tr>

                                                                                        ))
                                                                                    );

                                                                                })}
                                                                            </Tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>


                                        </TabPane>
                                    </TabContent>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </div>
            </div>

            <Modal
                size="lg"
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <div className="bg-info text-white modal-header">
                    <h5 className="modal-title mt-0" style={{ "color": "#ffff" }}>Place Order</h5>
                    <div>

                    </div>
                </div>
                <div className="modal-body">

                    <div className="table-responsive mb-0" style={{ maxHeight: "300px" }}>
                        <table className="table">
                            <tbody>
                                {cartData && cartData.map((data, key) => (

                                    <tr key={key}>
                                        <td>
                                            {data.symbolName}
                                        </td>
                                        <td>
                                            <button className={(data.transactionType == "BUY") ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"}>
                                                {data.transactionType}
                                            </button>
                                        </td>
                                        <td>
                                            <InputGroup>
                                                <div
                                                    className="input-group-append"
                                                    onClick={() => {
                                                        if (data.qty > 50)
                                                            data.qty -= 50;
                                                    }}
                                                >
                                                    <Button type="button" className="btn-sm" color="primary">
                                                        <i className="mdi mdi-minus" />
                                                    </Button>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    value={data.qty}
                                                    placeholder="number"
                                                    style={{ width: "70px", height: "29px", textAlign: "center" }}
                                                    readOnly />
                                                <div
                                                    className="input-group-append"
                                                    onClick={() => {
                                                        data.qty += 50;
                                                    }}
                                                >
                                                    <Button type="button" className="btn-sm" color="primary">
                                                        <i className="mdi mdi-plus" />
                                                    </Button>
                                                </div>
                                            </InputGroup>
                                        </td>

                                        <td>
                                            <div className="col-md-12">
                                                <select value={data.tradeType} onChange={(e) => { data.tradeType = e.target.value; }} className="form-control form-select form-select-sm">
                                                    <option value="MIS">MIS</option>
                                                    <option value="NRML">NRML</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-12">
                                                <select value={data.productType} onChange={(e) => { data.productType = e.target.value; }} className="form-control form-select form-select-sm">
                                                    <option value="MARKET">MARKET</option>
                                                    <option value="LIMIT">LIMIT</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col-md-12">
                                                <input type="text" style={{ width: "80px" }} onChange={(e) => { data.price = e.target.value; }} className="form-control form-control-sm" readOnly={data.productType == "MARKET"} value={data.price}></input>
                                            </div>
                                        </td>
                                        <td>
                                            <button onClick={() => { removeFromCart(data.id); }} className="btn btn-sm btn-danger"><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>

                                ))}

                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="model-footer mb-3">
                    <div className="col-md-12" style={{ textAlign: "right" }}>

                        <button type="button" className="btn btn-sm btn-danger w-md me-2" data-dismiss="modal" onClick={() => {
                            SetcartData([]);
                            setmodal_center(false);
                        }}>Clear</button>

                        <button type="button" className="btn btn-sm btn-info text-white w-md me-2" style={{ "color": "#ffff" }} onClick={() => { PlaceBasketOrder(); }}>Place</button>

                    </div>
                </div>

                {apiSuccess && (
                    <div
                        className="alert alert-success text-center mb-0"
                        role="alert">
                        {apiSuccess}
                    </div>
                )}

                {apiError && (
                    <div
                        className="alert alert-danger text-center mb-0"
                        role="alert">
                        {apiError}
                    </div>
                )}

            </Modal>

        </React.Fragment >
    );
});

export default OptionStrategies;