import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { userResetPassword, userResetPasswordSuccessful, userResetPasswordFailed } from "../../../store/actions";

import { useSelector, useDispatch } from "react-redux";

import logodark from "../../../assets/images/logo.png";
import logolight from "../../../assets/images/logo.png";
import CarouselPage from "./CarouselPage";

const ResetPassword = props => {

  //meta title
  document.title = "Reset Password | Algobridge ";

  const dispatch = useDispatch();

  let search = window.location.search;
  let params = new URLSearchParams(search);

  const { apiData, apiSuccess, apiError } = useSelector(state => ({
    apiData: state.ResetPassword.apiData,
    apiSuccess: state.ResetPassword.apiSuccess,
    apiError: state.ResetPassword.apiError
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: params.get('email') || null,
      key: params.get('key') || null,
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your New Password"),
    }),
    onSubmit: (values) => {
      //console.log(values);
      dispatch(userResetPassword(values, props.history));
    }
  });

  useEffect(() => {
    dispatch(userResetPasswordSuccessful(null));
    dispatch(userResetPasswordFailed(null));
  }, []);

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => dispatch(userResetPasswordSuccessful(null)), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => dispatch(userResetPasswordFailed(null)), 1000)
    }
  }, [apiError]);

  useEffect(() => {
    var element = document.getElementById("div-resetpwd");
    element.scrollIntoView();
    element.scrollIntoView(false);
    element.scrollIntoView({ block: "end" });
    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }, []);

  return (
    <React.Fragment>
      <div>
        <Row className="g-0">
          <CarouselPage />
          <Col xl={3} id="div-resetpwd">
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <a href="/" className="d-block auth-logo">
                      <img
                        src={logodark}
                        alt=""
                        height="36"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logolight}
                        alt=""
                        height="36"
                        className="auth-logo-light"
                      />
                    </a>
                  </div>

                  {apiSuccess && (
                    <div
                      className="alert alert-success text-center mb-4"
                      role="alert">
                      {apiSuccess}
                    </div>
                  )}

                  {apiError && (
                    <div
                      className="alert alert-danger text-center mb-4"
                      role="alert">
                      {apiError}
                    </div>
                  )}

                  <div className="my-auto">
                    <div>
                      <h5 className="text-primary">Reset Password</h5>
                      <p className="text-muted">Change Password with Algobridge.</p>
                    </div>

                    <div className="mt-4">

                      <Form className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

                        <Input
                          name="email"
                          type="hidden"
                          value={validation.values.email || ""}
                        />

                        <Input
                          name="key"
                          type="hidden"
                          value={validation.values.key || ""}
                        />

                        <div className="form-floating mb-3">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          <Label className="form-label">Password</Label>
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >Reset</button>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p>
                          Remember It ?{" "}
                          <Link
                            to="/login"
                            className="fw-medium text-primary"
                          >
                            {" "}
                            Sign In here
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} Algobridge. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger"></i> by
                      Seventicks Software
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
