import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { LOGIN_ADMIN, LOGOUT_ADMIN } from "./actionTypes";
import { loginAdminSuccessful, loginAdminFailed, logoutAdminSuccessful, logoutAdminFailed } from "./actions";

import { loginAdminCall } from "../../../helpers/backend_helper";

function* loginAdmin({ payload: { admin, history } }) {
  try {

    var input = (admin == undefined) ? {} : admin;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(loginAdminCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      var authAdmin = output.authAdmin;
      localStorage.setItem("authAdmin", btoa(JSON.stringify(authAdmin)));
      localStorage.removeItem("authUser");
      localStorage.removeItem("optionchain_record");
      localStorage.removeItem("greek_record");
      yield put(loginAdminSuccessful(output.message))
      history.push("/admin/users");
    }
    else {
      yield put(loginAdminFailed(output.message))
    }

  } catch (error) {
    yield put(loginAdminFailed(error.message));
  }
}

function* logoutAdmin({ payload: { history } }) {
  try {
    localStorage.removeItem("authAdmin");
    history.push("/admin/login");
  } catch (error) {
    yield put(logoutAdminFailed(error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_ADMIN, loginAdmin);
  yield takeEvery(LOGOUT_ADMIN, logoutAdmin);
}

export default authSaga;
