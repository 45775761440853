const rsi_data_init_state = {
  rsi_period: "14",
  rsi_applied_price: "close",
  rsi_entry_type: "above_below",
  rsi_oversold: "30",
  rsi_overbought: "70",
  rsi_above: "30",
  rsi_below: "70"
}

module.exports = rsi_data_init_state