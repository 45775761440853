import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_FAILED,
} from "./actionTypes"

export const userResetPassword = (user, history) => {
  return {
    type: RESET_PASSWORD,
    payload: { user, history },
  }
}

export const userResetPasswordSuccessful = message => {
  return {
    type: RESET_PASSWORD_SUCCESSFUL,
    payload: message,
  }
}

export const userResetPasswordFailed = message => {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: message,
  }
}
