import {
  GET_FOLLOW_REQUESTS_SUCCESSFUL,
  GET_FOLLOW_REQUESTS_FAILED,
  FOLLOW_REQUESTS_EVENTS_SUCCESSFUL,
  FOLLOW_REQUESTS_EVENTS_FAILED,
  GET_FOLLOW_CLIENTS_SUCCESSFUL,
  GET_FOLLOW_CLIENTS_FAILED,
  FOLLOW_CLIENTS_EVENTS_SUCCESSFUL,
  FOLLOW_CLIENTS_EVENTS_FAILED,
  FOLLOW_CLIENTS_RENEWAL_SUCCESSFUL,
  FOLLOW_CLIENTS_RENEWAL_FAILED
} from "./actionTypes"

const initialState = {
  loading: false,
  apiSuccess: null,
  apiError: null,
  followRequestsData: null,
  followClientsData: null,
}

const MyClients = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOLLOW_REQUESTS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        followRequestsData: action.payload
      }
      break
    case GET_FOLLOW_REQUESTS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        followRequestsData: null
      }
      break
    case GET_FOLLOW_CLIENTS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        followClientsData: action.payload
      }
      break
    case GET_FOLLOW_CLIENTS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        followClientsData: null
      }
      break
    case FOLLOW_REQUESTS_EVENTS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case FOLLOW_REQUESTS_EVENTS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case FOLLOW_CLIENTS_EVENTS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case FOLLOW_CLIENTS_EVENTS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case FOLLOW_CLIENTS_RENEWAL_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case FOLLOW_CLIENTS_RENEWAL_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default MyClients
