import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//ADMIN MODULE
import AdminLogin from "./admin/login/reducer"
import AdminUsers from "./admin/users/reducer"
import AdminMasters from "./admin/masters/reducer"
import AdminPayments from "./admin/payments/reducer"

//USER MODULES

// Authentication
import Login from "./users/auth/login/reducer"
import Register from "./users/auth/register/reducer"
import ForgotPassword from "./users/auth/forgetpwd/reducer"
import ResetPassword from "./users/auth/resetpwd/reducer"

import Authentication from "./users/authentication/reducer"

import MyClients from "./users/myclients/reducer"

import Trading from "./users/trading/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  Authentication,
  MyClients,
  Trading,

  AdminLogin,
  AdminUsers,
  AdminMasters,
  AdminPayments
})

export default rootReducer
