import { takeEvery, fork, put, all, call } from "redux-saga/effects"


import { RESET_PASSWORD } from "./actionTypes"
import { userResetPasswordSuccessful, userResetPasswordFailed } from "./actions"

import { resetPwdCall } from "../../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* resetPwd({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(resetPwdCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(userResetPasswordSuccessful(output.message))
    }
    else {
      yield put(userResetPasswordFailed(output.message))
    }
  } catch (error) {
    yield put(userResetPasswordFailed(error.message));
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetPwd)
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)])
}

export default resetPasswordSaga
