import {
  FORGET_PASSWORD_SUCCESSFUL,
  FORGET_PASSWORD_FAILED,
} from "./actionTypes"

const initialState = {
  apiSuccess: null,
  apiError: null
}

const ForgotPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_SUCCESSFUL:
      state = {
        ...state,     
        apiSuccess: action.payload,
        apiError: null
      }
      break
    case FORGET_PASSWORD_FAILED:
      state = {
        ...state,       
        apiSuccess: null,
        apiError: action.payload
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ForgotPassword
