import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Badge
} from "reactstrap";

import { Link } from "react-router-dom"

import Swal from 'sweetalert2'


const paymentFailed = props => {

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {
            Swal.fire({
              title: "Sorry!",
              html: "<p>You Payment was Unsuccessful. Please re-try !!!</p>",
              icon: "error",
            }).then(function (result) {
              location.href = "/marketwatch";
            })
          }

        </Container>
      </div>
    </React.Fragment>
  );
  
}

export default paymentFailed;