import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions

import axios from "axios";

import { API_URL } from "helpers/api_helper";


const Orders_History = props => {

    //meta title
    document.title = "Orders History | Algobridge ";

    const [page, setpage] = useState(0);

    const [record_type, Setrecord_type] = useState("DAILY");

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const [copierOrdersHistory, SetcopierOrdersHistory] = useState(null);

    const [bridgeOrdersHistory, SetbridgeOrdersHistory] = useState(null);

    const [customStrategiesOrdersHistory, SetcustomStrategiesOrdersHistory] = useState(null);

    const [myStrategiesOrdersHistory, SetmyStrategiesOrdersHistory] = useState(null);


    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    function LoadCopierOrdersHistory() {

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(API_URL + '/trading/getCopierOrdersHistory/' + record_type, { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                SetcopierOrdersHistory(output.data);
            }
            else {
                SetapiError(output.message);
            }

        });
    }

    function LoadBridgeOrdersHistory() {

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(API_URL + '/trading/getBridgeOrdersHistory/' + record_type, { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                SetbridgeOrdersHistory(output.data);
            }
            else {
                SetapiError(output.message);
            }

        });
    }

    function LoadCustomStrategiesOrdersHistory() {

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(API_URL + '/trading/getCustomStrategiesOrdersHistory/' + record_type, { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                SetcustomStrategiesOrdersHistory(output.data);
            }
            else {
                SetapiError(output.message);
            }

        });
    }

    function LoadMyStrategiesOrdersHistory() {

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(API_URL + '/trading/getMyStrategiesOrdersHistory/' + record_type, { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                SetmyStrategiesOrdersHistory(output.data);
            }
            else {
                SetapiError(output.message);
            }

        });
    }

    useEffect(() => {

        LoadCopierOrdersHistory();

        LoadBridgeOrdersHistory();

        LoadCustomStrategiesOrdersHistory();

        LoadMyStrategiesOrdersHistory();

    }, [record_type]);

    useEffect(() => {

        LoadCopierOrdersHistory();

        LoadBridgeOrdersHistory();

        LoadCustomStrategiesOrdersHistory();

        LoadMyStrategiesOrdersHistory();

    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}

                    <Breadcrumbs title="Dashboards" breadcrumbItem="Orders History" />

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col md={12} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                                            <div className="form-floating mb-3 me-3 float-end" style={{ width: "150px" }}>
                                                <select value={record_type} name="transaction_type" className="form-select" onChange={(e) => { Setrecord_type(e.target.value) }}>
                                                    <option value="DAILY">DAILY</option>
                                                    <option value="WEEKLY">WEEKLY</option>
                                                    <option value="MONTHLY">MONTHLY</option>
                                                </select>
                                                <label htmlFor="floatingSelectGrid">Report Type</label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    setpage(0);
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="fas fa-home"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Copier History ( {copierOrdersHistory ? copierOrdersHistory.length : 0} )</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    setpage(0);
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="fas fa-home"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Bridge History ( {bridgeOrdersHistory ? bridgeOrdersHistory.length : 0} )</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "3",
                                                })}
                                                onClick={() => {
                                                    setpage(0);
                                                    toggleCustom("3");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="fas fa-home"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Custom Strategies History ( {customStrategiesOrdersHistory ? customStrategiesOrdersHistory.length : 0} )</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "4",
                                                })}
                                                onClick={() => {
                                                    setpage(0);
                                                    toggleCustom("4");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="fas fa-home"></i>
                                                </span>
                                                <span className="d-none d-sm-block">My Strategies History ( {myStrategiesOrdersHistory ? myStrategiesOrdersHistory.length : 0} )</span>
                                            </NavLink>
                                        </NavItem>

                                    </Nav>

                                    <TabContent
                                        activeTab={customActiveTab}
                                        className="p-3 text-muted"
                                    >

                                        <TabPane tabId="1">
                                            <Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="1">Email</Th>
                                                                    <Th data-priority="2">Broker</Th>
                                                                    <Th data-priority="3">Symbol</Th>
                                                                    <Th data-priority="4">Transaction Type</Th>
                                                                    <Th data-priority="5">Qty</Th>
                                                                    <Th data-priority="6">Status</Th>
                                                                    <Th data-priority="6">Created Date</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {copierOrdersHistory &&

                                                                    copierOrdersHistory.map((data, key) => (
                                                                        <Tr key={key}>
                                                                            <Td>{data.email}</Td>
                                                                            <Td>{data.broker}</Td>
                                                                            <Td>{data.tradeProperties.symbol}</Td>
                                                                            <Td>{data.tradeProperties.transactionType}</Td>
                                                                            <Td>{data.tradeProperties.qty}</Td>
                                                                            <Td>
                                                                                {
                                                                                    (JSON.stringify(data.response).toLocaleLowerCase().indexOf("success") > -1 || JSON.stringify(data.response).toLocaleLowerCase().indexOf("ok") > -1) ?
                                                                                        (
                                                                                            <button type="button" className="btn btn-sm btn-success me-2" onClick={() => { }}>Success</button>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <button type="button" className="btn btn-sm btn-danger me-2" onClick={() => { }}>Failed</button>
                                                                                        )
                                                                                }
                                                                            </Td>
                                                                            <Td>{new Date(data.created_at).toLocaleString()}</Td>
                                                                        </Tr>
                                                                    ))}
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="1">Email</Th>
                                                                    <Th data-priority="2">Broker</Th>
                                                                    <Th data-priority="3">Symbol</Th>
                                                                    <Th data-priority="4">Transaction Type</Th>
                                                                    <Th data-priority="5">Qty</Th>
                                                                    <Th data-priority="6">Status</Th>
                                                                    <Th data-priority="6">Created Date</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {bridgeOrdersHistory &&

                                                                    bridgeOrdersHistory.map((data, key) => (
                                                                        <Tr key={key}>
                                                                            <Td>{data.email}</Td>
                                                                            <Td>{data.broker}</Td>
                                                                            <Td>{data.tradeProperties.symbol}</Td>
                                                                            <Td>{data.tradeProperties.transactionType}</Td>
                                                                            <Td>{data.tradeProperties.qty}</Td>
                                                                            <Td>
                                                                                {
                                                                                    (JSON.stringify(data.response).toLocaleLowerCase().indexOf("success") > -1 || JSON.stringify(data.response).toLocaleLowerCase().indexOf("ok") > -1) ?
                                                                                        (
                                                                                            <button type="button" className="btn btn-sm btn-success me-2" onClick={() => { }}>Success</button>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <button type="button" className="btn btn-sm btn-danger me-2" onClick={() => { }}>Failed</button>
                                                                                        )
                                                                                }
                                                                            </Td>
                                                                            <Td>{new Date(data.created_at).toLocaleString()}</Td>
                                                                        </Tr>
                                                                    ))}
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="3">
                                            <Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="1">Email</Th>
                                                                    <Th data-priority="2">Broker</Th>
                                                                    <Th data-priority="3">Symbol</Th>
                                                                    <Th data-priority="4">Transaction Type</Th>
                                                                    <Th data-priority="5">Qty</Th>
                                                                    <Th data-priority="6">Status</Th>
                                                                    <Th data-priority="6">Created Date</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {customStrategiesOrdersHistory &&

                                                                    customStrategiesOrdersHistory.map((data, key) => (
                                                                        <Tr key={key}>
                                                                            <Td>{data.email}</Td>
                                                                            <Td>{data.broker}</Td>
                                                                            <Td>{data.tradeProperties.symbol}</Td>
                                                                            <Td>{data.tradeProperties.transactionType}</Td>
                                                                            <Td>{data.tradeProperties.qty}</Td>
                                                                            <Td>
                                                                                {
                                                                                    (JSON.stringify(data.response).toLocaleLowerCase().indexOf("success") > -1 || JSON.stringify(data.response).toLocaleLowerCase().indexOf("ok") > -1) ?
                                                                                        (
                                                                                            <button type="button" className="btn btn-sm btn-success me-2" onClick={() => { }}>Success</button>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <button type="button" className="btn btn-sm btn-danger me-2" onClick={() => { }}>Failed</button>
                                                                                        )
                                                                                }
                                                                            </Td>
                                                                            <Td>{new Date(data.created_at).toLocaleString()}</Td>
                                                                        </Tr>
                                                                    ))}
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="4">
                                            <Row>


                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="1">Email</Th>
                                                                    <Th data-priority="2">Broker</Th>
                                                                    <Th data-priority="3">Symbol</Th>
                                                                    <Th data-priority="4">Transaction Type</Th>
                                                                    <Th data-priority="5">Qty</Th>
                                                                    <Th data-priority="6">Status</Th>
                                                                    <Th data-priority="6">Created Date</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {myStrategiesOrdersHistory &&

                                                                    myStrategiesOrdersHistory.map((data, key) => (
                                                                        <Tr key={key}>
                                                                            <Td>{data.email}</Td>
                                                                            <Td>{data.broker}</Td>
                                                                            <Td>{data.tradeProperties.symbol}</Td>
                                                                            <Td>{data.tradeProperties.transactionType}</Td>
                                                                            <Td>{data.tradeProperties.qty}</Td>
                                                                            <Td>
                                                                                {
                                                                                    (JSON.stringify(data.response).toLocaleLowerCase().indexOf("success") > -1 || JSON.stringify(data.response).toLocaleLowerCase().indexOf("ok") > -1) ?
                                                                                        (
                                                                                            <button type="button" className="btn btn-sm btn-success me-2" onClick={() => { }}>Success</button>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <button type="button" className="btn btn-sm btn-danger me-2" onClick={() => { }}>Failed</button>
                                                                                        )
                                                                                }
                                                                            </Td>
                                                                            <Td>{new Date(data.created_at).toLocaleString()}</Td>
                                                                        </Tr>
                                                                    ))}
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>

                                    </TabContent>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Orders_History
