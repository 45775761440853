import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
} from "reactstrap";

import EVENTS from './events';

function MACD(props) {

  var item = props.item;

  var isMobile = props.isMobile;

  return (

    <Card>
      <CardBody>
        <Row>
          <Col col={6}>
            <table width={"100%"}>
              <tbody>
                <tr>
                  <td colSpan={2} className="pb-1">
                    <p className="fs-14 fw-light mb-0">Strategy</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="pb-2">
                    <h5 className="mb-0">{item.strategy_name || item.strategy.replaceAll("_", " ")}</h5>
                  </td>
                </tr>
                <tr>
                  <td className="pb-1">
                    <p className="fs-14 fw-light mb-0">Timeframe</p>
                  </td>
                  <td>
                    <p className="fs-14 fw-light mb-0">Parameters</p>
                  </td>
                </tr>
                <tr>
                  <td className="pb-2">
                    <p className="fs-14 fw-bold mb-0">{item.timeframe}</p>
                  </td>
                  <td>
                    <p className="fs-14 fw-bold mb-0">{item.macd_fast_ema + " , " + item.macd_slow_ema + " , " + item.macd_sma + " , " + item.macd_applied_price.toUpperCase()}</p>
                  </td>
                </tr>
                <tr>
                  <td className="pb-0">
                    <p className="fs-14 fw-light mb-0">Entry Type</p>
                  </td>
                  <td>
                    <p className="fs-14 fw-light mb-0">Live Price</p>
                  </td>
                </tr>
                <tr>
                  <td className="pb-0">
                    {
                      (item.macd_entry_type == "above_below") ?
                        (<span className="badge mb-0 rounded-1 bg-success">{"Above " + (item.past_above || item.macd_above)}</span>)
                        :
                        (<p className="fs-14 fw-bold mb-0">CROSSOVER</p>)
                    }
                  </td>
                  <td>
                    <p style={{ color: (item.pnl > 0) ? "green" : "red" }} className="fs-14 fw-bold  mb-0">{item.pnl + " , " + item.pnl_percentage + "%"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    {
                      (item.macd_entry_type == "above_below") ?
                        (<span className="badge mb-0 rounded-1 bg-danger">{"Below " + (item.past_below || item.macd_below)}</span>)
                        :
                        ("")
                    }
                  </td>
                  <td>
                    <p style={{ color: (item.trend == "UP") ? "green" : "red" }} className="fs-14 fw-bold mb-0">{item.live_price}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col col={6}>
            <table width={"100%"}>
              <tbody>
                <tr>
                  <td colSpan={2} className="pb-1">
                    <p className="fs-14 fw-light mb-0">Symbol</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="pb-2">
                    {(item.strategy_type && item.strategy_type == "OPTIONS") ?
                      (<h5 onClick={() => { item.text = item.symbol + " " + ((item.day > 0 ? item.day : "")) + " " + item.month_name + " " + item.year; }} className="mb-0">{isMobile ? (item.text || item.symbol.split(" ")[0] + " ...") : (item.symbol + " " + ((item.day > 0 ? item.day : "")) + " " + item.month_name + " " + item.year)}</h5>)
                      :
                      (
                        <h5 onClick={() => { item.text = item.symbol; }} className="mb-0">{isMobile ? (item.text || item.symbol.split(" ")[0] + " ...") : item.symbol}</h5>
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td className="pb-1">
                    <p className="fs-14 fw-light mb-0">Signal</p>
                  </td>
                  <td>
                    <p className="fs-14 fw-light mb-0">Qty</p>
                  </td>
                </tr>
                <tr>
                  <td className="pb-2">
                    {item.signal.length > 0 ?
                      (<p className="fs-14 fw-bold mb-0">{item.signal[item.signal.length - 1].transactionType}</p>)
                      :
                      (<p className="fs-14 fw-bold mb-0">NO SIGNAL</p>)
                    }

                  </td>
                  <td>
                    <p className="fs-14 fw-bold mb-0">{item.qty}</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p className="fs-14 fw-light mb-0">{item.order_type + " , " + item.trade_type.replaceAll("_", " ") + " , " + item.exchange}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col col={12} className="pb-1 text-end">
            <EVENTS item={item} EditStrategy={props.EditStrategy} DeleteStrategy={props.DeleteStrategy} StrategyEvents={props.StrategyEvents} SquareoffStrategy={props.SquareoffStrategy} />
          </Col>
        </Row>
      </CardBody>
    </Card>

  );
}

export default MACD;
