import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_SYMBOLS,
  GET_ORDERS,
  GET_ORDERBOOK,
  GET_TRADEBOOK,
  GET_POSITIONS,
  PLACE_ORDERS,
  ADD_MARKETWATCH,
  DELETE_MARKETWATCH,
  GET_MARKETWATCH,
  SQUAREOFF_POSITIONS,
} from "./actionTypes";
import {
  getSymbolsSuccessful,
  getSymbolsFailed,
  getOrdersSuccessful,
  getOrdersFailed,
  getOrderbookSuccessful,
  getOrderbookFailed,
  getTradebookSuccessful,
  getTradebookFailed,
  getPositionsSuccessful,
  getPositionsFailed,
  placeOrdersSuccessful,
  placeOrdersFailed,
  addMarketwatchSuccessful,
  addMarketwatchFailed,
  deleteMarketwatchSuccessful,
  deleteMarketwatchFailed,
  getMarketwatchSuccessful,
  getMarketwatchFailed,
  squareoffPositionsSuccessful,
  squareoffPositionsFailed
} from "./actions";

import {
  getSymbolsCall, getOrdersCall,squareoffOrdersCall, getOrderbookCall,
  getTradebookCall, getPositionsCall, placeOrdersCall, addMarketwatchCall, deleteMarketwatchCall, getMarketwatchCall
} from "../../../helpers/backend_helper";


function* getSymbols({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getSymbolsCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));

    // var output = {
    //   "result": "success",
    //   "data": [
    //     {
    //       "exchange": "NFO",
    //       "name": "BANKNIFTY22SEPFUT",
    //       "token": "37516",
    //       "price": "17520.00",
    //       "description": "BANKNIFTY",
    //       "trend": "UP",
    //     }
    //   ]
    // }

    //console.log("output=" + JSON.stringify(output.data));
    if (output.result == "success") {
      yield put(getSymbolsSuccessful(output.data))
    }
    else {
      yield put(getSymbolsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getSymbolsFailed(error.message));
  }
}

function* getOrders({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getOrdersCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));

    // var output = {
    //   "result": "success",
    //   "data": [
    //     {
    //       "name": "PARVEEN RANA",
    //       "userKey": "JDHFJDFHJSDH",
    //       "mobile": "9555455557",
    //       "created_at": "20-08-2022 10:00AM",
    //       "active": "true"
    //     }
    //   ]
    // }

    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getOrdersSuccessful(output.data))
    }
    else {
      yield put(getOrdersFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getOrdersFailed(error.message));
  }
}

function* getOrderbook({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getOrderbookCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));

    // var output = {
    //   "result": "success",
    //   "data": [
    //     {
    //       "time": "23-8-2022 12:11 PM",
    //       "type": "BUY",
    //       "instrument": "CRUDEOIL AUG FUT",
    //       "product": "NRML",
    //       "qty": "50",
    //       "avgPrice": "20.85",
    //       "status": "COMPLETE",
    //       "tradeBy": "ALGOBRIDGE"
    //     },
    //     {
    //       "time": "23-8-2022 12:11 PM",
    //       "type": "SELL",
    //       "instrument": "CRUDEOIL AUG FUT",
    //       "product": "NRML",
    //       "qty": "50",
    //       "avgPrice": "20.85",
    //       "status": "COMPLETE",
    //       "tradeBy": "ALGOBRIDGE"
    //     }
    //   ]
    // }

    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getOrderbookSuccessful(output.data))
    }
    else {
      yield put(getOrderbookFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getOrderbookFailed(error.message));
  }
}

function* getTradebook({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getTradebookCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));

    // var output = {
    //   "result": "success",
    //   "data": [
    //     {
    //       "time": "23-8-2022 12:11 PM",
    //       "type": "BUY",
    //       "instrument": "CRUDEOIL AUG FUT",
    //       "product": "NRML",
    //       "qty": "50",
    //       "avgPrice": "20.85",
    //       "status": "COMPLETE",
    //       "tradeBy": "ALGOBRIDGE"
    //     },
    //     {
    //       "time": "23-8-2022 12:11 PM",
    //       "type": "SELL",
    //       "instrument": "CRUDEOIL AUG FUT",
    //       "product": "NRML",
    //       "qty": "50",
    //       "avgPrice": "20.85",
    //       "status": "COMPLETE",
    //       "tradeBy": "ALGOBRIDGE"
    //     }
    //   ]
    // }

    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getTradebookSuccessful(output.data))
    }
    else {
      yield put(getTradebookFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getTradebookFailed(error.message));
  }
}

function* getPositions({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getPositionsCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));

    // var output = {
    //   "result": "success",
    //   "data": [
    //     {
    //       "time": "23-8-2022 12:11 PM",
    //       "type": "BUY",
    //       "instrument": "CRUDEOIL AUG FUT",
    //       "product": "NRML",
    //       "qty": "50",
    //       "avgPrice": "20.85",
    //       "status": "COMPLETE",
    //       "tradeBy": "ALGOBRIDGE"
    //     },
    //     {
    //       "time": "23-8-2022 12:11 PM",
    //       "type": "SELL",
    //       "instrument": "CRUDEOIL AUG FUT",
    //       "product": "NRML",
    //       "qty": "50",
    //       "avgPrice": "20.85",
    //       "status": "COMPLETE",
    //       "tradeBy": "ALGOBRIDGE"
    //     }
    //   ]
    // }

    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getPositionsSuccessful(output.data))
    }
    else {
      yield put(getPositionsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getPositionsFailed(error.message));
  }
}

function* placeOrders({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(placeOrdersCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(placeOrdersSuccessful(output.message))
    }
    else {
      yield put(placeOrdersFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(placeOrdersFailed(error.message));
  }
}

function* squareoffOrders({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(squareoffOrdersCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(squareoffPositionsSuccessful(output.message))
    }
    else {
      yield put(squareoffPositionsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(squareoffPositionsFailed(error.message));
  }
}

function* addMarketwatch({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(addMarketwatchCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(addMarketwatchSuccessful(output.message))
    }
    else {
      yield put(addMarketwatchFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(addMarketwatchFailed(error.message));
  }
}

function* deleteMarketwatch({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(deleteMarketwatchCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(deleteMarketwatchSuccessful(output.message))
    }
    else {
      yield put(deleteMarketwatchFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(deleteMarketwatchFailed(error.message));
  }
}

function* getMarketwatch({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;
    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    input.email = JSON.parse(authUser).email;

    console.log("input=" + JSON.stringify(input));

    const output = yield call(getMarketwatchCall, input);

    //console.log("output=" + JSON.stringify(output));

    // //console.log("input=" + JSON.stringify(input));

    // var output = {
    //   "result": "success",
    //   "data": [
    //     {
    //       "exchange": "NFO",
    //       "name": "BANKNIFTY22SEPFUT",
    //       "token": "37516",
    //       "price": "17520.00",
    //       "description": "BANKNIFTY",
    //       "trend": "UP",
    //     },
    //     {
    //       "exchange": "NSE",
    //       "name": "SBIN-EQ",
    //       "token": "3045",
    //       "price": "513.00",
    //       "description": "State Bank Of India",
    //       "trend": "DN",
    //     }
    //   ]
    // }

    // //console.log("output=" + JSON.stringify(output));
    if (output.result == "success") {
      yield put(getMarketwatchSuccessful(output.data))
    }
    else {
      yield put(getMarketwatchFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getMarketwatchFailed(error.message));
  }
}


function* TradingSaga() {
  yield takeEvery(GET_SYMBOLS, getSymbols);
  yield takeEvery(GET_ORDERS, getOrders);
  yield takeEvery(GET_ORDERBOOK, getOrderbook);
  yield takeEvery(GET_TRADEBOOK, getTradebook);
  yield takeEvery(GET_POSITIONS, getPositions);
  yield takeEvery(PLACE_ORDERS, placeOrders);
  yield takeEvery(ADD_MARKETWATCH, addMarketwatch);
  yield takeEvery(DELETE_MARKETWATCH, deleteMarketwatch);
  yield takeEvery(GET_MARKETWATCH, getMarketwatch);
  yield takeEvery(SQUAREOFF_POSITIONS, squareoffOrders);
}

export default TradingSaga;
