export const GET_SYMBOLS = "GET_SYMBOLS"
export const GET_SYMBOLS_SUCCESSFUL = "GET_SYMBOLS_SUCCESSFUL"
export const GET_SYMBOLS_FAILED = "GET_SYMBOLS_FAILED"

export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESSFUL = "GET_ORDERS_SUCCESSFUL"
export const GET_ORDERS_FAILED = "GET_ORDERS_FAILED"

export const GET_ORDERBOOK = "GET_ORDERBOOK"
export const GET_ORDERBOOK_SUCCESSFUL = "GET_ORDERBOOK_SUCCESSFUL"
export const GET_ORDERBOOK_FAILED = "GET_ORDERBOOK_FAILED"

export const GET_TRADEBOOK = "GET_TRADEBOOK"
export const GET_TRADEBOOK_SUCCESSFUL = "GET_TRADEBOOK_SUCCESSFUL"
export const GET_TRADEBOOK_FAILED = "GET_TRADEBOOK_FAILED"

export const GET_POSITIONS = "GET_POSITIONS"
export const GET_POSITIONS_SUCCESSFUL = "GET_POSITIONS_SUCCESSFUL"
export const GET_POSITIONS_FAILED = "GET_POSITIONS_FAILED"

export const PLACE_ORDERS = "PLACE_ORDERS"
export const PLACE_ORDERS_SUCCESSFUL = "PLACE_ORDERS_SUCCESSFUL"
export const PLACE_ORDERS_FAILED = "PLACE_ORDERS_FAILED"

export const ADD_MARKETWATCH = "ADD_MARKETWATCH"
export const ADD_MARKETWATCH_SUCCESSFUL = "ADD_MARKETWATCH_SUCCESSFUL"
export const ADD_MARKETWATCH_FAILED = "ADD_MARKETWATCH_FAILED"

export const DELETE_MARKETWATCH = "DELETE_MARKETWATCH"
export const DELETE_MARKETWATCH_SUCCESSFUL = "DELETE_MARKETWATCH_SUCCESSFUL"
export const DELETE_MARKETWATCH_FAILED = "DELETE_MARKETWATCH_FAILED"

export const GET_MARKETWATCH = "GET_MARKETWATCH"
export const GET_MARKETWATCH_SUCCESSFUL = "GET_MARKETWATCH_SUCCESSFUL"
export const GET_MARKETWATCH_FAILED = "GET_MARKETWATCH_FAILED"

export const RESET_APIEVENT = "RESET_APIEVENT"

export const SQUAREOFF_POSITIONS = "SQUAREOFF_POSITIONS"
export const SQUAREOFF_POSITIONS_SUCCESSFUL = "SQUAREOFF_POSITIONS_SUCCESSFUL"
export const SQUAREOFF_POSITIONS_FAILED = "SQUAREOFF_POSITIONS_FAILED"