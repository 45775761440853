import { API_URL } from "helpers/api_helper";

import axios from "axios";

import socketio from "socket.io-client";

var socket = socketio.connect(API_URL);

function startSocket() {
    // client-side
    socket.on("connect", () => {
        console.log("socket connected");
    });

    socket.on("disconnect", () => {
        console.log("socket disconnected");
        startSocket();
    });
}

startSocket();

export const socket_io = socket;

export const AddToMarketDataSocketInstruments = function (instruments) {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
        "email": email,
        "instruments": instruments
    };

    const headers = {
        'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/sockets/AddToMarketDataSocketInstruments', JSON.stringify(obj), { headers }).then(response => {

        //console.log(response)

    });
}

export const AddToOptionChainDataSocketInstruments = function (instruments) {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
        "email": email,
        "instruments": instruments
    };

    const headers = {
        'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/sockets/AddToOptionChainDataSocketInstruments', JSON.stringify(obj), { headers }).then(response => {

        //console.log(response)

    });
}




