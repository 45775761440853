import {
  LOGIN_USER,
  LOGIN_USER_SUCCESSFUL,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESSFUL,
  LOGOUT_USER_FAILED,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginUserSuccessful = user => {
  return {
    type: LOGIN_USER_SUCCESSFUL,
    payload: user,
  }
}

export const loginUserFailed = user => {
  return {
    type: LOGIN_USER_FAILED,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccessful = () => {
  return {
    type: LOGOUT_USER_SUCCESSFUL,
    payload: {},
  }
}

export const logoutUserFailed = () => {
  return {
    type: LOGOUT_USER_FAILED,
    payload: {},
  }
}