import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Badge
} from "reactstrap";

import { Link } from "react-router-dom"

import Swal from 'sweetalert2'

import { API_URL } from "helpers/api_helper";

import axios from "axios";




const PaymentStatus = props => {

    const [paymentSuccess, setpaymentSuccess] = useState(false);

    const [paymentFailed, setpaymentFailed] = useState(false);

    useEffect(() => {

        async function checkPaymentStatus() {

            var merchantTransactionId = localStorage.getItem("merchantTransactionId");

            if (merchantTransactionId) {

                var obj = {};

                obj.merchantTransactionId = merchantTransactionId;

                const headers = {
                    'Content-Type': 'application/json'
                };

                await axios.post(API_URL + '/payment/phonepe-payment-status', JSON.stringify(obj), { headers }).then(response => {

                    //console.log(response) 

                    var output = response.data;

                    if (output.result == "success") {

                        Swal.fire({
                            title: "Thank you!",
                            html: "<p>Your Payment received Successfully</p><p>We have extended your Subscription !!!</p>",
                            icon: "success",
                        }).then(function (result) {
                            location.href = "/marketwatch";
                        })

                        localStorage.removeItem("merchantTransactionId");

                    }
                    else {
                        Swal.fire({
                            title: "Sorry!",
                            html: "<p>You Payment was Unsuccessful. Please re-try !!!</p>",
                            icon: "error",
                        }).then(function (result) {
                            location.href = "/marketwatch";
                        })
                    }

                });

            } else {
                location.href = "/marketwatch";
            }
        }

        checkPaymentStatus();

    }, []);




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>


                </Container>
            </div>
        </React.Fragment>
    );
}

export default PaymentStatus;