import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

import { registerUserCall } from "../../../../helpers/backend_helper";

function* registerUser({ payload: { user, history } }) {
  try {
    var input = (user == undefined) ? {} : user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(registerUserCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(registerUserSuccessful(output.message))
      history.push("/login");
    }
    else {
      yield put(registerUserFailed(output.message))
    }

  } catch (error) {
    console.log("There was an error registering: ", error.message)
    yield put(registerUserFailed(error.message))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* registerSaga() {
  yield all([fork(watchUserRegister)])
}

export default registerSaga
