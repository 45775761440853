import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_PAYMENTS,
} from "./actionTypes";
import {
  getPaymentsSuccessful,
  getPaymentsFailed,
} from "./actions";

import {
  getPaymentsCall
} from "../../../helpers/backend_helper";

function* getPayments({ payload: { user, history } }) {
  try {
    var input = user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getPaymentsCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(getPaymentsSuccessful(output.data))
    }
    else {
      yield put(getPaymentsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getPaymentsFailed(error.message));
  }
}

function* adminPaymentsSaga() {
  yield takeEvery(GET_PAYMENTS, getPayments);
}

export default adminPaymentsSaga;
