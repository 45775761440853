import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <div dir="ltr">
                        <Carousel showThumbs={false} className="slider_css">
                          <div>
                            <div className="item">
                              <h4 className="mb-3">
                                <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                                What is Algo Bridge?
                              </h4>
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  "Algo bridge is an Algo trading platform for the Indian Stock Market. It comes with multiple account handling feature. Its the best Algo cum trade copier tool for Indian Stock Market“
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Parveen Rana
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Founder, CEO
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <h4 className="mb-3">
                                <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                                How Algo Bridge Works?
                              </h4>
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  "It's Very Simple! Just register and Login to the platform, Authenticate with broker's Login details. Now you are ready to trade using algo bridge. If you want to send orders to multiple accounts, you need to buy Master subscription“
                                </p>

                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Nowsath
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Technical Head, Algobridge
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="text-end">
                      <a href="/privacy-policy.html" target="_blank" className="fw-medium text-primary pe-3">Privacy Policy</a>
                      <a href="/refund-policy.html" target="_blank" className="fw-medium text-primary pe-3">Refund Policy</a>
                      <a href="/terms-and-conditions.html" target="_blank" className="fw-medium text-primary pe-3">Terms & Conditions</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
