import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Tooltip,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
} from "reactstrap";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

var strategyInfo_empty = {
    strategyID: "",
    strategy: "",
    strategy_name: "",
}

var instrumentInfo_empty = {
    instrumentID: "",
    strategyID: "",
    strategy: "",
    strategy_name: "",
    symbol: "",
    stoploss_on: "false",
    stoploss_type: "NORMAL",
    trailing_stop: "0",
    stoploss: "0",
    target_on: "false",
    target: "0",
    reentry_on: "false",
    squareoff_leg_on: "false",
    reentry_level: 1,
    execution_time: "09:15",
    squareoff_time: "15:00",
    symbols: []
}

var symbolInfo_empty = {
    symbolID: "",
    symbol: "",
    symbol_code: "",
    exchange: "",
    exchange_code: "",
    day: 0,
    month_name: "",
    month_value: 0,
    year: "",
    transaction_type: "BUY",
    strike_type: "CE",
    strike_gap: "0",
    qty: "1",
    pnl: 0,
    pnl_percentage: 0,
    live_price: 0
}

const MyStrategies = props => {

    //meta title
    document.title = "MyStrategies | Algobridge ";

    const [DefaultStrategies, SetDefaultStrategies] = useState(null);

    function GetDefaultStrategies() {
        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(API_URL + '/user/getMyStrategies', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetDefaultStrategies(output.data);

            }
        });
    }

    const [apiSuccess, SetapiSuccess] = useState(null)

    const [apiError, SetapiError] = useState(null)

    const [strategyInfo, SetstrategyInfo] = useState(strategyInfo_empty);

    const [instrumentInfo, SetinstrumentInfo] = useState(instrumentInfo_empty);

    const [symbolInfo, SetsymbolInfo] = useState(symbolInfo_empty);

    const [symbolsArray, SetsymbolsArray] = useState([]);

    const [instruments, Setinstruments] = useState(null);

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => SetapiSuccess(null), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => SetapiError(null), 1000)
        }
    }, [apiError]);

    useEffect(() => {
        GetDefaultStrategies();
    }, []);

    const [modal_standard, setmodal_standard] = useState(false);

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();

        // SetstrategyInfo(strategyInfo_empty);

        // SetinstrumentInfo(instrumentInfo_empty);

        // SetsymbolInfo(symbolInfo_empty);

        // SetsymbolsArray([]);
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function getExchangeCode(exchange) {
        var result = 0
        if (exchange == "NSE") {
            result = 1;
        }
        else if (exchange == "NFO") {
            result = 2;
        }
        else if (exchange == "CDS") {
            result = 3;
        }
        else if (exchange == "MCX") {
            result = 4;
        }
        else if (exchange == "BSE") {
            result = 6;
        }
        else if (exchange == "BFO") {
            result = 7;
        }
        return result;
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;


    function AddSymbol() {

        if (symbolInfo.symbol != "" && symbolInfo.month_name != "" && symbolInfo.year != "" && symbolInfo.strike_gap != "" && symbolInfo.qty != "") {

            var find_symbol = symbolInfo.symbol + symbolInfo.year + symbolInfo.month_name + "FUT";

            GetSymbolInfoByTradingSymbol(find_symbol, function (output) {

                if (output.result == "success") {

                    symbolInfo.symbol_code = output.data.token;

                    symbolInfo.exchange = output.data.exch;

                    symbolInfo.exchange_code = getExchangeCode(output.data.exch);

                    symbolInfo.symbolID = Math.random().toString(15).slice(2);

                    symbolInfo.created_at = new Date(Date.now()).toISOString();

                    var old_symbolsArray = [...symbolsArray, symbolInfo];

                    SetsymbolsArray(old_symbolsArray);

                    SetsymbolInfo(symbolInfo_empty);

                    SetinstrumentInfo({ ...instrumentInfo, "symbols": old_symbolsArray });
                }
            });

        }

    }

    function RemoveSymbol(index) {

        var old_symbolsArray = [...symbolsArray];

        old_symbolsArray.splice(index, 1);

        SetsymbolsArray(old_symbolsArray);

        SetinstrumentInfo({ ...instrumentInfo, "symbols": old_symbolsArray });

    }

    useEffect(() => {
        if (strategyInfo.strategy != "") {
            SetinstrumentInfo({
                ...instrumentInfo,
                "strategyID": strategyInfo.strategyID,
                "strategy": strategyInfo.strategy,
                "strategy_name": strategyInfo.strategy_name
            });
        }
    }, [strategyInfo]);

    const changeHandler = (e) => {

        if (e.target.name == "stoploss_on") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: ((instrumentInfo.stoploss_on == "true") ? "false" : "true") });
        }
        else if (e.target.name == "stoploss_type") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "trailing_stop") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "stoploss") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "target_on") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: ((instrumentInfo.target_on == "true") ? "false" : "true") });
        }
        else if (e.target.name == "target") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "reentry_on") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: ((instrumentInfo.reentry_on == "true") ? "false" : "true") });
        }
        else if (e.target.name == "squareoff_leg_on") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: ((instrumentInfo.squareoff_leg_on == "true") ? "false" : "true") });
        }
        else if (e.target.name == "reentry_level") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "execution_time") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "squareoff_time") {
            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "symbol") {
            SetsymbolInfo({ ...symbolInfo, [e.target.name]: e.target.value });

            SetinstrumentInfo({ ...instrumentInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "qty") {
            SetsymbolInfo({ ...symbolInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "day") {
            SetsymbolInfo({ ...symbolInfo, [e.target.name]: (e.target.value) });
        }
        else if (e.target.name == "month_value") {
            var month_name_temp = e.target.selectedOptions[0].getAttribute('data-month-name');
            var month_value_temp = e.target.value;
            SetsymbolInfo({ ...symbolInfo, month_name: month_name_temp, [e.target.name]: parseInt(month_value_temp) });
        }
        else if (e.target.name == "year") {
            SetsymbolInfo({ ...symbolInfo, [e.target.name]: (e.target.value) });
        }
        else if (e.target.name == "transaction_type") {
            SetsymbolInfo({ ...symbolInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "strike_type") {
            SetsymbolInfo({ ...symbolInfo, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "strike_gap") {
            SetsymbolInfo({ ...symbolInfo, [e.target.name]: e.target.value });
        }

    };

    function GetSymbolInfoByTradingSymbol(find_symbol, callback) {

        var obj = {};

        obj.find_symbol = find_symbol;

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/trading/getSymbolInfoByTradingSymbol', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            callback(output);

        });

    }

    function Submit() {

        var input = instrumentInfo;

        if (input.strategy == "" || input.symbol == "") {
            SetapiError("Enter All Required Fields !!!")
            return;
        }

        if (input.symbols.length == 0) {
            SetapiError("Instruments Empty !!!")
            return;
        }

        AddStrategy(input);
        setmodal_standard(false);
    }

    function AddStrategy(input) {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = input;

        obj.email = email;

        const headers = {
            'Content-Type': 'application/json'
        };

        var url = '';

        if (input.instrumentID == "") {
            url = API_URL + '/user/addMyStrategies';
        } else {
            url = API_URL + '/user/updateMyStrategies';
        }

        axios.post(url, JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {

                SetapiSuccess(output.message);

                SetstrategyInfo(strategyInfo_empty);

                SetinstrumentInfo(instrumentInfo_empty);

                SetsymbolInfo(symbolInfo_empty);

                SetsymbolsArray([]);

                LoadMyStrategiesInstruments();

            }
            else {
                SetapiError(output.message);
            }

        });

    }

    useEffect(() => {
        LoadMyStrategiesInstruments();
    }, []);

    function LoadMyStrategiesInstruments() {

        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/getMyStrategiesInstruments', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response)

            var output = response.data;

            if (output.result == "success") {

                if (output.data) {

                    //console.log(JSON.stringify(output.data));

                    Setinstruments(output.data);
                }

            }
            else {
                SetapiError(output.message);
            }

        });
    }

    function EditStrategy(instrument) {

        SetstrategyInfo(strategyInfo_empty);

        SetinstrumentInfo(instrumentInfo_empty);

        SetsymbolInfo(symbolInfo_empty);

        SetsymbolsArray([]);


        var temp_strategyInfo = {
            strategyID: instrument.strategyID,
            strategy: instrument.strategy,
            strategy_name: instrument.strategy_name
        }

        var temp_instrumentInfo = {
            instrumentID: instrument.instrumentID,
            strategyID: instrument.strategyID,
            strategy: instrument.strategy,
            strategy_name: instrument.strategy_name,
            symbol: instrument.symbol,
            stoploss_on: instrument.stoploss_on,
            stoploss_type: instrument.stoploss_type,
            trailing_stop: instrument.trailing_stop,
            stoploss: instrument.stoploss,
            target_on: instrument.target_on,
            target: instrument.target,
            reentry_on: instrument.reentry_on,
            squareoff_leg_on: instrument.squareoff_leg_on,
            reentry_level: instrument.reentry_level,
            execution_time: instrument.execution_time,
            squareoff_time: instrument.squareoff_time,
            symbols: instrument.symbols
        }

        SetstrategyInfo(temp_strategyInfo);

        SetinstrumentInfo(temp_instrumentInfo);

        if (instrument.symbols.length > 0) {

            var temp_symbolsArray = [];

            for (var i = 0; i < instrument.symbols.length; i++) {

                temp_symbolsArray.push(instrument.symbols[i]);
            }

            SetsymbolsArray(temp_symbolsArray);

            SetsymbolInfo({
                symbolID: "",
                symbol: instrument.symbol,
                symbol_code: "",
                exchange: "",
                exchange_code: "",
                day: 0,
                month_name: "",
                month_value: 0,
                year: "",
                transaction_type: "BUY",
                strike_type: "CE",
                strike_gap: "0",
                qty: "1",
                pnl: 0,
                pnl_percentage: 0,
                live_price: 0
            });
        }

        tog_standard();

        return;

    }

    function StrategyEvents(instrument_index, event) {

        var old_instruments = [...instruments];

        if (event == "start") {
            old_instruments[instrument_index].active = "true";
        }
        else if (event == "stop") {
            old_instruments[instrument_index].active = "false";
        }
        else if (event == "reset") {
            old_instruments[instrument_index].active = "false";
            old_instruments[instrument_index].started = "false";
            old_instruments[instrument_index].last_signal = "waiting";
        }

        Setinstruments(old_instruments);

        AddStrategy(old_instruments[instrument_index]);

    }

    function DeleteStrategy(instrumentID) {

        if (window.confirm('Are You Sure Want To Delete ?')) {
            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            var obj = {
                "email": email,
                "instrumentID": instrumentID
            };

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/user/deleteMyStrategies', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    LoadMyStrategiesInstruments();

                }
                else {
                    SetapiError(output.message);
                }

            });
        }
    }

    function SquareoffStrategy(instrumentID) {

        if (window.confirm('Are You Sure Want To Squareoff ?')) {
            var authUser = localStorage.getItem("authUser");
            if (authUser) { authUser = atob(authUser); }
            var email = JSON.parse(authUser).email;

            var obj = {
                "email": email,
                "instrumentID": instrumentID
            };

            const headers = {
                'Content-Type': 'application/json'
            };

            axios.post(API_URL + '/trading/squareoffMyStrategiesOrder', JSON.stringify(obj), { headers }).then(response => {

                //console.log(response) 

                var output = response.data;

                if (output.result == "success") {

                    SetapiSuccess(output.message);

                    LoadStrategies();

                }
                else {
                    SetapiError(output.message);
                }

            });
        }

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        <Col lg={12}>
                            <div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        tog_standard();
                                    }}
                                    className="btn btn-primary float-end"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                >
                                    Create Strategy
                                </button>

                                <Modal
                                    isOpen={modal_standard}
                                    size="lg"
                                    toggle={() => {
                                        tog_standard();
                                    }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">
                                            Create Strategy
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setmodal_standard(false);
                                            }}
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Row className="mb-2">

                                            <Col className="mb-2" md={12}>
                                                <div className="form-floating mb-3">
                                                    <select value={strategyInfo.strategy} name="strategy" required={true} className="form-select" onChange={e => {
                                                        SetstrategyInfo({
                                                            strategyID: e.target.value,
                                                            strategy: e.target.value,
                                                            strategy_name: e.target.selectedOptions[0].getAttribute('data-name')
                                                        })
                                                    }} disabled={instrumentInfo.instrumentID != "" && true}>
                                                        <option value="">SELECT</option>
                                                        {(DefaultStrategies || []).map((option, index) => (
                                                            <>
                                                                <option data-name={option.name} value={option.strategyID}>{option.name}</option>
                                                            </>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="floatingSelectGrid">Strategy</label>
                                                </div>
                                            </Col>

                                            {strategyInfo.strategy == "SACHIN_GUPTA" && (

                                                <React.Fragment>


                                                    <fieldset className="border border-light border-2 rounded-3 p-2 mb-2">
                                                        <legend className="float-none w-auto px-3 fs-6 fw-bold">
                                                            Symbol Properties
                                                        </legend>

                                                        <Row>

                                                            <Col className="mb-2" md={3}>
                                                                <div className="form-floating mb-3">
                                                                    <select value={symbolInfo.symbol} name="symbol" className="form-select" onChange={changeHandler}>
                                                                        <option value="">SELECT</option>
                                                                        <option value="BANKNIFTY">BANKNIFTY</option>
                                                                        <option value="NIFTY">NIFTY</option>
                                                                        <option value="FINNIFTY">FINNIFTY</option>
                                                                        <option value="ABBOTINDIA">ABBOTINDIA</option>
                                                                        <option value="ABCAPITAL">ABCAPITAL</option>
                                                                        <option value="ACC">ACC</option>
                                                                        <option value="ADANIPORTS">ADANIPORTS</option>
                                                                        <option value="ALKEM">ALKEM</option>
                                                                        <option value="AMARAJABAT">AMARAJABAT</option>
                                                                        <option value="AMBUJACEM">AMBUJACEM</option>
                                                                        <option value="APOLLOTYRE">APOLLOTYRE</option>
                                                                        <option value="ATUL">ATUL</option>
                                                                        <option value="AXISBANK">AXISBANK</option>
                                                                        <option value="BAJAJ-AUTO">BAJAJ-AUTO</option>
                                                                        <option value="AARTIIND">AARTIIND</option>
                                                                        <option value="BALRAMCHIN">BALRAMCHIN</option>
                                                                        <option value="BANKBARODA">BANKBARODA</option>
                                                                        <option value="BEL">BEL</option>
                                                                        <option value="BERGEPAINT">BERGEPAINT</option>
                                                                        <option value="BHARATFORG">BHARATFORG</option>
                                                                        <option value="BHARTIARTL">BHARTIARTL</option>
                                                                        <option value="BHEL">BHEL</option>
                                                                        <option value="BIOCON">BIOCON</option>
                                                                        <option value="BPCL">BPCL</option>
                                                                        <option value="BRITANNIA">BRITANNIA</option>
                                                                        <option value="BSOFT">BSOFT</option>
                                                                        <option value="CANBK">CANBK</option>
                                                                        <option value="CANFINHOME">CANFINHOME</option>
                                                                        <option value="COALINDIA">COALINDIA</option>
                                                                        <option value="COFORGE">COFORGE</option>
                                                                        <option value="CONCOR">CONCOR</option>
                                                                        <option value="CROMPTON">CROMPTON</option>
                                                                        <option value="CUB">CUB</option>
                                                                        <option value="DABUR">DABUR</option>
                                                                        <option value="DALBHARAT">DALBHARAT</option>
                                                                        <option value="DEEPAKNTR">DEEPAKNTR</option>
                                                                        <option value="DELTACORP">DELTACORP</option>
                                                                        <option value="DIVISLAB">DIVISLAB</option>
                                                                        <option value="DLF">DLF</option>
                                                                        <option value="DRREDDY">DRREDDY</option>
                                                                        <option value="ESCORTS">ESCORTS</option>
                                                                        <option value="FEDERALBNK">FEDERALBNK</option>
                                                                        <option value="GAIL">GAIL</option>
                                                                        <option value="GMRINFRA">GMRINFRA</option>
                                                                        <option value="GNFC">GNFC</option>
                                                                        <option value="GODREJCP">GODREJCP</option>
                                                                        <option value="GRANULES">GRANULES</option>
                                                                        <option value="GRASIM">GRASIM</option>
                                                                        <option value="GSPL">GSPL</option>
                                                                        <option value="GUJGASLTD">GUJGASLTD</option>
                                                                        <option value="HAL">HAL</option>
                                                                        <option value="HCLTECH">HCLTECH</option>
                                                                        <option value="HDFCAMC">HDFCAMC</option>
                                                                        <option value="ASHOKLEY">ASHOKLEY</option>
                                                                        <option value="HDFCLIFE">HDFCLIFE</option>
                                                                        <option value="HEROMOTOCO">HEROMOTOCO</option>
                                                                        <option value="HINDALCO">HINDALCO</option>
                                                                        <option value="HINDCOPPER">HINDCOPPER</option>
                                                                        <option value="HINDPETRO">HINDPETRO</option>
                                                                        <option value="HONAUT">HONAUT</option>
                                                                        <option value="ICICIBANK">ICICIBANK</option>
                                                                        <option value="ICICIGI">ICICIGI</option>
                                                                        <option value="ICICIPRULI">ICICIPRULI</option>
                                                                        <option value="IDFC">IDFC</option>
                                                                        <option value="IGL">IGL</option>
                                                                        <option value="INDHOTEL">INDHOTEL</option>
                                                                        <option value="INDIACEM">INDIACEM</option>
                                                                        <option value="INFY">INFY</option>
                                                                        <option value="INTELLECT">INTELLECT</option>
                                                                        <option value="IRCTC">IRCTC</option>
                                                                        <option value="JINDALSTEL">JINDALSTEL</option>
                                                                        <option value="JUBLFOOD">JUBLFOOD</option>
                                                                        <option value="KOTAKBANK">KOTAKBANK</option>
                                                                        <option value="LALPATHLAB">LALPATHLAB</option>
                                                                        <option value="LAURUSLABS">LAURUSLABS</option>
                                                                        <option value="LICHSGFIN">LICHSGFIN</option>
                                                                        <option value="LTI">LTI</option>
                                                                        <option value="LUPIN">LUPIN</option>
                                                                        <option value="MARICO">MARICO</option>
                                                                        <option value="MARUTI">MARUTI</option>
                                                                        <option value="METROPOLIS">METROPOLIS</option>
                                                                        <option value="MFSL">MFSL</option>
                                                                        <option value="MGL">MGL</option>
                                                                        <option value="MPHASIS">MPHASIS</option>
                                                                        <option value="MUTHOOTFIN">MUTHOOTFIN</option>
                                                                        <option value="NAM-INDIA">NAM-INDIA</option>
                                                                        <option value="NATIONALUM">NATIONALUM</option>
                                                                        <option value="NAVINFLUOR">NAVINFLUOR</option>
                                                                        <option value="NESTLEIND">NESTLEIND</option>
                                                                        <option value="NMDC">NMDC</option>
                                                                        <option value="NTPC">NTPC</option>
                                                                        <option value="ONGC">ONGC</option>
                                                                        <option value="PAGEIND">PAGEIND</option>
                                                                        <option value="PETRONET">PETRONET</option>
                                                                        <option value="PFC">PFC</option>
                                                                        <option value="PIDILITIND">PIDILITIND</option>
                                                                        <option value="PIIND">PIIND</option>
                                                                        <option value="POWERGRID">POWERGRID</option>
                                                                        <option value="RAIN">RAIN</option>
                                                                        <option value="RELIANCE">RELIANCE</option>
                                                                        <option value="SAIL">SAIL</option>
                                                                        <option value="SBICARD">SBICARD</option>
                                                                        <option value="SBIN">SBIN</option>
                                                                        <option value="SHREECEM">SHREECEM</option>
                                                                        <option value="SUNPHARMA">SUNPHARMA</option>
                                                                        <option value="SUNTV">SUNTV</option>
                                                                        <option value="SYNGENE">SYNGENE</option>
                                                                        <option value="TATACHEM">TATACHEM</option>
                                                                        <option value="TATACOMM">TATACOMM</option>
                                                                        <option value="TATACONSUM">TATACONSUM</option>
                                                                        <option value="TATAPOWER">TATAPOWER</option>
                                                                        <option value="TATASTEEL">TATASTEEL</option>
                                                                        <option value="TECHM">TECHM</option>
                                                                        <option value="TORNTPOWER">TORNTPOWER</option>
                                                                        <option value="TVSMOTOR">TVSMOTOR</option>
                                                                        <option value="VOLTAS">VOLTAS</option>
                                                                        <option value="WIPRO">WIPRO</option>
                                                                        <option value="ZEEL">ZEEL</option>
                                                                        <option value="ZYDUSLIFE">ZYDUSLIFE</option>
                                                                        <option value="IPCALAB">IPCALAB</option>
                                                                        <option value="PEL">PEL</option>
                                                                        <option value="TORNTPHARM">TORNTPHARM</option>
                                                                        <option value="ABFRL">ABFRL</option>
                                                                        <option value="APOLLOHOSP">APOLLOHOSP</option>
                                                                        <option value="ASIANPAINT">ASIANPAINT</option>
                                                                        <option value="ASTRAL">ASTRAL</option>
                                                                        <option value="AUBANK">AUBANK</option>
                                                                        <option value="AUROPHARMA">AUROPHARMA</option>
                                                                        <option value="BAJFINANCE">BAJFINANCE</option>
                                                                        <option value="BALKRISIND">BALKRISIND</option>
                                                                        <option value="BATAINDIA">BATAINDIA</option>
                                                                        <option value="COLPAL">COLPAL</option>
                                                                        <option value="DIXON">DIXON</option>
                                                                        <option value="EICHERMOT">EICHERMOT</option>
                                                                        <option value="FSL">FSL</option>
                                                                        <option value="GLENMARK">GLENMARK</option>
                                                                        <option value="GODREJPROP">GODREJPROP</option>
                                                                        <option value="HAVELLS">HAVELLS</option>
                                                                        <option value="HDFCBANK">HDFCBANK</option>
                                                                        <option value="IDFCFIRSTB">IDFCFIRSTB</option>
                                                                        <option value="IEX">IEX</option>
                                                                        <option value="INDUSINDBK">INDUSINDBK</option>
                                                                        <option value="ITC">ITC</option>
                                                                        <option value="JKCEMENT">JKCEMENT</option>
                                                                        <option value="L&TFH">L&TFH</option>
                                                                        <option value="LTTS">LTTS</option>
                                                                        <option value="MCDOWELL-N">MCDOWELL-N</option>
                                                                        <option value="MINDTREE">MINDTREE</option>
                                                                        <option value="MOTHERSON">MOTHERSON</option>
                                                                        <option value="CIPLA">CIPLA</option>
                                                                        <option value="NAUKRI">NAUKRI</option>
                                                                        <option value="NBCC">NBCC</option>
                                                                        <option value="OBEROIRLTY">OBEROIRLTY</option>
                                                                        <option value="PERSISTENT">PERSISTENT</option>
                                                                        <option value="POLYCAB">POLYCAB</option>
                                                                        <option value="RBLBANK">RBLBANK</option>
                                                                        <option value="SBILIFE">SBILIFE</option>
                                                                        <option value="SIEMENS">SIEMENS</option>
                                                                        <option value="TATAMOTORS">TATAMOTORS</option>
                                                                        <option value="TITAN">TITAN</option>
                                                                        <option value="UBL">UBL</option>
                                                                        <option value="ULTRACEMCO">ULTRACEMCO</option>
                                                                        <option value="UPL">UPL</option>
                                                                        <option value="WHIRLPOOL">WHIRLPOOL</option>
                                                                        <option value="M&M">M&M</option>
                                                                        <option value="MRF">MRF</option>
                                                                        <option value="OFSS">OFSS</option>
                                                                        <option value="PVR">PVR</option>
                                                                        <option value="BAJAJFINSV">BAJAJFINSV</option>
                                                                        <option value="BANDHANBNK">BANDHANBNK</option>
                                                                        <option value="ADANIENT">ADANIENT</option>
                                                                        <option value="BOSCHLTD">BOSCHLTD</option>
                                                                        <option value="CHAMBLFERT">CHAMBLFERT</option>
                                                                        <option value="CHOLAFIN">CHOLAFIN</option>
                                                                        <option value="CUMMINSIND">CUMMINSIND</option>
                                                                        <option value="HINDUNILVR">HINDUNILVR</option>
                                                                        <option value="INDIAMART">INDIAMART</option>
                                                                        <option value="INDIGO">INDIGO</option>
                                                                        <option value="JSWSTEEL">JSWSTEEL</option>
                                                                        <option value="M&MFIN">M&MFIN</option>
                                                                        <option value="MANAPPURAM">MANAPPURAM</option>
                                                                        <option value="INDUSTOWER">INDUSTOWER</option>
                                                                        <option value="ABB">ABB</option>
                                                                        <option value="PNB">PNB</option>
                                                                        <option value="RAMCOCEM">RAMCOCEM</option>
                                                                        <option value="RECLTD">RECLTD</option>
                                                                        <option value="SRF">SRF</option>
                                                                        <option value="TCS">TCS</option>
                                                                        <option value="EXIDEIND">EXIDEIND</option>
                                                                        <option value="TRENT">TRENT</option>
                                                                        <option value="VEDL">VEDL</option>
                                                                        <option value="COROMANDEL">COROMANDEL</option>
                                                                        <option value="IDEA">IDEA</option>
                                                                        <option value="GOLD">GOLD</option>
                                                                        <option value="GOLDM">GOLDM</option>
                                                                        <option value="GOLDGUINEA">GOLDGUINEA</option>
                                                                        <option value="SILVER">SILVER</option>
                                                                        <option value="SILVERM">SILVERM</option>
                                                                        <option value="SILVERMIC">SILVERMIC</option>
                                                                        <option value="COPPER">COPPER</option>
                                                                        <option value="CRUDEOIL">CRUDEOIL</option>
                                                                        <option value="NATURALGAS">NATURALGAS</option>
                                                                        <option value="NICKEL">NICKEL</option>
                                                                        <option value="ZINC">ZINC</option>
                                                                        <option value="ALUMINUM">ALUMINUM</option>
                                                                        <option value="LEAD">LEAD</option>
                                                                    </select>
                                                                    <label htmlFor="floatingSelectGrid">Symbol</label>
                                                                </div>
                                                            </Col>

                                                            <Col className="mb-2" md={3}>
                                                                <div className="form-floating mb-3">
                                                                    <select value={symbolInfo.day} name="day" className="form-select" onChange={changeHandler}>
                                                                        <option value="0">SELECT</option>
                                                                        <option value="01">
                                                                            01
                                                                        </option>
                                                                        <option value="02">
                                                                            02
                                                                        </option>
                                                                        <option value="03">
                                                                            03
                                                                        </option>
                                                                        <option value="04">
                                                                            04
                                                                        </option>
                                                                        <option value="05">
                                                                            05
                                                                        </option>
                                                                        <option value="06">
                                                                            06
                                                                        </option>
                                                                        <option value="07">
                                                                            07
                                                                        </option>
                                                                        <option value="08">
                                                                            08
                                                                        </option>
                                                                        <option value="09">
                                                                            09
                                                                        </option>
                                                                        <option value="10">
                                                                            10
                                                                        </option>
                                                                        <option value="11">
                                                                            11
                                                                        </option>
                                                                        <option value="12">
                                                                            12
                                                                        </option>
                                                                        <option value="13">
                                                                            13
                                                                        </option>
                                                                        <option value="14">
                                                                            14
                                                                        </option>
                                                                        <option value="15">
                                                                            15
                                                                        </option>
                                                                        <option value="16">
                                                                            16
                                                                        </option>
                                                                        <option value="17">
                                                                            17
                                                                        </option>
                                                                        <option value="18">
                                                                            18
                                                                        </option>
                                                                        <option value="19">
                                                                            19
                                                                        </option>
                                                                        <option value="20">
                                                                            20
                                                                        </option>
                                                                        <option value="21">
                                                                            21
                                                                        </option>
                                                                        <option value="22">
                                                                            22
                                                                        </option>
                                                                        <option value="23">
                                                                            23
                                                                        </option>
                                                                        <option value="24">
                                                                            24
                                                                        </option>
                                                                        <option value="25">
                                                                            25
                                                                        </option>
                                                                        <option value="26">
                                                                            26
                                                                        </option>
                                                                        <option value="27">
                                                                            27
                                                                        </option>
                                                                        <option value="28">
                                                                            28
                                                                        </option>
                                                                        <option value="29">
                                                                            29
                                                                        </option>
                                                                        <option value="30">
                                                                            30
                                                                        </option>
                                                                        <option value="31">
                                                                            31
                                                                        </option>
                                                                    </select>
                                                                    <label htmlFor="floatingSelectGrid">Date</label>
                                                                </div>
                                                            </Col>

                                                            <Col className="mb-2" md={3}>
                                                                <div className="form-floating mb-3">
                                                                    <select value={symbolInfo.month_value} name="month_value" className="form-select" onChange={changeHandler}>
                                                                        <option value="">SELECT</option>
                                                                        <option data-month-value="1" data-month-name="JAN" value="1">
                                                                            JAN
                                                                        </option>
                                                                        <option data-month-value="2" data-month-name="FEB" value="2">
                                                                            FEB
                                                                        </option>
                                                                        <option data-month-value="3" data-month-name="MAR" value="3">
                                                                            MAR
                                                                        </option>
                                                                        <option data-month-value="4" data-month-name="APR" value="4">
                                                                            APR
                                                                        </option>
                                                                        <option data-month-value="5" data-month-name="MAY" value="5">
                                                                            MAY
                                                                        </option>
                                                                        <option data-month-value="6" data-month-name="JUN" value="6">
                                                                            JUN
                                                                        </option>
                                                                        <option data-month-value="7" data-month-name="JUL" value="7">
                                                                            JUL
                                                                        </option>
                                                                        <option data-month-value="8" data-month-name="AUG" value="8">
                                                                            AUG
                                                                        </option>
                                                                        <option data-month-value="9" data-month-name="SEP" value="9">
                                                                            SEP
                                                                        </option>
                                                                        <option data-month-value="10" data-month-name="OCT" value="10">
                                                                            OCT
                                                                        </option>
                                                                        <option data-month-value="11" data-month-name="NOV" value="11">
                                                                            NOV
                                                                        </option>
                                                                        <option data-month-value="12" data-month-name="DEC" value="12">
                                                                            DEC
                                                                        </option>
                                                                    </select>
                                                                    <label htmlFor="floatingSelectGrid">Month</label>
                                                                </div>
                                                            </Col>

                                                            <Col className="mb-2" md={3}>
                                                                <div className="form-floating mb-3">
                                                                    <select value={symbolInfo.year} name="year" className="form-select" onChange={changeHandler}>
                                                                        <option value="">SELECT</option>
                                                                        <option value="23">
                                                                            23
                                                                        </option>
                                                                        <option value="24">
                                                                            24
                                                                        </option>
                                                                        <option value="25">
                                                                            25
                                                                        </option>
                                                                        <option value="26">
                                                                            26
                                                                        </option>
                                                                        <option value="27">
                                                                            27
                                                                        </option>
                                                                        <option value="28">
                                                                            28
                                                                        </option>
                                                                        <option value="29">
                                                                            29
                                                                        </option>
                                                                        <option value="30">
                                                                            30
                                                                        </option>
                                                                        <option value="31">
                                                                            31
                                                                        </option>
                                                                        <option value="32">
                                                                            32
                                                                        </option>
                                                                    </select>
                                                                    <label htmlFor="floatingSelectGrid">Year</label>
                                                                </div>
                                                            </Col>

                                                            <Col className="mb-2" md={3}>
                                                                <div className="form-floating mb-3">
                                                                    <select value={symbolInfo.transaction_type} name="transaction_type" className="form-select" onChange={changeHandler}>
                                                                        <option value="BUY">BUY</option>
                                                                        <option value="SELL">SELL</option>
                                                                    </select>
                                                                    <label htmlFor="floatingSelectGrid">Trade Type</label>
                                                                </div>
                                                            </Col>

                                                            <Col className="mb-2" md={3}>
                                                                <div className="form-floating mb-3">
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="strike_gap"
                                                                        value={symbolInfo.strike_gap}
                                                                        onChange={changeHandler}
                                                                        required={true} />
                                                                    <label htmlFor="floatingSelectGrid">Strike %</label>
                                                                </div>
                                                            </Col>

                                                            <Col className="mb-2" md={3}>
                                                                <div className="form-floating mb-3">
                                                                    <select value={symbolInfo.strike_type} name="strike_type" className="form-select" onChange={changeHandler}>
                                                                        <option value="FUT">FUT</option>
                                                                        <option value="CE">CE</option>
                                                                        <option value="PE">PE</option>
                                                                    </select>
                                                                    <label htmlFor="floatingSelectGrid">Strike Type</label>
                                                                </div>
                                                            </Col>


                                                            <Col className="mb-2" md={3}>
                                                                <div className="form-floating mb-3">
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="qty"
                                                                        value={symbolInfo.qty}
                                                                        onChange={changeHandler}
                                                                        required={true} />
                                                                    <label htmlFor="floatingSelectGrid">Qty</label>
                                                                </div>
                                                            </Col>

                                                            <Col className="mb-2" md={12}>
                                                                <button onClick={() => { AddSymbol(); }} className="btn btn-sm btn-primary float-end me-1 pt-2 mb-2"><i className="dripicons-plus"></i></button>
                                                            </Col>
                                                        </Row>

                                                        <fieldset className="border border-light border-2 rounded-3 p-2 mb-2">
                                                            <legend className="float-none w-auto px-3 fs-6 fw-bold">
                                                                Selected Symbols
                                                            </legend>

                                                            <Row>
                                                                <table width={"100%"}>
                                                                    <thead>
                                                                        <th className="ps-3 pb-1">
                                                                            SYMBOL NAME
                                                                        </th>
                                                                        <th className="ps-3 pb-1">
                                                                            TRADE
                                                                        </th>
                                                                        <th className="ps-3 pb-1">
                                                                            TYPE
                                                                        </th>
                                                                        <th className="ps-3 pb-1">
                                                                            STRIKE %
                                                                        </th>
                                                                        <th className="ps-3 pb-1">
                                                                            QTY
                                                                        </th>
                                                                        <th className="ps-3 pb-1">
                                                                            ACTION
                                                                        </th>
                                                                    </thead>
                                                                    <tbody>

                                                                        {symbolsArray.map((item, index) => (

                                                                            <tr key={index}>
                                                                                <td className="ps-3 pb-1">
                                                                                    {(item.symbol + " " + ((item.day > 0 ? item.day : "")) + " " + item.month_name + " " + item.year)}
                                                                                </td>
                                                                                <td className="ps-3 pb-1">
                                                                                    {item.transaction_type}
                                                                                </td>
                                                                                <td className="ps-3 pb-1">
                                                                                    {item.strike_type}
                                                                                </td>
                                                                                <td className="ps-3 pb-1">
                                                                                    {item.strike_gap}
                                                                                </td>
                                                                                <td className="ps-3 pb-1">
                                                                                    {item.qty}
                                                                                </td>
                                                                                <td className="ps-3 pb-1">
                                                                                    <button onClick={() => { RemoveSymbol(index) }} title="Remove" data-bs-toggle="tooltip" data-bs-placement="top" id={"removetooltip_" + index} className="btn btn-sm btn-danger me-1 pt-2 mb-2"><i className="dripicons-trash"></i></button>
                                                                                </td>
                                                                            </tr>

                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </Row>
                                                        </fieldset>


                                                    </fieldset>

                                                    <fieldset className="col-md-12 border border-light border-2 rounded-3 p-2 mb-2">
                                                        <legend className="float-none w-auto px-3 fs-6 fw-bold">
                                                            Common Properties
                                                        </legend>

                                                        <Row className="m-0 p-0">
                                                            <fieldset className="col-md-8 border border-light border-2 rounded-3 p-2 mb-2">
                                                                <legend className="float-none w-auto px-3 fs-6 fw-bold">
                                                                    Stoploss Properties
                                                                </legend>

                                                                <Row>

                                                                    <Col className="mb-2" md={3}>
                                                                        <div className="form-check form-switch mt-3 mb-3">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                onClick={changeHandler}
                                                                                checked={instrumentInfo.stoploss_on == "true"}
                                                                                name="stoploss_on" />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="customSwitchsizelg"
                                                                            >
                                                                                ON/OFF
                                                                            </label>
                                                                        </div>
                                                                    </Col>

                                                                    {instrumentInfo.stoploss_on == "true" && (
                                                                        <>
                                                                            <Col className="mb-2" md={3}>
                                                                                <div className="form-floating mb-3">
                                                                                    <select value={instrumentInfo.stoploss_type} name="stoploss_type" required={true} className="form-select" onChange={changeHandler}>
                                                                                        <option value="">SELECT</option>
                                                                                        <option value="NORMAL">NORMAL</option>
                                                                                        <option value="TRAILING">TRAILING</option>
                                                                                        <option value="BREAKEVEN">BREAKEVEN</option>
                                                                                    </select>
                                                                                    <label htmlFor="floatingSelectGrid">Type</label>
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="mb-2" md={3}>
                                                                                <div className="form-floating mb-3">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="number"
                                                                                        name="stoploss"
                                                                                        value={instrumentInfo.stoploss}
                                                                                        onChange={changeHandler}
                                                                                        required={true} />
                                                                                    <label htmlFor="floatingSelectGrid">Stoploss %</label>
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                    )}

                                                                    {instrumentInfo.stoploss_on == "true" && instrumentInfo.stoploss_type == "TRAILING" && (
                                                                        <Col className="mb-2" md={3}>
                                                                            <div className="form-floating mb-3">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="number"
                                                                                    name="trailing_stop"
                                                                                    value={instrumentInfo.trailing_stop}
                                                                                    onChange={changeHandler}
                                                                                    required={true} />
                                                                                <label htmlFor="floatingSelectGrid">Trailing %</label>
                                                                            </div>
                                                                        </Col>
                                                                    )}

                                                                </Row>
                                                            </fieldset>


                                                            <fieldset className="col-md-4  border border-light border-2 rounded-3 p-2 mb-2">
                                                                <legend className="float-none w-auto px-3 fs-6 fw-bold">
                                                                    Target Properties
                                                                </legend>

                                                                <Row>

                                                                    <Col className="mb-2" md={4}>
                                                                        <div className="form-check form-switch mt-3 mb-3">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="customSwitchsizelg"
                                                                                onClick={changeHandler}
                                                                                checked={instrumentInfo.target_on == "true"}
                                                                                name="target_on"
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="customSwitchsizelg"
                                                                            >
                                                                                ON/OFF
                                                                            </label>
                                                                        </div>
                                                                    </Col>

                                                                    {instrumentInfo.target_on == "true" &&
                                                                        <Col className="mb-2" md={8}>
                                                                            <div className="form-floating mb-3">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="number"
                                                                                    name="target"
                                                                                    value={instrumentInfo.target}
                                                                                    onChange={changeHandler}
                                                                                    required={true}
                                                                                />
                                                                                <label htmlFor="floatingSelectGrid">Target %</label>
                                                                            </div>
                                                                        </Col>
                                                                    }

                                                                </Row>

                                                            </fieldset>

                                                        </Row>

                                                        <Row className="m-0 p-0">
                                                            <fieldset className="col-md-6 border border-light border-2 rounded-3 p-2 mb-2">
                                                                <legend className="float-none w-auto px-3 fs-6 fw-bold">
                                                                    Re Entry Properties
                                                                </legend>

                                                                <Row>

                                                                    <Col className="mb-2" md={4}>
                                                                        <div
                                                                            className="form-check form-switch mt-3 mb-3"
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="reentry_on"
                                                                                name="reentry_on"
                                                                                checked={instrumentInfo.reentry_on == "true"}
                                                                                onClick={changeHandler}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="customSwitchsizelg"
                                                                            >
                                                                                ON/OFF
                                                                            </label>
                                                                        </div>
                                                                    </Col>

                                                                    {instrumentInfo.reentry_on == "true" &&
                                                                        <Col className="mb-2" md={4}>
                                                                            <div className="form-floating mb-3">
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="number"
                                                                                    name="reentry_level"
                                                                                    value={instrumentInfo.reentry_level}
                                                                                    onChange={changeHandler}
                                                                                    required={true} />
                                                                                <label htmlFor="floatingSelectGrid">LEVELS</label>
                                                                            </div>
                                                                        </Col>
                                                                    }

                                                                </Row>
                                                            </fieldset>

                                                            <fieldset className="col-md-6 border border-light border-2 rounded-3 p-2 mb-2">
                                                                <legend className="float-none w-auto px-3 fs-6 fw-bold">
                                                                    Time Properties
                                                                </legend>
                                                                <Row>
                                                                    <Col className="mb-2" md={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <input
                                                                                className="form-control"
                                                                                type="time"
                                                                                id="example-time-input"
                                                                                value={instrumentInfo.execution_time}
                                                                                name="execution_time"
                                                                                onChange={changeHandler}
                                                                                required={true} />
                                                                            <label htmlFor="floatingSelectGrid">Entry Time</label>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="mb-2" md={6}>
                                                                        <div className="form-floating mb-3">
                                                                            <input
                                                                                className="form-control"
                                                                                type="time"
                                                                                id="example-time-input"
                                                                                value={instrumentInfo.squareoff_time}
                                                                                name="squareoff_time"
                                                                                onChange={changeHandler}
                                                                                required={true} />
                                                                            <label htmlFor="floatingSelectGrid">Exit Time</label>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </fieldset>

                                                            <fieldset className="col-md-6 border border-light border-2 rounded-3 p-2 mb-2">
                                                                <legend className="float-none w-auto px-3 fs-6 fw-bold">
                                                                    Single Squareoff Leg
                                                                </legend>

                                                                <Row>

                                                                    <Col className="mb-2" md={4}>
                                                                        <div
                                                                            className="form-check form-switch mt-3 mb-3"
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="squareoff_leg_on"
                                                                                name="squareoff_leg_on"
                                                                                checked={instrumentInfo.squareoff_leg_on == "true"}
                                                                                onClick={changeHandler}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="customSwitchsizelg"
                                                                            >
                                                                                ON/OFF
                                                                            </label>
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                            </fieldset>


                                                        </Row>
                                                    </fieldset>

                                                </React.Fragment>

                                            )}

                                            <Col className="mb-2" md={12}>
                                                <button type="button" onClick={() => { Submit() }} className="btn btn-primary float-end">
                                                    Add Strategy
                                                </button>
                                            </Col>
                                            {
                                                apiSuccess && (
                                                    <div
                                                        className="alert alert-success text-center mb-0"
                                                        role="alert">
                                                        {apiSuccess}
                                                    </div>
                                                )
                                            }

                                            {
                                                apiError && (
                                                    <div
                                                        className="alert alert-danger text-center mb-0"
                                                        role="alert">
                                                        {apiError}
                                                    </div>
                                                )
                                            }

                                        </Row>
                                    </div>

                                </Modal>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-3">

                        {instruments && instruments.map((instrument, instrument_index) => (

                            <Col md={12} key={instrument_index}>

                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col col={6}>

                                                <table width={"100%"}>
                                                    <thead>
                                                        <th className="ps-3 pb-3">
                                                            STRATEGY
                                                        </th>
                                                        <th className="ps-3 pb-3">
                                                            SYMBOL NAME
                                                        </th>
                                                        <th className="ps-3 pb-3">
                                                            TRADE
                                                        </th>
                                                        <th className="ps-3 pb-3">
                                                            TYPE
                                                        </th>
                                                        <th className="ps-3 pb-3">
                                                            STOPLOSS
                                                        </th>
                                                        <th className="ps-3 pb-3">
                                                            TARGET
                                                        </th>
                                                        <th className="ps-3 pb-3">
                                                            RE-ENTRY
                                                        </th>
                                                        <th className="ps-3 pb-3">
                                                            PNL
                                                        </th>
                                                        <th className="ps-3 pb-3">
                                                            ACTION
                                                        </th>
                                                    </thead>
                                                    <tbody>
                                                        {instrument.symbols.length > 0 && instrument.symbols.map((item, symbol_index) => (

                                                            (instrument.strategy == "SACHIN_GUPTA" && (
                                                                <tr>
                                                                    <td className="ps-3 pb-3">
                                                                        {instrument.strategy_name}
                                                                    </td>
                                                                    <td className="ps-3 pb-3">
                                                                        {isMobile ? (item.text || item.symbol.split(" ")[0] + " ...") : (item.symbol + " " + ((item.day > 0 ? item.day : "")) + " " + item.month_name + " " + item.year)}
                                                                    </td>
                                                                    <td className="ps-3 pb-3">
                                                                        {item.transaction_type}
                                                                    </td>
                                                                    <td className="ps-3 pb-3">
                                                                        {item.strike_type}
                                                                    </td>
                                                                    <td className="ps-3 pb-3">
                                                                        {instrument.stoploss}
                                                                    </td>
                                                                    <td className="ps-3 pb-3">
                                                                        {instrument.target}
                                                                    </td>
                                                                    <td className="ps-3 pb-3">
                                                                        {(instrument.reentry_on == "true") ? instrument.reentry_level : 0}
                                                                    </td>
                                                                    <td className="ps-3 pb-3">
                                                                        <p style={{ color: (item.pnl > 0) ? "green" : "red" }} className="fs-14 fw-bold  mb-0">{item.pnl + " , " + item.pnl_percentage + "%"}</p>
                                                                    </td>
                                                                    <td className="ps-3 pb-3" rowSpan={instrument.symbols.length} style={{ verticalAlign: "middle" }}>
                                                                        {symbol_index == 0 && (
                                                                            <div>
                                                                                {instrument.active == "false" ? (
                                                                                    <>
                                                                                        <button onClick={() => { StrategyEvents(instrument_index, "start") }} title="Start" data-bs-toggle="tooltip" data-bs-placement="top" id={"starttooltip_" + symbol_index} className="btn btn-sm btn-soft btn-success me-1 pt-2 mb-2"><i className="dripicons-media-play"></i></button>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <button onClick={() => { StrategyEvents(instrument_index, "stop") }} title="Stop" data-bs-toggle="tooltip" data-bs-placement="top" id={"stoptooltip_" + symbol_index} className="btn btn-sm btn-secondary me-1 pt-2 mb-2"><i className="dripicons-media-stop"></i></button>
                                                                                    </>
                                                                                )}

                                                                                <button onClick={() => { SquareoffStrategy(instrument.instrumentID); }} title="Squareoff" data-bs-toggle="tooltip" data-bs-placement="top" id={"squaretooltip_" + symbol_index} className="btn btn-sm btn-info me-1 pt-2 mb-2"><i className="dripicons-time-reverse"></i></button>

                                                                                <button onClick={() => { EditStrategy(instrument) }} title="Edit" data-bs-toggle="tooltip" data-bs-placement="top" id={"edittooltip_" + symbol_index} className="btn btn-sm btn-warning me-1 pt-2 mb-2"><i className="dripicons-document-edit"></i></button>


                                                                                <button onClick={() => { StrategyEvents(instrument_index, "reset") }} title="Reset" data-bs-toggle="tooltip" data-bs-placement="top" id={"resettooltip_" + symbol_index} className="btn btn-sm btn-dark me-1 pt-2 mb-2"><i className="bx bx-reset"></i></button>

                                                                                <button onClick={() => { DeleteStrategy(instrument.instrumentID) }} title="Delete" data-bs-toggle="tooltip" data-bs-placement="top" id={"deletetooltip_" + symbol_index} className="btn btn-sm btn-danger me-1 pt-2 mb-2"><i className="dripicons-trash"></i></button>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))

                                                        ))}
                                                    </tbody>
                                                </table>

                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>


                            </Col>

                        ))}

                    </Row>

                </Container>
            </div >
        </React.Fragment >);
}

export default MyStrategies;