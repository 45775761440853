import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  const [role, setRole] = useState("");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      var authUser = localStorage.getItem("authUser");
      if (authUser) {
        authUser = atob(authUser);
      }
      const obj = JSON.parse(authUser);
      setusername(obj.name || "User");
      setRole(obj.role || "");
    }
    else if (localStorage.getItem("authAdmin")) {
      var authAdmin = localStorage.getItem("authAdmin");
      if (authAdmin) {
        authAdmin = atob(authAdmin);
      }
      const obj = JSON.parse(authAdmin);
      setusername(obj.name || "Admin");
      setRole(obj.role || "");
    }
  }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          {(role == "user" || role == "master") && (
            <>
              <Link to="/settings" className="dropdown-item" style={{ position: "relative" }}>
                <i className="bx bx-certification font-size-16 align-middle me-1 text-danger" />
                <span style={{ position: "absolute", bottom: "3px" }}>{props.t("Settings")}</span>

              </Link><Link to="/freebies" className="dropdown-item" style={{ position: "relative" }}>
                <i className="bx bx-badge-check font-size-16 align-middle me-1 text-danger" />
                <span style={{ position: "absolute", bottom: "3px" }}>{props.t("Freebies")}</span>
              </Link>

              <Link to="/optionchain" className="dropdown-item" style={{ position: "relative" }}>
                <i className="bx bx-sort font-size-16 align-middle me-1 text-danger" />
                <span style={{ position: "absolute", bottom: "3px" }}>{props.t("Option Chain")}</span>
              </Link>
              <Link to="/mysubscriptions" className="dropdown-item" style={{ position: "relative" }}>
                <i className="bx bx-rupee font-size-16 align-middle me-1 text-danger" />
                <span style={{ position: "absolute", bottom: "3px" }}>{props.t("My Subscriptions")}</span>
              </Link>

              <Link to="/wallet" className="dropdown-item" style={{ position: "relative" }}>
                <i className="bx bx-wallet font-size-16 align-middle me-1 text-danger" />
                <span style={{ position: "absolute", bottom: "3px" }}>{props.t("Wallet")}</span>
              </Link>

              <Link to="/logs" className="dropdown-item" style={{ position: "relative" }}>
                <i className="bx bx-brush-alt font-size-16 align-middle me-1 text-danger" />
                <span style={{ position: "absolute", bottom: "3px" }}>{props.t("Logs")}</span>
              </Link>
            </>
          )}

          

          {(role == "admin") && (
            <Link to="/admin/create-user" className="dropdown-item">
              <i className="bx bx-user-plus font-size-16 align-middle me-1 text-danger" />
              <span>{props.t("Create User")}</span>
            </Link>
          )}

          <div className="dropdown-divider" />

          {(role == "user" || role == "master") && (
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{props.t("Logout")}</span>
            </Link>
          )}

          {role == "admin" && (
            <Link to="/admin/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{props.t("Logout")}</span>
            </Link>
          )}

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile || {};
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
