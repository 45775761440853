import {
  GET_USERS,
  GET_USERS_SUCCESSFUL,
  GET_USERS_FAILED,
  USERS_EVENTS,
  USERS_EVENTS_SUCCESSFUL,
  USERS_EVENTS_FAILED,
  USERS_ROLE_CHANGE,
  USERS_ROLE_CHANGE_SUCCESSFUL,
  USERS_ROLE_CHANGE_FAILED,
} from "./actionTypes"

export const getUsers = (user, history) => {
  return {
    type: GET_USERS,
    payload: { user, history },
  }
}

export const getUsersSuccessful = user => {
  return {
    type: GET_USERS_SUCCESSFUL,
    payload: user,
  }
}

export const getUsersFailed = user => {
  return {
    type: GET_USERS_FAILED,
    payload: user,
  }
}

export const usersEvents = (user, history) => {
  return {
    type: USERS_EVENTS,
    payload: { user, history },
  }
}

export const usersEventsSuccessful = user => {
  return {
    type: USERS_EVENTS_SUCCESSFUL,
    payload: user,
  }
}

export const usersEventsFailed = user => {
  return {
    type: USERS_EVENTS_FAILED,
    payload: user,
  }
}

export const userRoleChange = (user, history) => {
  return {
    type: USERS_ROLE_CHANGE,
    payload: { user, history },
  }
}

export const userRoleChangeSuccessful = user => {
  return {
    type: USERS_ROLE_CHANGE_SUCCESSFUL,
    payload: user,
  }
}

export const userRoleChangeFailed = user => {
  return {
    type: USERS_ROLE_CHANGE_FAILED,
    payload: user,
  }
}