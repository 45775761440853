import {
  GET_PAYMENTS_SUCCESSFUL,
  GET_PAYMENTS_FAILED,
} from "./actionTypes"

const initialState = {
  loading: false,
  apiSuccess: null,
  apiError: null,
  paymentsData: null,
}

const AdminPayments = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENTS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        paymentsData: action.payload
      }
      break
    case GET_PAYMENTS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        paymentsData: null
      }
      break   
    default:
      state = { ...state }
      break
  }
  return state
}

export default AdminPayments
