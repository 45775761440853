import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Col, Container, Form, FormFeedback, Input, Label, Row, FormGroup, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, registerUserSuccessful, registerUserFailed } from "../../../store/actions";

//Verification code package
import AuthCode from "react-auth-code-input"


// import images
import logodark from "../../../assets/images/logo.png";
import logolight from "../../../assets/images/logo.png";
import CarouselPage from "./CarouselPage";

//redux
import { useSelector, useDispatch } from "react-redux";

const Register = props => {

  //meta title
  document.title = "Register | Algobridge ";

  const [ActivePanel, setActivePanel] = useState("register");

  const dispatch = useDispatch();

  const { apiData, apiSuccess, apiError } = useSelector(state => ({
    apiData: state.Register.apiData,
    apiSuccess: state.Register.apiSuccess,
    apiError: state.Register.apiError
  }));

  //form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      mobile: '',
      password: '',
      otp: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
      mobile: Yup.string().required("Please Enter Your Mobile"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      //console.log(values);
      //dispatch(registerUser(values, props.history));
      if (values.otp == "") {
        onSignInSubmit();
      }
      else {
        dispatch(registerUser(values, props.history));
      }
    }
  });


  useEffect(() => {
    dispatch(registerUserSuccessful(null));
    dispatch(registerUserFailed(null));
  }, []);

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => dispatch(registerUserSuccessful(null)), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => dispatch(registerUserFailed(null)), 1000)
    }
  }, [apiError]);

  function callSignin() {
    validation.handleSubmit();
  }

  function onSignInSubmit() {
    var mobile = validation.values.mobile;
    if (mobile != "") {
      console.log("mobile=" + mobile);

      phoneAuth(mobile);

      //setActivePanel("otp");

      // window.signingIn = true;
      // var phoneNumber = "+91" + mobile;
      // var appVerifier = window.recaptchaVerifier;
      // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      //   .then(function (confirmationResult) {
      //     // SMS sent. Prompt user to type the code from the message, then sign the
      //     // user in with confirmationResult.confirm(code).
      //     window.confirmationResult = confirmationResult;
      //     window.signingIn = false;

      //     console.log("OTP Sent Success!!!");

      //     setActivePanel("otp");

      //   }).catch(function (error) {
      //     // Error; SMS not sent
      //     // console.error('Error during signInWithPhoneNumber', error);
      //     // window.alert('Error during signInWithPhoneNumber:\n\n'
      //     //   + error.code + '\n\n' + error.message);
      //     window.signingIn = false;

      //     console.log("OTP Sent Failed!!!");
      //   });

    }
    else {
      console.log("OTP Sent Failed!!!");
    }
  }

  function onVerifyCodeSubmit() {
    var code = validation.values.otp;
    if (code.length == 6) {
      //console.log("ok and length is " + validation.values.otp.length);

      var mobile = validation.values.mobile;

      verifyOTP(mobile, code);

      // window.verifyingCode = true;
      // confirmationResult.confirm(code).then(function (result) {
      //   // User signed in successfully.
      //   var user = result.user;
      //   //console.log(JSON.stringify(user));
      //   window.verifyingCode = false;
      //   window.confirmationResult = null;
      //   auth.signOut();

      //   console.log("OTP Verified Success!!!");

      //   validation.handleSubmit();

      // }).catch(function (error) {
      //   window.verifyingCode = false;

      //   console.log("OTP Verified Failed!!!");
      // });

    }
    else {
      console.log("OTP Verified Failed!!!");
    }
  }

  function MobileValidate(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const handleOnChange = (result) => {
    validation.values.otp = result;
    //console.log(validation.values.otp);    
  };

  useEffect(() => {
    var element = document.getElementById("div-register");
    element.scrollIntoView();
    element.scrollIntoView(false);
    element.scrollIntoView({ block: "end" });
    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }, []);


  const callback = (otplessUser) => {
    const emailMap = otplessUser.identities.find(
      (item) => item.identityType === "EMAIL"
    );

    const mobileMap = otplessUser.identities.find(
      (item) => item.identityType === "MOBILE"
    )?.identityValue;

    const token = otplessUser.token;

    const email = emailMap?.identityValue;

    const mobile = mobileMap?.identityValue;

    const name = emailMap?.name || mobileMap?.name;

    console.log(otplessUser);

    // Implement your custom logic here.
  };
  // Initialize OTPLESS SDK with the defined callback.
  const OTPlessSignin = new OTPless(callback);

  const phoneAuth = async (mobile) => {
    var result = await OTPlessSignin.initiate({
      channel: "PHONE",
      phone: mobile,
      countryCode: "+91",
    });
    //console.log(result);
    if (result.success == true) {
      console.log("OTP Sent Success!!!");
      setActivePanel("otp");
    }
    else {
      console.log("OTP Sent Failed!!!");
    }
  };

  const verifyOTP = async (mobile, otp) => {
    var result = await OTPlessSignin.verify({
      channel: "PHONE",
      phone: mobile,
      otp: otp,
      countryCode: "+91",
    });
    //console.log(result);
    if (result.success == true) {
      console.log("OTP Verified Success!!!");
      validation.handleSubmit();
    }
    else {
      console.log("OTP Verified Failed!!!");
    }
  };


  return (

    <React.Fragment>
      <div>
        <Row className="g-0">
          <CarouselPage />

          <Col xl={3} id="div-register">
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5">
                    <Link to="dashboard" className="d-block auth-logo">
                      <img
                        src={logodark}
                        alt=""
                        height="36"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logolight}
                        alt=""
                        height="36"
                        className="auth-logo-light"
                      />
                    </Link>
                  </div>

                  {apiSuccess && (
                    <div
                      className="alert alert-success text-center mb-4"
                      role="alert">
                      {apiSuccess}
                    </div>
                  )}

                  {apiError && (
                    <div
                      className="alert alert-danger text-center mb-4"
                      role="alert">
                      {apiError}
                    </div>
                  )}

                  <div id="register-div" name="register-div"></div>

                  {ActivePanel == "register" && (
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Register account</h5>
                        <p className="text-muted">
                          Get your free Algobridge account now.
                        </p>
                      </div>

                      <div className="mt-4">

                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            callSignin();
                            return false;
                          }}
                        >

                          <div className="form-floating mb-3">
                            <Input
                              id="name"
                              name="name"
                              className="form-control"
                              placeholder="Enter name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name && validation.errors.name ? true : false
                              }
                            />
                            <Label className="form-label">Name</Label>
                            {validation.touched.name && validation.errors.name ? (
                              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-floating mb-3">
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            <Label className="form-label">Email</Label>
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-floating mb-3">
                            <Input
                              id="mobile"
                              name="mobile"
                              className="form-control"
                              placeholder="Enter mobile"
                              type="text"
                              onKeyPress={(event) => MobileValidate(event)}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mobile || ""}
                              invalid={
                                validation.touched.mobile && validation.errors.mobile ? true : false
                              }
                            />
                            <Label className="form-label">Mobile</Label>
                            {validation.touched.mobile && validation.errors.mobile ? (
                              <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="form-floating mb-3">
                            <Input
                              id="password"
                              name="password"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            <Label className="form-label">Password</Label>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>



                          <div>
                            <p className="mb-0">
                              By registering you agree to the Algobridge{" "}
                              <a href="#" className="text-primary">
                                Terms of Use
                              </a>
                            </p>
                          </div>

                          <div className="mt-4">
                            <button
                              id="sign-in-button"
                              name="sign-in-button"
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Register
                            </button>
                          </div>

                        </Form>

                        <div className="mt-5 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="font-weight-medium text-primary"
                            >
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {ActivePanel == "otp" && (
                    <div className="my-auto">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verify Your Mobile</h4>
                          <p>
                            Please enter the 6 digit code sent to{" "}
                            <span className="font-weight-semibold">
                              {validation.values.mobile}
                            </span>
                          </p>

                          <Form>
                            <Row>
                              <Col xs={12}>
                                <FormGroup className="verification-2 mb-3">
                                  <Label
                                    htmlFor="digit1-input"
                                    className="visually-hidden"
                                  >
                                    OTP
                                  </Label>
                                  <AuthCode
                                    characters={6}
                                    className="form-control form-control-lg text-center"
                                    inputStyle={{
                                      width: "50px",
                                      height: "calc(1.5em + 1rem + 2px)",
                                      padding: ".5rem 1rem",
                                      borderRadius: "8px",
                                      fontSize: "1.01562rem",
                                      textAlign: "center",
                                      marginTop: "5px",
                                      marginRight: "15px",
                                      border: "1px solid #ced4da",
                                      textTransform: "uppercase",
                                      borderRadius: ".4rem"
                                    }}
                                    onChange={handleOnChange}
                                    value={validation.values.otp || ""}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>

                          <div className="mt-4">
                            <button type="button" className="btn btn-success w-md" onClick={() => {
                              onVerifyCodeSubmit();
                            }}>
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      ©{" "}
                      {new Date().getFullYear()}
                      Algobridge. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger"></i> by
                      Seventicks Software
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>

        </Row>
      </div>
    </React.Fragment>
  );
};

export default Register;
