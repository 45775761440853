import {
  GET_SYMBOLS_SUCCESSFUL,
  GET_SYMBOLS_FAILED,
  GET_ORDERS_SUCCESSFUL,
  GET_ORDERS_FAILED,
  GET_ORDERBOOK_SUCCESSFUL,
  GET_ORDERBOOK_FAILED,
  GET_TRADEBOOK_SUCCESSFUL,
  GET_TRADEBOOK_FAILED,
  GET_POSITIONS_SUCCESSFUL,
  GET_POSITIONS_FAILED,
  PLACE_ORDERS_SUCCESSFUL,
  PLACE_ORDERS_FAILED,
  ADD_MARKETWATCH_SUCCESSFUL,
  ADD_MARKETWATCH_FAILED,
  DELETE_MARKETWATCH_SUCCESSFUL,
  DELETE_MARKETWATCH_FAILED,
  GET_MARKETWATCH_SUCCESSFUL,
  GET_MARKETWATCH_FAILED,
  RESET_APIEVENT,
  SQUAREOFF_POSITIONS_SUCCESSFUL,
  SQUAREOFF_POSITIONS_FAILED
} from "./actionTypes"

const initialState = {
  loading: false,
  apiSuccess: null,
  apiError: null,
  symbolsData: null,
  ordersData: null,
  orderbookData: null,
  tradebookData: null,
  positionsData: null,
  marketwatchData: null,
  apiEvent: null
}

const Trading = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APIEVENT:
      state = {
        ...state,
        loading: false,
        apiEvent: null,
      }
      break
    case PLACE_ORDERS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case PLACE_ORDERS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case SQUAREOFF_POSITIONS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
        apiEvent: "RELOAD",
      }
      break
    case SQUAREOFF_POSITIONS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case ADD_MARKETWATCH_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
        apiEvent: "RELOAD",
      }
      break
    case ADD_MARKETWATCH_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case DELETE_MARKETWATCH_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
        apiEvent: "RELOAD",
      }
      break
    case DELETE_MARKETWATCH_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case GET_MARKETWATCH_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        marketwatchData: action.payload
      }
      break
    case GET_MARKETWATCH_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        marketwatchData: null
      }
      break
    case GET_SYMBOLS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        symbolsData: action.payload
      }
      break
    case GET_SYMBOLS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        symbolsData: null
      }
      break
    case GET_ORDERS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        ordersData: action.payload
      }
      break
    case GET_ORDERS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        ordersData: null
      }
      break
    case GET_ORDERBOOK_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        orderbookData: action.payload
      }
      break
    case GET_ORDERBOOK_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        orderbookData: null
      }
      break
    case GET_TRADEBOOK_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        tradebookData: action.payload
      }
      break
    case GET_TRADEBOOK_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        tradebookData: null
      }
      break
    case GET_POSITIONS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        positionsData: action.payload
      }
      break
    case GET_POSITIONS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        positionsData: null
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Trading
