export const GET_MASTERS = "GET_MASTERS"
export const GET_MASTERS_SUCCESSFUL = "GET_MASTERS_SUCCESSFUL"
export const GET_MASTERS_FAILED = "GET_MASTERS_FAILED"

export const MASTERS_EVENTS = "MASTERS_EVENTS"
export const MASTERS_EVENTS_SUCCESSFUL = "MASTERS_EVENTS_SUCCESSFUL"
export const MASTERS_EVENTS_FAILED = "MASTERS_EVENTS_FAILED"

export const MASTER_AMOUNT_CHANGE = "MASTER_AMOUNT_CHANGE"
export const MASTER_AMOUNT_CHANGE_SUCCESSFUL = "MASTER_AMOUNT_CHANGE_SUCCESSFUL"
export const MASTER_AMOUNT_CHANGE_FAILED = "MASTER_AMOUNT_CHANGE_FAILED"