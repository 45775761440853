import React, { useEffect, useState, useRef } from "react";

import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Tooltip,
    CardBody,
    CardFooter,
    CardTitle,
    Modal,
} from "reactstrap";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

import common_data_init_state from "../model/common"

function Common(props) {

    const [common_data, set_common_data] = React.useState(common_data_init_state);

    const { strategyID, strategy_name, strategy, strategy_type, day, month_name, month_value, year, option_type, option_gap, symbol, symbol_code, exchange, exchange_code, trade_type, order_type, timeframe,
        stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
        squareoff_time
    } = common_data;

    const changeHandler = (e) => {

        //debugger;
        
        //common
        if (e.target.name == "strategy") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "symbol") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "trade_type") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "timeframe") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "stoploss_on") {
            set_common_data({ ...common_data, [e.target.name]: ((stoploss_on == "true") ? "false" : "true") });
        }
        else if (e.target.name == "stoploss_type") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "trailing_step") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "stoploss") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "target_on") {
            set_common_data({ ...common_data, [e.target.name]: ((target_on == "true") ? "false" : "true") });
        }
        else if (e.target.name == "target") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "qty") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "reentry_on") {
            set_common_data({ ...common_data, [e.target.name]: ((reentry_on == "true") ? "false" : "true") });
        }
        else if (e.target.name == "squareoff_time") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "day") {
            set_common_data({ ...common_data, [e.target.name]: (e.target.value) });
        }
        else if (e.target.name == "month_value") {
            var month_name_temp = e.target.selectedOptions[0].getAttribute('data-month-name');
            var month_value_temp = e.target.value;
            set_common_data({ ...common_data, month_name: month_name_temp, [e.target.name]: parseInt(month_value_temp) });
        }
        else if (e.target.name == "year") {
            set_common_data({ ...common_data, [e.target.name]: (e.target.value) });
        }
        else if (e.target.name == "option_type") {
            set_common_data({ ...common_data, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "option_gap") {
            set_common_data({ ...common_data, [e.target.name]: parseInt(e.target.value) });
        }

    };

    useEffect(() => {
        props.updateCommonData(common_data);
    }, [common_data]);

    useEffect(() => {
        if (props.edit_data) {
            set_common_data(props.edit_data);
            setSearchTerm(props.edit_data.symbol);
        }
        else {
            set_common_data(common_data_init_state);
            setSearchTerm("");
        }
    }, [props.edit_data]);

    const [searchTerm, setSearchTerm] = useState(""); // holds the search term
    const [filteredData, setFilteredData] = useState([]); // holds the filtered data

    const [showSearchDiv, SetshowSearchDiv] = useState(false)

    function loadSymbolSearch(search) {
        var authUser = localStorage.getItem("authUser");
        if (authUser) { authUser = atob(authUser); }
        var email = JSON.parse(authUser).email;

        var obj = {
            "email": email,
            "symbol": search
        };

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/trading/getSymbols', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                setFilteredData(output.data || []);
                if (output.data) {
                    SetshowSearchDiv(true);
                }
                else {
                    SetshowSearchDiv(false);
                }
            }
            else {
                SetapiError(output.message);
            }
        });
    }

    // filter data based on search term
    useEffect(() => {
        if (searchTerm && searchTerm != "") {
            if (searchTerm.length > 2) {
                if (searchTerm != common_data.symbol) {
                    loadSymbolSearch(searchTerm);
                }
            }
        }
    }, [searchTerm]);

    function handleSymbolDropdown(symbolObj) {

        SetshowSearchDiv(false);

        setSearchTerm(symbolObj.symbol);

        common_data.symbol = symbolObj.symbol;
        common_data.symbol_code = symbolObj.code.toString();
        common_data.exchange = symbolObj.exchange;
        common_data.exchange_code = getExchangeCode(symbolObj.exchange).toString();

    }

    function getExchangeCode(exchange) {
        var result = 0
        if (exchange == "NSE") {
            result = 1;
        }
        else if (exchange == "NFO") {
            result = 2;
        }
        else if (exchange == "CDS") {
            result = 3;
        }
        else if (exchange == "MCX") {
            result = 4;
        }
        else if (exchange == "BSE") {
            result = 6;
        }
        else if (exchange == "BFO") {
            result = 7;
        }
        return result;
    }

    return (
        <React.Fragment>

            <>

                <fieldset className="border border-light border-2 rounded-3 p-2 mb-2">
                    <legend className="float-none w-auto px-3 fs-6 fw-bold">
                        Default Properties
                    </legend>

                    <Row>

                        {(props.strategy != "OPTION_WRITING") &&

                            (props.strategy_type == "NORMAL") ?
                            (
                                <Col className="mb-2" md={12}>
                                    <div>
                                        <input
                                            disabled={strategyID != "" && true}
                                            className="form-control"
                                            type="text"
                                            id="symbol"
                                            name="symbol"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Search Symbol..."
                                        />
                                        {showSearchDiv &&
                                            <div className="list-group" style={{ "maxHeight": "150px", "overflowY": "scroll" }}>
                                                {filteredData.map((item) => (
                                                    <button key={item._id} onClick={() => { handleSymbolDropdown(item) }} type="button" className="list-group-item list-group-item-action">{item.symbol}</button>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </Col>
                            )
                            :
                            (
                                <>
                                    <Col className="mb-2" md={6}>
                                        <div className="form-floating mb-3">
                                            <select value={symbol} disabled={strategyID != "" && true} name="symbol" className="form-select" onChange={changeHandler}>
                                                <option value="">SELECT</option>
                                                <option value="BANKNIFTY">BANKNIFTY</option>
                                                <option value="NIFTY">NIFTY</option>
                                                <option value="FINNIFTY">FINNIFTY</option>
                                                <option value="ABBOTINDIA">ABBOTINDIA</option>
                                                <option value="ABCAPITAL">ABCAPITAL</option>
                                                <option value="ACC">ACC</option>
                                                <option value="ADANIPORTS">ADANIPORTS</option>
                                                <option value="ALKEM">ALKEM</option>
                                                <option value="AMARAJABAT">AMARAJABAT</option>
                                                <option value="AMBUJACEM">AMBUJACEM</option>
                                                <option value="APOLLOTYRE">APOLLOTYRE</option>
                                                <option value="ATUL">ATUL</option>
                                                <option value="AXISBANK">AXISBANK</option>
                                                <option value="BAJAJ-AUTO">BAJAJ-AUTO</option>
                                                <option value="AARTIIND">AARTIIND</option>
                                                <option value="BALRAMCHIN">BALRAMCHIN</option>
                                                <option value="BANKBARODA">BANKBARODA</option>
                                                <option value="BEL">BEL</option>
                                                <option value="BERGEPAINT">BERGEPAINT</option>
                                                <option value="BHARATFORG">BHARATFORG</option>
                                                <option value="BHARTIARTL">BHARTIARTL</option>
                                                <option value="BHEL">BHEL</option>
                                                <option value="BIOCON">BIOCON</option>
                                                <option value="BPCL">BPCL</option>
                                                <option value="BRITANNIA">BRITANNIA</option>
                                                <option value="BSOFT">BSOFT</option>
                                                <option value="CANBK">CANBK</option>
                                                <option value="CANFINHOME">CANFINHOME</option>
                                                <option value="COALINDIA">COALINDIA</option>
                                                <option value="COFORGE">COFORGE</option>
                                                <option value="CONCOR">CONCOR</option>
                                                <option value="CROMPTON">CROMPTON</option>
                                                <option value="CUB">CUB</option>
                                                <option value="DABUR">DABUR</option>
                                                <option value="DALBHARAT">DALBHARAT</option>
                                                <option value="DEEPAKNTR">DEEPAKNTR</option>
                                                <option value="DELTACORP">DELTACORP</option>
                                                <option value="DIVISLAB">DIVISLAB</option>
                                                <option value="DLF">DLF</option>
                                                <option value="DRREDDY">DRREDDY</option>
                                                <option value="ESCORTS">ESCORTS</option>
                                                <option value="FEDERALBNK">FEDERALBNK</option>
                                                <option value="GAIL">GAIL</option>
                                                <option value="GMRINFRA">GMRINFRA</option>
                                                <option value="GNFC">GNFC</option>
                                                <option value="GODREJCP">GODREJCP</option>
                                                <option value="GRANULES">GRANULES</option>
                                                <option value="GRASIM">GRASIM</option>
                                                <option value="GSPL">GSPL</option>
                                                <option value="GUJGASLTD">GUJGASLTD</option>
                                                <option value="HAL">HAL</option>
                                                <option value="HCLTECH">HCLTECH</option>
                                                <option value="HDFCAMC">HDFCAMC</option>
                                                <option value="ASHOKLEY">ASHOKLEY</option>
                                                <option value="HDFCLIFE">HDFCLIFE</option>
                                                <option value="HEROMOTOCO">HEROMOTOCO</option>
                                                <option value="HINDALCO">HINDALCO</option>
                                                <option value="HINDCOPPER">HINDCOPPER</option>
                                                <option value="HINDPETRO">HINDPETRO</option>
                                                <option value="HONAUT">HONAUT</option>
                                                <option value="ICICIBANK">ICICIBANK</option>
                                                <option value="ICICIGI">ICICIGI</option>
                                                <option value="ICICIPRULI">ICICIPRULI</option>
                                                <option value="IDFC">IDFC</option>
                                                <option value="IGL">IGL</option>
                                                <option value="INDHOTEL">INDHOTEL</option>
                                                <option value="INDIACEM">INDIACEM</option>
                                                <option value="INFY">INFY</option>
                                                <option value="INTELLECT">INTELLECT</option>
                                                <option value="IRCTC">IRCTC</option>
                                                <option value="JINDALSTEL">JINDALSTEL</option>
                                                <option value="JUBLFOOD">JUBLFOOD</option>
                                                <option value="KOTAKBANK">KOTAKBANK</option>
                                                <option value="LALPATHLAB">LALPATHLAB</option>
                                                <option value="LAURUSLABS">LAURUSLABS</option>
                                                <option value="LICHSGFIN">LICHSGFIN</option>
                                                <option value="LTI">LTI</option>
                                                <option value="LUPIN">LUPIN</option>
                                                <option value="MARICO">MARICO</option>
                                                <option value="MARUTI">MARUTI</option>
                                                <option value="METROPOLIS">METROPOLIS</option>
                                                <option value="MFSL">MFSL</option>
                                                <option value="MGL">MGL</option>
                                                <option value="MPHASIS">MPHASIS</option>
                                                <option value="MUTHOOTFIN">MUTHOOTFIN</option>
                                                <option value="NAM-INDIA">NAM-INDIA</option>
                                                <option value="NATIONALUM">NATIONALUM</option>
                                                <option value="NAVINFLUOR">NAVINFLUOR</option>
                                                <option value="NESTLEIND">NESTLEIND</option>
                                                <option value="NMDC">NMDC</option>
                                                <option value="NTPC">NTPC</option>
                                                <option value="ONGC">ONGC</option>
                                                <option value="PAGEIND">PAGEIND</option>
                                                <option value="PETRONET">PETRONET</option>
                                                <option value="PFC">PFC</option>
                                                <option value="PIDILITIND">PIDILITIND</option>
                                                <option value="PIIND">PIIND</option>
                                                <option value="POWERGRID">POWERGRID</option>
                                                <option value="RAIN">RAIN</option>
                                                <option value="RELIANCE">RELIANCE</option>
                                                <option value="SAIL">SAIL</option>
                                                <option value="SBICARD">SBICARD</option>
                                                <option value="SBIN">SBIN</option>
                                                <option value="SHREECEM">SHREECEM</option>
                                                <option value="SUNPHARMA">SUNPHARMA</option>
                                                <option value="SUNTV">SUNTV</option>
                                                <option value="SYNGENE">SYNGENE</option>
                                                <option value="TATACHEM">TATACHEM</option>
                                                <option value="TATACOMM">TATACOMM</option>
                                                <option value="TATACONSUM">TATACONSUM</option>
                                                <option value="TATAPOWER">TATAPOWER</option>
                                                <option value="TATASTEEL">TATASTEEL</option>
                                                <option value="TECHM">TECHM</option>
                                                <option value="TORNTPOWER">TORNTPOWER</option>
                                                <option value="TVSMOTOR">TVSMOTOR</option>
                                                <option value="VOLTAS">VOLTAS</option>
                                                <option value="WIPRO">WIPRO</option>
                                                <option value="ZEEL">ZEEL</option>
                                                <option value="ZYDUSLIFE">ZYDUSLIFE</option>
                                                <option value="IPCALAB">IPCALAB</option>
                                                <option value="PEL">PEL</option>
                                                <option value="TORNTPHARM">TORNTPHARM</option>
                                                <option value="ABFRL">ABFRL</option>
                                                <option value="APOLLOHOSP">APOLLOHOSP</option>
                                                <option value="ASIANPAINT">ASIANPAINT</option>
                                                <option value="ASTRAL">ASTRAL</option>
                                                <option value="AUBANK">AUBANK</option>
                                                <option value="AUROPHARMA">AUROPHARMA</option>
                                                <option value="BAJFINANCE">BAJFINANCE</option>
                                                <option value="BALKRISIND">BALKRISIND</option>
                                                <option value="BATAINDIA">BATAINDIA</option>
                                                <option value="COLPAL">COLPAL</option>
                                                <option value="DIXON">DIXON</option>
                                                <option value="EICHERMOT">EICHERMOT</option>
                                                <option value="FSL">FSL</option>
                                                <option value="GLENMARK">GLENMARK</option>
                                                <option value="GODREJPROP">GODREJPROP</option>
                                                <option value="HAVELLS">HAVELLS</option>
                                                <option value="HDFCBANK">HDFCBANK</option>
                                                <option value="IDFCFIRSTB">IDFCFIRSTB</option>
                                                <option value="IEX">IEX</option>
                                                <option value="INDUSINDBK">INDUSINDBK</option>
                                                <option value="ITC">ITC</option>
                                                <option value="JKCEMENT">JKCEMENT</option>
                                                <option value="L&TFH">L&TFH</option>
                                                <option value="LTTS">LTTS</option>
                                                <option value="MCDOWELL-N">MCDOWELL-N</option>
                                                <option value="MINDTREE">MINDTREE</option>
                                                <option value="MOTHERSON">MOTHERSON</option>
                                                <option value="CIPLA">CIPLA</option>
                                                <option value="NAUKRI">NAUKRI</option>
                                                <option value="NBCC">NBCC</option>
                                                <option value="OBEROIRLTY">OBEROIRLTY</option>
                                                <option value="PERSISTENT">PERSISTENT</option>
                                                <option value="POLYCAB">POLYCAB</option>
                                                <option value="RBLBANK">RBLBANK</option>
                                                <option value="SBILIFE">SBILIFE</option>
                                                <option value="SIEMENS">SIEMENS</option>
                                                <option value="TATAMOTORS">TATAMOTORS</option>
                                                <option value="TITAN">TITAN</option>
                                                <option value="UBL">UBL</option>
                                                <option value="ULTRACEMCO">ULTRACEMCO</option>
                                                <option value="UPL">UPL</option>
                                                <option value="WHIRLPOOL">WHIRLPOOL</option>
                                                <option value="M&M">M&M</option>
                                                <option value="MRF">MRF</option>
                                                <option value="OFSS">OFSS</option>
                                                <option value="PVR">PVR</option>
                                                <option value="BAJAJFINSV">BAJAJFINSV</option>
                                                <option value="BANDHANBNK">BANDHANBNK</option>
                                                <option value="ADANIENT">ADANIENT</option>
                                                <option value="BOSCHLTD">BOSCHLTD</option>
                                                <option value="CHAMBLFERT">CHAMBLFERT</option>
                                                <option value="CHOLAFIN">CHOLAFIN</option>
                                                <option value="CUMMINSIND">CUMMINSIND</option>
                                                <option value="HINDUNILVR">HINDUNILVR</option>
                                                <option value="INDIAMART">INDIAMART</option>
                                                <option value="INDIGO">INDIGO</option>
                                                <option value="JSWSTEEL">JSWSTEEL</option>
                                                <option value="M&MFIN">M&MFIN</option>
                                                <option value="MANAPPURAM">MANAPPURAM</option>
                                                <option value="INDUSTOWER">INDUSTOWER</option>
                                                <option value="ABB">ABB</option>
                                                <option value="PNB">PNB</option>
                                                <option value="RAMCOCEM">RAMCOCEM</option>
                                                <option value="RECLTD">RECLTD</option>
                                                <option value="SRF">SRF</option>
                                                <option value="TCS">TCS</option>
                                                <option value="EXIDEIND">EXIDEIND</option>
                                                <option value="TRENT">TRENT</option>
                                                <option value="VEDL">VEDL</option>
                                                <option value="COROMANDEL">COROMANDEL</option>
                                                <option value="IDEA">IDEA</option>
                                                <option value="GOLD">GOLD</option>
                                                <option value="GOLDM">GOLDM</option>
                                                <option value="GOLDGUINEA">GOLDGUINEA</option>
                                                <option value="SILVER">SILVER</option>
                                                <option value="SILVERM">SILVERM</option>
                                                <option value="SILVERMIC">SILVERMIC</option>
                                                <option value="COPPER">COPPER</option>
                                                <option value="CRUDEOIL">CRUDEOIL</option>
                                                <option value="NATURALGAS">NATURALGAS</option>
                                                <option value="NICKEL">NICKEL</option>
                                                <option value="ZINC">ZINC</option>
                                                <option value="ALUMINUM">ALUMINUM</option>
                                                <option value="LEAD">LEAD</option>
                                            </select>
                                            <label htmlFor="floatingSelectGrid">Symbol</label>
                                        </div>
                                    </Col>
                                    <Col className="mb-2" md={6}>
                                        <div className="form-floating mb-3">
                                            <select value={day} disabled={strategyID != "" && true} name="day" className="form-select" onChange={changeHandler}>
                                                <option value="0">SELECT</option>
                                                <option value="01">
                                                    01
                                                </option>
                                                <option value="02">
                                                    02
                                                </option>
                                                <option value="03">
                                                    03
                                                </option>
                                                <option value="04">
                                                    04
                                                </option>
                                                <option value="05">
                                                    05
                                                </option>
                                                <option value="06">
                                                    06
                                                </option>
                                                <option value="07">
                                                    07
                                                </option>
                                                <option value="08">
                                                    08
                                                </option>
                                                <option value="09">
                                                    09
                                                </option>
                                                <option value="10">
                                                    10
                                                </option>
                                                <option value="11">
                                                    11
                                                </option>
                                                <option value="12">
                                                    12
                                                </option>
                                                <option value="13">
                                                    13
                                                </option>
                                                <option value="14">
                                                    14
                                                </option>
                                                <option value="15">
                                                    15
                                                </option>
                                                <option value="16">
                                                    16
                                                </option>
                                                <option value="17">
                                                    17
                                                </option>
                                                <option value="18">
                                                    18
                                                </option>
                                                <option value="19">
                                                    19
                                                </option>
                                                <option value="20">
                                                    20
                                                </option>
                                                <option value="21">
                                                    21
                                                </option>
                                                <option value="22">
                                                    22
                                                </option>
                                                <option value="23">
                                                    23
                                                </option>
                                                <option value="24">
                                                    24
                                                </option>
                                                <option value="25">
                                                    25
                                                </option>
                                                <option value="26">
                                                    26
                                                </option>
                                                <option value="27">
                                                    27
                                                </option>
                                                <option value="28">
                                                    28
                                                </option>
                                                <option value="29">
                                                    29
                                                </option>
                                                <option value="30">
                                                    30
                                                </option>
                                                <option value="31">
                                                    31
                                                </option>
                                            </select>
                                            <label htmlFor="floatingSelectGrid">Date</label>
                                        </div>
                                    </Col>
                                    <Col className="mb-2" md={3}>
                                        <div className="form-floating mb-3">
                                            <select value={month_value} disabled={strategyID != "" && true} name="month_value" className="form-select" onChange={changeHandler}>
                                                <option value="">SELECT</option>
                                                <option data-month-value="1" data-month-name="JAN" value="1">
                                                    JAN
                                                </option>
                                                <option data-month-value="2" data-month-name="FEB" value="2">
                                                    FEB
                                                </option>
                                                <option data-month-value="3" data-month-name="MAR" value="3">
                                                    MAR
                                                </option>
                                                <option data-month-value="4" data-month-name="APR" value="4">
                                                    APR
                                                </option>
                                                <option data-month-value="5" data-month-name="MAY" value="5">
                                                    MAY
                                                </option>
                                                <option data-month-value="6" data-month-name="JUN" value="6">
                                                    JUN
                                                </option>
                                                <option data-month-value="7" data-month-name="JUL" value="7">
                                                    JUL
                                                </option>
                                                <option data-month-value="8" data-month-name="AUG" value="8">
                                                    AUG
                                                </option>
                                                <option data-month-value="9" data-month-name="SEP" value="9">
                                                    SEP
                                                </option>
                                                <option data-month-value="10" data-month-name="OCT" value="10">
                                                    OCT
                                                </option>
                                                <option data-month-value="11" data-month-name="NOV" value="11">
                                                    NOV
                                                </option>
                                                <option data-month-value="12" data-month-name="DEC" value="12">
                                                    DEC
                                                </option>
                                            </select>
                                            <label htmlFor="floatingSelectGrid">Month</label>
                                        </div>
                                    </Col>
                                    <Col className="mb-2" md={3}>
                                        <div className="form-floating mb-3">
                                            <select value={year} disabled={strategyID != "" && true} name="year" className="form-select" onChange={changeHandler}>
                                                <option value="">SELECT</option>
                                                <option value="23">
                                                    23
                                                </option>
                                                <option value="24">
                                                    24
                                                </option>
                                                <option value="25">
                                                    25
                                                </option>
                                                <option value="26">
                                                    26
                                                </option>
                                                <option value="27">
                                                    27
                                                </option>
                                                <option value="28">
                                                    28
                                                </option>
                                                <option value="29">
                                                    29
                                                </option>
                                                <option value="30">
                                                    30
                                                </option>
                                                <option value="31">
                                                    31
                                                </option>
                                                <option value="32">
                                                    32
                                                </option>
                                            </select>
                                            <label htmlFor="floatingSelectGrid">Year</label>
                                        </div>
                                    </Col>
                                    <Col className="mb-2" md={4}>
                                        <div className="form-floating mb-3">
                                            <select value={option_type} name="option_type" className="form-select" onChange={changeHandler}>
                                                <option value="ATM">ATM</option>
                                                <option value="OTM">OTM</option>
                                                <option value="ITM">ITM</option>
                                            </select>
                                            <label htmlFor="floatingSelectGrid">Option Type</label>
                                        </div>
                                    </Col>

                                    {option_type && option_type != "ATM" &&
                                        <Col className="mb-2" md={4}>
                                            <div className="form-floating mb-3">
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="option_gap"
                                                    value={option_gap}
                                                    onChange={changeHandler}
                                                    required={true}
                                                />
                                                <label htmlFor="floatingSelectGrid">Option Gap</label>
                                            </div>
                                        </Col>
                                    }

                                </>

                            )
                        }

                        {(props.strategy != "OPTION_WRITING") &&
                            <Col className="mb-2" md={6}>
                                <div className="form-floating mb-3">
                                    <select value={timeframe} name="timeframe" required={true} className="form-select" onChange={changeHandler}>
                                        <option value="">SELECT</option>
                                        <option value="M1">M1</option>
                                        <option value="M3">M3</option>
                                        <option value="M5">M5</option>
                                        <option value="M10">M10</option>
                                        <option value="M15">M15</option>
                                        <option value="M30">M30</option>
                                        <option value="H1">H1</option>
                                    </select>
                                    <label htmlFor="floatingSelectGrid">Timeframe</label>
                                </div>
                            </Col>
                        }

                        {(props.strategy != "RSI_STRATEGY" && props.strategy != "OPTION_WRITING") &&
                            <Col className="mb-2" md={6}>
                                <div className="form-floating mb-3">
                                    <select value={trade_type} name="trade_type" required={true} className="form-select" onChange={changeHandler}>
                                        <option value="">SELECT</option>
                                        <option value="REAL_TIME">REAL TIME</option>
                                        <option value="CANDLE_CLOSE">CANDLE CLOSE</option>
                                    </select>
                                    <label htmlFor="floatingSelectGrid">Trade Type</label>
                                </div>
                            </Col>
                        }

                        <Col className="mb-2" md={6}>
                            <div className="form-floating mb-3">
                                <input
                                    className="form-control"
                                    type="number"
                                    name="qty"
                                    value={qty}
                                    onChange={changeHandler}
                                    required={true} />
                                <label htmlFor="floatingSelectGrid">Qty</label>
                            </div>
                        </Col>

                        {(props.strategy != "OPTION_WRITING") &&

                            <Col className="mb-2" md={6}>
                                <div className="form-check form-switch mt-3 mb-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizelg"
                                        onClick={changeHandler}
                                        checked={stoploss_on == "true"}
                                        name="stoploss_on" />
                                    <label
                                        className="form-check-label"
                                        htmlFor="customSwitchsizelg"
                                    >
                                        STOPLOSS ON/OFF
                                    </label>
                                </div>
                            </Col>
                        }

                        {stoploss_on == "true" && (
                            <>
                                <Col className="mb-2" md={6}>
                                    <div className="form-floating mb-3">
                                        <select value={stoploss_type} name="stoploss_type" required={true} className="form-select" onChange={changeHandler}>
                                            <option value="">SELECT</option>
                                            <option value="NORMAL">NORMAL</option>
                                            <option value="TRAILING">TRAILING</option>
                                            <option value="BREAKEVEN">BREAKEVEN</option>
                                        </select>
                                        <label htmlFor="floatingSelectGrid">Stoploss Type</label>
                                    </div>
                                </Col>
                                <Col className="mb-2" md={6}>
                                    <div className="form-floating mb-3">
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="stoploss"
                                            value={stoploss}
                                            onChange={changeHandler}
                                            required={true} />
                                        <label htmlFor="floatingSelectGrid">Stoploss</label>
                                    </div>
                                </Col>
                            </>
                        )}

                        {stoploss_on == "true" && stoploss_type == "TRAILING" && (
                            <Col className="mb-2" md={6}>
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="trailing_step"
                                        value={trailing_step}
                                        onChange={changeHandler}
                                        required={true} />
                                    <label htmlFor="floatingSelectGrid">Trailing Step</label>
                                </div>
                            </Col>
                        )}


                        {(props.strategy != "OPTION_WRITING") &&
                            <Col className="mb-2" md={6}>
                                <div className="form-check form-switch mt-3 mb-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizelg"
                                        onClick={changeHandler}
                                        checked={target_on == "true"}
                                        name="target_on"
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="customSwitchsizelg"
                                    >
                                        TARGET ON/OFF
                                    </label>
                                </div>
                            </Col>
                        }

                        {target_on == "true" &&
                            <Col className="mb-2" md={6}>
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="target"
                                        value={target}
                                        onChange={changeHandler}
                                        required={true}
                                    />
                                    <label htmlFor="floatingSelectGrid">Target</label>
                                </div>
                            </Col>
                        }

                        <Col className="mb-2" md={6}>
                            <div
                                className="form-check form-switch mt-3 mb-3"
                            >
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="reentry_on"
                                    name="reentry_on"
                                    checked={reentry_on == "true"}
                                    onClick={changeHandler}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="customSwitchsizelg"
                                >
                                    RE ENTRY ON/OFF
                                </label>
                            </div>
                        </Col>

                        <Col className="mb-2" md={6}>
                            <div className="form-floating mb-3">
                                <input
                                    className="form-control"
                                    type="time"
                                    id="example-time-input"
                                    value={squareoff_time}
                                    name="squareoff_time"
                                    onChange={changeHandler}
                                    required={true} />
                                <label htmlFor="floatingSelectGrid">Squareoff Time</label>
                            </div>
                        </Col>

                    </Row>

                </fieldset>

            </>

        </React.Fragment>
    );
}

export default Common;
