import { takeEvery, fork, put, all, call } from "redux-saga/effects"


import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccessful, userForgetPasswordFailed } from "./actions"

import { forgotPwdCall } from "../../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgotPwd({ payload: { user, history } }) {
  try {

    var input = (user == undefined) ? {} : user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(forgotPwdCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(userForgetPasswordSuccessful(output.message))
    }
    else {
      yield put(userForgetPasswordFailed(output.message))
    }
  } catch (error) {
    yield put(userForgetPasswordFailed(error.message));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgotPwd)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
