import {
  GET_USERS_SUCCESSFUL,
  GET_USERS_FAILED,
  USERS_EVENTS_SUCCESSFUL,
  USERS_EVENTS_FAILED,
  USERS_ROLE_CHANGE_SUCCESSFUL,
  USERS_ROLE_CHANGE_FAILED,
} from "./actionTypes"

const initialState = {
  loading: false,
  apiSuccess: null,
  apiError: null,
  usersData: null,
}

const AdminUsers = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        usersData: action.payload
      }
      break
    case GET_USERS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        usersData: null
      }
      break
    case USERS_ROLE_CHANGE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case USERS_ROLE_CHANGE_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case USERS_EVENTS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case USERS_EVENTS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default AdminUsers
