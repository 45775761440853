import {
  ADD_BROKER,
  ADD_BROKER_SUCCESSFUL,
  ADD_BROKER_FAILED,
  GET_AUTHENTICATION_DATA,
  GET_AUTHENTICATION_DATA_SUCCESSFUL,
  GET_AUTHENTICATION_DATA_FAILED,
  GET_BROKER_DATA,
  GET_BROKER_DATA_SUCCESSFUL,
  GET_BROKER_DATA_FAILED,
  GET_MASTER_DATA,
  GET_MASTER_DATA_SUCCESSFUL,
  GET_MASTER_DATA_FAILED,
  LINK_MASTER,
  LINK_MASTER_SUCCESSFUL,
  LINK_MASTER_FAILED,
  GENERATE_TOKEN,
  GENERATE_TOKEN_SUCCESSFUL,
  GENERATE_TOKEN_FAILED,
  VALIDATE_TOKEN,
  VALIDATE_TOKEN_SUCCESSFUL,
  VALIDATE_TOKEN_FAILED
} from "./actionTypes"

export const addBroker = (user, history) => {
  return {
    type: ADD_BROKER,
    payload: { user, history },
  }
}

export const addBrokerSuccessful = user => {
  return {
    type: ADD_BROKER_SUCCESSFUL,
    payload: user,
  }
}

export const addBrokerFailed = user => {
  return {
    type: ADD_BROKER_FAILED,
    payload: user,
  }
}

export const getAuthenticationData = (user, history) => {
  return {
    type: GET_AUTHENTICATION_DATA,
    payload: { user, history },
  }
}

export const getAuthenticationDataSuccessful = user => {
  return {
    type: GET_AUTHENTICATION_DATA_SUCCESSFUL,
    payload: user,
  }
}

export const getAuthenticationDataFailed = user => {
  return {
    type: GET_AUTHENTICATION_DATA_FAILED,
    payload: user,
  }
}

export const getBrokerData = (user, history) => {
  return {
    type: GET_BROKER_DATA,
    payload: { user, history },
  }
}

export const getBrokerDataSuccessful = user => {
  return {
    type: GET_BROKER_DATA_SUCCESSFUL,
    payload: user,
  }
}

export const getBrokerDataFailed = user => {
  return {
    type: GET_BROKER_DATA_FAILED,
    payload: user,
  }
}

export const getMasterData = (user, history) => {
  return {
    type: GET_MASTER_DATA,
    payload: { user, history },
  }
}

export const getMasterDataSuccessful = user => {
  return {
    type: GET_MASTER_DATA_SUCCESSFUL,
    payload: user,
  }
}

export const getMasterDataFailed = user => {
  return {
    type: GET_MASTER_DATA_FAILED,
    payload: user,
  }
}
export const linkMaster = (user, history) => {
  return {
    type: LINK_MASTER,
    payload: { user, history },
  }
}

export const linkMasterSuccessful = user => {
  return {
    type: LINK_MASTER_SUCCESSFUL,
    payload: user,
  }
}

export const linkMasterFailed = user => {
  return {
    type: LINK_MASTER_FAILED,
    payload: user,
  }
}

export const generateToken = (user, history) => {
  return {
    type: GENERATE_TOKEN,
    payload: { user, history },
  }
}

export const generateTokenSuccessful = user => {
  return {
    type: GENERATE_TOKEN_SUCCESSFUL,
    payload: user,
  }
}

export const generateTokenFailed = user => {
  return {
    type: GENERATE_TOKEN_FAILED,
    payload: user,
  }
}

export const validateToken = (user, history) => {
  return {
    type: VALIDATE_TOKEN,
    payload: { user, history },
  }
}

export const validateTokenSuccessful = user => {
  return {
    type: VALIDATE_TOKEN_SUCCESSFUL,
    payload: user,
  }
}

export const validateTokenFailed = user => {
  return {
    type: VALIDATE_TOKEN_FAILED,
    payload: user,
  }
}