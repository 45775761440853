import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {

      //console.log(props.location.pathname);

      var path = props.location.pathname;

      if (path.indexOf("/admin") > -1) {

        if (isAuthProtected && !localStorage.getItem("authAdmin")) {
          return (
            <Redirect
              to={{ pathname: "/admin/login", state: { from: props.location } }}
            />
          )
        }

      } else {

        if (path.indexOf("/totp") > -1) {

          if (!localStorage.getItem("authUser")) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            )
          }

        }

        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        if (isAuthProtected && localStorage.getItem("authUser")) {

          var authUser = localStorage.getItem("authUser");
          if (authUser) { authUser = atob(authUser); }
          authUser = JSON.parse(authUser);

          if (path.indexOf("/disclaimer") == -1) {

            if (authUser.DisclaimerAgree == "false") {

              return (
                <Redirect
                  to={{ pathname: "/disclaimer", state: { from: props.location } }}
                />
              )

            }
          }

          if (path.indexOf("/totp") == -1) {

            if (authUser.mfaEnabled == "false" && authUser.twoFactor == "true") {

              return (
                <Redirect
                  to={{ pathname: "/totp", state: { from: props.location } }}
                />
              )
            }
          }

          if (path.indexOf("/disclaimer") > -1 && authUser.DisclaimerAgree == "true") {

            return (
              <Redirect
                to={{ pathname: "/marketwatch", state: { from: props.location } }}
              />
            )

          }

          if (path.indexOf("/totp") > -1 && ((authUser.twoFactor == "true" && authUser.mfaEnabled == "true") || (authUser.twoFactor == "false"))) {

            return (
              <Redirect
                to={{ pathname: "/marketwatch", state: { from: props.location } }}
              />
            )

          }

        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
