const optionwriting_data_init_state = {
  symbol:"",
  expiry_date: new Date().toISOString().slice(0, 10),
  execution_time: "09:15",
  hedge_range: "10",
  level1_stoploss: "30",
  level2_stoploss: "50",
  stoploss_level: "1",
}

module.exports = optionwriting_data_init_state