import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions
import { getUsers, usersEvents, userRoleChange, usersEventsSuccessful, usersEventsFailed } from "../../../store/actions";

import axios from "axios";

import { API_URL } from "helpers/api_helper";


// var usersData = [
//     {
//         followingMasterKey: "abc",
//         name: "abc",
//         email: "abc@gmail.com",
//         mobile: "123",
//         role: "user",
//         daysleft: 10,
//         active: "true"
//     }
// ];

const Users = props => {

    //meta title
    document.title = "Users | Algobridge ";

    const dispatch = useDispatch();

    const { apiSuccess, apiError } = useSelector(state => ({
        apiSuccess: state.AdminUsers.apiSuccess,
        apiError: state.AdminUsers.apiError,
    }
    ));

    const [extendDays, SetextendDays] = useState({ id: 0, days: "0" });


    const [page, setpage] = useState(0);

    const [searchData, setsearchData] = useState("");

    const [usersData, setusersData] = useState(null);

    const [customActiveTab, setcustomActiveTab] = useState("1");

    useEffect(() => {
       LoadData(searchData, page, "Manual");
    }, []);

    useEffect(() => {
        dispatch(usersEventsSuccessful(null));
        dispatch(usersEventsFailed(null));
    }, []);

    useEffect(() => {
        if (apiSuccess != null) {
            setTimeout(() => dispatch(usersEventsSuccessful(null)), 1000)
        }
    }, [apiSuccess]);

    useEffect(() => {
        if (apiError != null) {
            setTimeout(() => dispatch(usersEventsFailed(null)), 1000)
        }
    }, [apiError]);

    function handleRoleChange(email, role) {
        if (role != "select") {
            var index = usersData.findIndex(item => item.email == email);
            if (index > -1) {
                usersData[index].role = role;
            }
        }
    }

    function handleServiceChange(email, service) {
        if (service != "select") {
            var index = usersData.findIndex(item => item.email == email);
            if (index > -1) {
                usersData[index].service = service;
            }
        }
    }

    function showServiceExpiration(key, service, data) {

        var days = 0

        if (service == "copytrader") {
            if (data.copytrader_subscriptions.length > 0) {
                days = parseInt((new Date(data.copytrader_subscriptions[0].expired_at) - new Date(Date.now())) / (1000 * 60 * 60 * 24));
            }
        }
        else if (service == "bridge") {
            if (data.bridge_subscriptions.length > 0) {
                days = parseInt((new Date(data.bridge_subscriptions[0].expired_at) - new Date(Date.now())) / (1000 * 60 * 60 * 24));
            }
        }
        SetextendDays({ id: key, days: days });
    }

    function SearchData(createdBy) {
        //alert("searchData=" + searchData);
        LoadData(searchData, page, createdBy);
    }

    function LoadData(search, no, createdBy) {

        var obj = {
            "search": search,
            "page": no
        };

        if (createdBy != "") {
            obj.createdBy = createdBy;
        }

        //console.log("page=" + no);

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/admin/getUsers', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                setusersData(output.data);
            }
            else {
                setusersData(null);
            }
        });
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    function ResetQR(email) {

        var obj = {
            "email": email,
        };

        //console.log("page=" + no);

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(API_URL + '/user/reset-qr-key', JSON.stringify(obj), { headers }).then(response => {

            //console.log(response) 

            var output = response.data;

            if (output.result == "success") {
                dispatch(usersEventsSuccessful(output.message));
            }
            else {
                dispatch(usersEventsFailed(output.message));
            }
        });

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}

                    <Breadcrumbs title="Dashboards" breadcrumbItem="Users" />

                    {apiSuccess && (
                        <div
                            className="alert alert-success text-center"
                            role="alert">
                            {apiSuccess}
                        </div>
                    )}

                    {apiError && (
                        <div
                            className="alert alert-danger text-center"
                            role="alert">
                            {apiError}
                        </div>
                    )}

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>

                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "1",
                                                })}
                                                onClick={() => {
                                                    SetextendDays({ id: 0, days: "0" });
                                                    setpage(0);
                                                    setsearchData("");
                                                    LoadData(searchData, 0, "Manual");
                                                    toggleCustom("1");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="fas fa-home"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Algobrige Users</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: customActiveTab === "2",
                                                })}
                                                onClick={() => {
                                                    SetextendDays({ id: 0, days: "0" });
                                                    setpage(0);
                                                    setsearchData("");
                                                    LoadData(searchData, 0, "Master");
                                                    toggleCustom("2");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-user"></i>
                                                </span>
                                                <span className="d-none d-sm-block">Master's Users</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent
                                        activeTab={customActiveTab}
                                        className="p-3 text-muted"
                                    >
                                        <TabPane tabId="1">
                                            <Row>
                                                <Row className="mt-3 mb-3">
                                                    <Col md={6} className="mb-2">
                                                        <div className="d-flex flex-wrap gap-2">

                                                            <div className="btn-group">
                                                                <Link to="#" onClick={() => {
                                                                    if (page > 0) {
                                                                        LoadData(searchData, page - 1, "Manual");
                                                                        setpage(page - 1);
                                                                    }
                                                                }} className="btn btn-outline-primary">Prev</Link>
                                                                <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                                                                <Link to="#" onClick={() => {
                                                                    LoadData(searchData, page + 1, "Manual");
                                                                    setpage(page + 1);
                                                                }} className="btn btn-outline-primary">Next</Link>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                                                        <div style={{ display: "inline-flex" }}>
                                                            <input value={searchData} onChange={(val) => {
                                                                if (val != "") {
                                                                    setsearchData(val.target.value)
                                                                }
                                                            }}
                                                                onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        SearchData("Manual");
                                                                    }
                                                                }}
                                                                className="form-control " type="text" placeholder="Search"></input>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            id="tech-companies-1"
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="1">Master Key</Th>
                                                                    <Th data-priority="3">Name</Th>
                                                                    <Th data-priority="4">Email</Th>
                                                                    <Th data-priority="5">Mobile</Th>
                                                                    <Th data-priority="6">Role</Th>
                                                                    <Th data-priority="6">Service</Th>
                                                                    <Th data-priority="7">Days Left</Th>
                                                                    <Th data-priority="8">Action</Th>
                                                                    <Th data-priority="9">Reset QR</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {usersData &&

                                                                    usersData.map((data, key) => (
                                                                        <Tr key={key}>
                                                                            <Td>{(data.role == "master") ? data.masterKey : "-"}</Td>
                                                                            <Td>{data.name}</Td>
                                                                            <Td>{data.email}</Td>
                                                                            <Td>{data.mobile}</Td>
                                                                            <Td>
                                                                                <select value={data.role} name="role" className="form-select" onChange={(val) => { handleRoleChange(data.email, val.target.value) }}>
                                                                                    <option value="select">Select</option>
                                                                                    <option value="user">User</option>
                                                                                    <option value="master">Master</option>
                                                                                </select>
                                                                            </Td>
                                                                            <Td>
                                                                                <select value={data.service || "select"} name="service" className="form-select" onChange={(val) => { handleServiceChange(data.email, val.target.value); showServiceExpiration(key, val.target.value, data); }}>
                                                                                    <option value="select">Select</option>
                                                                                    <option value="copytrader">Copier</option>
                                                                                    <option value="bridge">Bridge</option>
                                                                                </select>
                                                                            </Td>
                                                                            <Td>
                                                                                <div style={{ display: "inline-flex", "alignItems": "center" }}>
                                                                                    <span>{(extendDays.id == key) ? extendDays.days : "0"}</span>
                                                                                    <input type="text" onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }} style={{ "width": "70px" }} className="form-control ms-2" maxLength="100" placeholder="Add Days" value={(extendDays.id == key) ? extendDays.days : "0"} onChange={e => SetextendDays({ id: key, days: e.target.value })} />
                                                                                </div>
                                                                            </Td>
                                                                            <Td>

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-info me-2" onClick={() => { dispatch(userRoleChange({ "email": data.email, "userKey": data.userKey, "role": data.role, "service": data.service, "days": (extendDays.days == "") ? "0" : extendDays.days.toString(), "masterEmail": (data.copytrader_subscriptions.length > 0) ? data.copytrader_subscriptions[0].masterEmail : "empty" }, props.history)); setTimeout(() => { LoadData(searchData, page, "Manual"); }, 500); }}
                                                                                >
                                                                                    <i className="bx bx-cart-alt font-size-14 align-middle"></i>

                                                                                </button>

                                                                                {data.active == "false" ? (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-sm btn-success me-2" onClick={() => { dispatch(usersEvents({ "email": data.email, "event": "start" }, props.history)); setTimeout(() => { LoadData(searchData, page, "Manual") }, 500); }}
                                                                                    >
                                                                                        <i className="bx bx-play-circle font-size-14 align-middle"></i>

                                                                                    </button>) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-sm btn-warning me-2" onClick={() => { dispatch(usersEvents({ "email": data.email, "event": "stop" }, props.history)); setTimeout(() => { LoadData(searchData, page, "Manual") }, 500); }}
                                                                                    >
                                                                                        <i className="bx bx-pause-circle font-size-14 align-middle"></i>

                                                                                    </button>)}

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-danger" onClick={() => { dispatch(usersEvents({ "email": data.email, "event": "delete" }, props.history)); setTimeout(() => { LoadData(searchData, page, "Manual") }, 500); }}
                                                                                >
                                                                                    <i className="bx bx-trash font-size-14 align-middle"></i>

                                                                                </button>
                                                                            </Td>

                                                                            <Td>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-info me-2" onClick={() => { ResetQR(data.email) }}
                                                                                >
                                                                                    <i className="bx bx-rotate-left font-size-16 align-middle"></i>{" "}

                                                                                </button>
                                                                            </Td>
                                                                        </Tr>
                                                                    ))}
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Row className="mt-3 mb-3">
                                                    <Col md={6} className="mb-2">
                                                        <div className="d-flex flex-wrap gap-2">

                                                            <div className="btn-group">
                                                                <Link to="#" onClick={() => {
                                                                    if (page > 0) {
                                                                        LoadData(searchData, page - 1, "Master");
                                                                        setpage(page - 1);
                                                                    }
                                                                }} className="btn btn-outline-primary">Prev</Link>
                                                                <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                                                                <Link to="#" onClick={() => {
                                                                    LoadData(searchData, page + 1, "Master");
                                                                    setpage(page + 1);
                                                                }} className="btn btn-outline-primary">Next</Link>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                                                        <div style={{ display: "inline-flex" }}>
                                                            <input value={searchData} onChange={(val) => {
                                                                if (val != "") {
                                                                    setsearchData(val.target.value)
                                                                }
                                                            }}
                                                                onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        SearchData("Master");
                                                                    }
                                                                }}
                                                                className="form-control " type="text" placeholder="Search"></input>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="table-rep-plugin">
                                                    <div
                                                        className="table-responsive mb-0"
                                                        data-pattern="priority-columns"
                                                    >
                                                        <Table
                                                            id="tech-companies-1"
                                                            className="table table-striped table-bordered"
                                                        >
                                                            <Thead>
                                                                <Tr>
                                                                    <Th data-priority="1">Master Key</Th>
                                                                    <Th data-priority="3">Name</Th>
                                                                    <Th data-priority="4">Email</Th>
                                                                    <Th data-priority="5">Mobile</Th>
                                                                    <Th data-priority="6">Role</Th>
                                                                    <Th data-priority="6">Service</Th>
                                                                    <Th data-priority="7">Days Left</Th>
                                                                    <Th data-priority="8">Action</Th>
                                                                    <Th data-priority="9">Reset QR</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {usersData &&

                                                                    usersData.map((data, key) => (
                                                                        <Tr key={key}>
                                                                            <Td>{(data.role == "master") ? data.masterKey : "-"}</Td>
                                                                            <Td>{data.name}</Td>
                                                                            <Td>{data.email}</Td>
                                                                            <Td>{data.mobile}</Td>
                                                                            <Td>
                                                                                <select value={data.role} name="role" className="form-select" onChange={(val) => { handleRoleChange(data.email, val.target.value) }}>
                                                                                    <option value="select">Select</option>
                                                                                    <option value="user">User</option>
                                                                                    <option value="master">Master</option>
                                                                                </select>
                                                                            </Td>
                                                                            <Td>
                                                                                <select value={data.service || "select"} name="service" className="form-select" onChange={(val) => { handleServiceChange(data.email, val.target.value); showServiceExpiration(key, val.target.value, data); }}>
                                                                                    <option value="select">Select</option>
                                                                                    <option value="copytrader">Copier</option>
                                                                                    <option value="bridge">Bridge</option>
                                                                                </select>
                                                                            </Td>
                                                                            <Td>
                                                                                <div style={{ display: "inline-flex", "alignItems": "center" }}>
                                                                                    <span>{(extendDays.id == key) ? extendDays.days : "0"}</span>
                                                                                    <input type="text" onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }} style={{ "width": "70px" }} className="form-control ms-2" maxLength="100" placeholder="Add Days" value={(extendDays.id == key) ? extendDays.days : "0"} onChange={e => SetextendDays({ id: key, days: e.target.value })} />
                                                                                </div>
                                                                            </Td>
                                                                            <Td>

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-info me-2" onClick={() => { dispatch(userRoleChange({ "email": data.email, "userKey": data.userKey, "role": data.role, "service": data.service, "days": (extendDays.days == "") ? "0" : extendDays.days.toString(), "masterEmail": (data.copytrader_subscriptions.length > 0) ? data.copytrader_subscriptions[0].masterEmail : "empty" }, props.history)); setTimeout(() => { LoadData(searchData, page, "Master"); }, 500); }}
                                                                                >
                                                                                    <i className="bx bx-cart-alt font-size-14 align-middle"></i>

                                                                                </button>

                                                                                {data.active == "false" ? (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-sm btn-success me-2" onClick={() => { dispatch(usersEvents({ "email": data.email, "event": "start" }, props.history)); setTimeout(() => { LoadData(searchData, page, "Master") }, 500); }}
                                                                                    >
                                                                                        <i className="bx bx-play-circle font-size-14 align-middle"></i>

                                                                                    </button>) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-sm btn-warning me-2" onClick={() => { dispatch(usersEvents({ "email": data.email, "event": "stop" }, props.history)); setTimeout(() => { LoadData(searchData, page, "Master") }, 500); }}
                                                                                    >
                                                                                        <i className="bx bx-pause-circle font-size-14 align-middle"></i>

                                                                                    </button>)}

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-danger" onClick={() => { dispatch(usersEvents({ "email": data.email, "event": "delete" }, props.history)); setTimeout(() => { LoadData(searchData, page, "Master") }, 500); }}
                                                                                >
                                                                                    <i className="bx bx-trash font-size-14 align-middle"></i>

                                                                                </button>
                                                                            </Td>

                                                                            <Td>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-info me-2" onClick={() => { ResetQR(data.email) }}
                                                                                >
                                                                                    <i className="bx bx-rotate-left font-size-16 align-middle"></i>{" "}

                                                                                </button>
                                                                            </Td>
                                                                        </Tr>
                                                                    ))}
                                                            </Tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Users
