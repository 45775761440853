import { del, get, post, put } from "./api_helper";

//ADMIN MODULES

export const loginAdminCall = data => post("/admin/loginAdmin", data);

export const getUsersCall = data => post("/admin/getUsers", data);
export const changeUserRoleCall = data => post("/admin/changeUserRoleandRenewal", data);
export const usersEventsCall = data => post("/admin/usersEvents", data);

export const getMastersCall = data => post("/admin/getMasters", data);
export const changeMasterAmountCall = data => post("/admin/updateMasterAmount", data);
export const mastersEventsCall = data => post("/admin/usersEvents", data);

export const getPaymentsCall = data => post("/admin/getPayments", data);

//USER MODULES
export const registerUserCall = data => post("/user/registerUser", data);

export const loginUserCall = data => post("/user/loginUser", data);

export const forgotPwdCall = data => post("/user/forgotPwd", data);

export const resetPwdCall = data => post("/user/resetPwd", data);

export const addBrokerCall = data => post("/user/addBroker", data);

export const getAuthenticationDataCall = data => post("/user/getAuthenticationData", data);

export const getBrokerDataCall = data => post("/user/getBrokerData", data);

export const getMasterDataCall = data => post("/user/getMasterData", data);

export const linkMasterCall = data => post("/user/linkMaster", data);

export const generateTokenCall = data => post("/user/generateToken", data);

export const validateTokenCall = data => post("/user/validateToken", data);

export const getFollowRequestsCall = data => post("/user/getFollowRequests", data);

export const followRequestsEventsCall = data => post("/user/followRequestsEvents", data);

export const getFollowClientsCall = data => post("/user/getFollowClients", data);

export const followClientsEventsCall = data => post("/user/followClientsEvents", data);

export const followClientsRenewalCall = data => post("/user/followClientsRenewal", data);

export const getSymbolsCall = data => post("/trading/getSymbols", data);

export const getOrdersCall = data => post("/trading/getOrders", data);

export const squareoffOrdersCall = data => post("/trading/squareoffPosition", data);

export const getOrderbookCall = data => post("/trading/getOrderbook", data);

export const getTradebookCall = data => post("/trading/getTradebook", data);

export const getPositionsCall = data => post("/trading/getPositions", data);

export const placeOrdersCall = data => post("/trading/placeOrders", data);

export const addMarketwatchCall = data => post("/trading/addMarketwatch", data);

export const deleteMarketwatchCall = data => post("/trading/deleteMarketwatch", data);

export const getMarketwatchCall = data => post("/trading/getMarketwatch", data);