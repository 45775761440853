import {
  RESET_PASSWORD_SUCCESSFUL,
  RESET_PASSWORD_FAILED,
} from "./actionTypes"

const initialState = {
  apiSuccess: null,
  apiError: null
}

const ResetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESSFUL:
      state = {
        ...state,       
        apiSuccess: action.payload,
        apiError: null
      }
      break
    case RESET_PASSWORD_FAILED:
      state = {
        ...state,     
        apiSuccess: null,
        apiError: action.payload
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ResetPassword
