import {
  GET_SYMBOLS,
  GET_SYMBOLS_SUCCESSFUL,
  GET_SYMBOLS_FAILED,
  GET_ORDERS,
  GET_ORDERS_SUCCESSFUL,
  GET_ORDERS_FAILED,
  GET_ORDERBOOK,
  GET_ORDERBOOK_SUCCESSFUL,
  GET_ORDERBOOK_FAILED,
  GET_TRADEBOOK,
  GET_TRADEBOOK_SUCCESSFUL,
  GET_TRADEBOOK_FAILED,
  GET_POSITIONS,
  GET_POSITIONS_SUCCESSFUL,
  GET_POSITIONS_FAILED,
  PLACE_ORDERS,
  PLACE_ORDERS_SUCCESSFUL,
  PLACE_ORDERS_FAILED,
  ADD_MARKETWATCH,
  ADD_MARKETWATCH_SUCCESSFUL,
  ADD_MARKETWATCH_FAILED,
  DELETE_MARKETWATCH,
  DELETE_MARKETWATCH_SUCCESSFUL,
  DELETE_MARKETWATCH_FAILED,
  GET_MARKETWATCH,
  GET_MARKETWATCH_SUCCESSFUL,
  GET_MARKETWATCH_FAILED,
  RESET_APIEVENT,
  SQUAREOFF_POSITIONS,
  SQUAREOFF_POSITIONS_SUCCESSFUL,
  SQUAREOFF_POSITIONS_FAILED
} from "./actionTypes"

export const resetApiEvent = (user, history) => {
  return {
    type: RESET_APIEVENT,
    payload: { user, history },
  }
}

export const getSymbols = (user, history) => {
  return {
    type: GET_SYMBOLS,
    payload: { user, history },
  }
}

export const getSymbolsSuccessful = user => {
  return {
    type: GET_SYMBOLS_SUCCESSFUL,
    payload: user,
  }
}

export const getSymbolsFailed = user => {
  return {
    type: GET_SYMBOLS_FAILED,
    payload: user,
  }
}

export const getOrders = (user, history) => {
  return {
    type: GET_ORDERS,
    payload: { user, history },
  }
}

export const getOrdersSuccessful = user => {
  return {
    type: GET_ORDERS_SUCCESSFUL,
    payload: user,
  }
}

export const getOrdersFailed = user => {
  return {
    type: GET_ORDERS_FAILED,
    payload: user,
  }
}

export const getOrderbook = (user, history) => {
  return {
    type: GET_ORDERBOOK,
    payload: { user, history },
  }
}

export const getOrderbookSuccessful = user => {
  return {
    type: GET_ORDERBOOK_SUCCESSFUL,
    payload: user,
  }
}

export const getOrderbookFailed = user => {
  return {
    type: GET_ORDERBOOK_FAILED,
    payload: user,
  }
}

export const getTradebook = (user, history) => {
  return {
    type: GET_TRADEBOOK,
    payload: { user, history },
  }
}

export const getTradebookSuccessful = user => {
  return {
    type: GET_TRADEBOOK_SUCCESSFUL,
    payload: user,
  }
}

export const getTradebookFailed = user => {
  return {
    type: GET_TRADEBOOK_FAILED,
    payload: user,
  }
}

export const getPositions = (user, history) => {
  return {
    type: GET_POSITIONS,
    payload: { user, history },
  }
}

export const getPositionsSuccessful = user => {
  return {
    type: GET_POSITIONS_SUCCESSFUL,
    payload: user,
  }
}

export const getPositionsFailed = user => {
  return {
    type: GET_POSITIONS_FAILED,
    payload: user,
  }
}

export const placeOrders = (user, history) => {
  return {
    type: PLACE_ORDERS,
    payload: { user, history },
  }
}

export const placeOrdersSuccessful = user => {
  return {
    type: PLACE_ORDERS_SUCCESSFUL,
    payload: user,
  }
}

export const placeOrdersFailed = user => {
  return {
    type: PLACE_ORDERS_FAILED,
    payload: user,
  }
}

export const addMarketwatch = (user, history) => {
  return {
    type: ADD_MARKETWATCH,
    payload: { user, history },
  }
}

export const addMarketwatchSuccessful = user => {
  return {
    type: ADD_MARKETWATCH_SUCCESSFUL,
    payload: user,
  }
}

export const addMarketwatchFailed = user => {
  return {
    type: ADD_MARKETWATCH_FAILED,
    payload: user,
  }
}

export const deleteMarketwatch = (user, history) => {
  return {
    type: DELETE_MARKETWATCH,
    payload: { user, history },
  }
}

export const deleteMarketwatchSuccessful = user => {
  return {
    type: DELETE_MARKETWATCH_SUCCESSFUL,
    payload: user,
  }
}

export const deleteMarketwatchFailed = user => {
  return {
    type: DELETE_MARKETWATCH_FAILED,
    payload: user,
  }
}

export const getMarketwatch = (user, history) => {
  return {
    type: GET_MARKETWATCH,
    payload: { user, history },
  }
}

export const getMarketwatchSuccessful = user => {
  return {
    type: GET_MARKETWATCH_SUCCESSFUL,
    payload: user,
  }
}

export const getMarketwatchFailed = user => {
  return {
    type: GET_MARKETWATCH_FAILED,
    payload: user,
  }
}

export const squareoffPositions = (user, history) => {
  return {
    type: SQUAREOFF_POSITIONS,
    payload: { user, history },
  }
}

export const squareoffPositionsSuccessful = user => {
  return {
    type: SQUAREOFF_POSITIONS_SUCCESSFUL,
    payload: user,
  }
}

export const squareoffPositionsFailed = user => {
  return {
    type: SQUAREOFF_POSITIONS_FAILED,
    payload: user,
  }
}