import {
  GET_FOLLOW_REQUESTS,
  GET_FOLLOW_REQUESTS_SUCCESSFUL,
  GET_FOLLOW_REQUESTS_FAILED,
  FOLLOW_REQUESTS_EVENTS,
  FOLLOW_REQUESTS_EVENTS_SUCCESSFUL,
  FOLLOW_REQUESTS_EVENTS_FAILED,
  GET_FOLLOW_CLIENTS,
  GET_FOLLOW_CLIENTS_SUCCESSFUL,
  GET_FOLLOW_CLIENTS_FAILED,
  FOLLOW_CLIENTS_EVENTS,
  FOLLOW_CLIENTS_EVENTS_SUCCESSFUL,
  FOLLOW_CLIENTS_EVENTS_FAILED,
  FOLLOW_CLIENTS_RENEWAL,
  FOLLOW_CLIENTS_RENEWAL_SUCCESSFUL,
  FOLLOW_CLIENTS_RENEWAL_FAILED
} from "./actionTypes"

export const getFollowRequests = (user, history) => {
  return {
    type: GET_FOLLOW_REQUESTS,
    payload: { user, history },
  }
}

export const getFollowRequestsSuccessful = user => {
  return {
    type: GET_FOLLOW_REQUESTS_SUCCESSFUL,
    payload: user,
  }
}

export const getFollowRequestsFailed = user => {
  return {
    type: GET_FOLLOW_REQUESTS_FAILED,
    payload: user,
  }
}

export const followRequestsEvents = (user, history) => {
  return {
    type: FOLLOW_REQUESTS_EVENTS,
    payload: { user, history },
  }
}

export const followRequestsEventsSuccessful = user => {
  return {
    type: FOLLOW_REQUESTS_EVENTS_SUCCESSFUL,
    payload: user,
  }
}

export const followRequestsEventsFailed = user => {
  return {
    type: FOLLOW_REQUESTS_EVENTS_FAILED,
    payload: user,
  }
}

export const getFollowClients = (user, history) => {
  return {
    type: GET_FOLLOW_CLIENTS,
    payload: { user, history },
  }
}

export const getFollowClientsSuccessful = user => {
  return {
    type: GET_FOLLOW_CLIENTS_SUCCESSFUL,
    payload: user,
  }
}

export const getFollowClientsFailed = user => {
  return {
    type: GET_FOLLOW_CLIENTS_FAILED,
    payload: user,
  }
}

export const followClientsEvents = (user, history) => {
  return {
    type: FOLLOW_CLIENTS_EVENTS,
    payload: { user, history },
  }
}

export const followClientsEventsSuccessful = user => {
  return {
    type: FOLLOW_CLIENTS_EVENTS_SUCCESSFUL,
    payload: user,
  }
}

export const followClientsEventsFailed = user => {
  return {
    type: FOLLOW_CLIENTS_EVENTS_FAILED,
    payload: user,
  }
}

export const followClientsRenewal = (user, history) => {
  return {
    type: FOLLOW_CLIENTS_RENEWAL,
    payload: { user, history },
  }
}

export const followClientsRenewalSuccessful = user => {
  return {
    type: FOLLOW_CLIENTS_RENEWAL_SUCCESSFUL,
    payload: user,
  }
}

export const followClientsRenewalFailed = user => {
  return {
    type: FOLLOW_CLIENTS_RENEWAL_FAILED,
    payload: user,
  }
}

