import {
  ADD_BROKER_SUCCESSFUL,
  ADD_BROKER_FAILED,
  GET_AUTHENTICATION_DATA_SUCCESSFUL,
  GET_AUTHENTICATION_DATA_FAILED,
  GET_BROKER_DATA_SUCCESSFUL,
  GET_BROKER_DATA_FAILED,
  GET_MASTER_DATA_SUCCESSFUL,
  GET_MASTER_DATA_FAILED,
  LINK_MASTER_SUCCESSFUL,
  LINK_MASTER_FAILED,
  GENERATE_TOKEN_SUCCESSFUL,
  GENERATE_TOKEN_FAILED,
  VALIDATE_TOKEN_SUCCESSFUL,
  VALIDATE_TOKEN_FAILED
} from "./actionTypes"

const initialState = {
  loading: false,
  apiSuccess: null,
  apiError: null,
  authenticationData: null,
  brokerData: null,
  masterData: null
}

const Authentication = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BROKER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null
      }
      break
    case ADD_BROKER_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload
      }
      break
    case GET_AUTHENTICATION_DATA_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        authenticationData: action.payload
      }
      break
    case GET_AUTHENTICATION_DATA_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        authenticationData: null
      }
      break
    case GET_BROKER_DATA_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        brokerData: action.payload
      }
      break
    case GET_BROKER_DATA_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        brokerData: null
      }
      break
    case GET_MASTER_DATA_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        masterData: action.payload
      }
      break
    case GET_MASTER_DATA_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        masterData: null
      }
      break
    case LINK_MASTER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case LINK_MASTER_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case GENERATE_TOKEN_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null
      }
      break
    case GENERATE_TOKEN_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload
      }
      break
    case VALIDATE_TOKEN_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null
      }
      break
    case VALIDATE_TOKEN_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Authentication
