import { all, fork } from "redux-saga/effects"

//public

//ADMIN MODULES

import AdminAuthSaga from "./admin/login/saga"
import AdminUsersSaga from "./admin/users/saga"
import AdminMastersSaga from "./admin/masters/saga"
import AdminPaymentsSaga from "./admin/payments/saga"

//USER MODULES
import RegisterSaga from "./users/auth/register/saga"
import AuthSaga from "./users/auth/login/saga"
import ForgetSaga from "./users/auth/forgetpwd/saga"
import ResetSaga from "./users/auth/resetpwd/saga"
import LayoutSaga from "./layout/saga"
import AuthenticationSaga from "./users/authentication/saga"
import MyClientsSaga from "./users/myclients/saga"
import TradingSaga from "./users/trading/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(RegisterSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ResetSaga),
    fork(LayoutSaga),
    fork(AuthenticationSaga),
    fork(MyClientsSaga),
    fork(TradingSaga),

    fork(AdminAuthSaga),
    fork(AdminUsersSaga),
    fork(AdminMastersSaga),
    fork(AdminPaymentsSaga),
  ])
}
