import {
  LOGIN_USER_SUCCESSFUL,
  LOGIN_USER_FAILED,
  LOGOUT_USER_SUCCESSFUL,
  LOGOUT_USER_FAILED,
} from "./actionTypes"

const initialState = {
  loading: false,
  apiSuccess: null,
  apiError: null
}

const Login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,      
        apiSuccess: action.payload,
        apiError: null
      }
      break
    case LOGIN_USER_FAILED:
      state = {
        ...state,
        loading: false,      
        apiSuccess: null,
        apiError: action.payload
      }
      break
    case LOGOUT_USER_SUCCESSFUL:
      state = { ...state }
      break
    case LOGOUT_USER_FAILED:
      state = { ...state }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Login
