import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Badge
} from "reactstrap";

import { Link } from "react-router-dom"

import Swal from 'sweetalert2'

const paymentSuccess = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {
                        Swal.fire({
                            title: "Thank you!",
                            html: "<p>Your Payment received Successfully</p><p>We have extended your Subscription !!!</p>",
                            icon: "success",
                        }).then(function (result) {
                            location.href = "/marketwatch";
                        })
                    }
                    
                </Container>
            </div>
        </React.Fragment>
    );
}

export default paymentSuccess;