export const GET_FOLLOW_REQUESTS = "GET_FOLLOW_REQUESTS"
export const GET_FOLLOW_REQUESTS_SUCCESSFUL = "GET_FOLLOW_REQUESTS_SUCCESSFUL"
export const GET_FOLLOW_REQUESTS_FAILED = "GET_FOLLOW_REQUESTS_FAILED"

export const FOLLOW_REQUESTS_EVENTS = "FOLLOW_REQUESTS_EVENTS"
export const FOLLOW_REQUESTS_EVENTS_SUCCESSFUL = "FOLLOW_REQUESTS_EVENTS_SUCCESSFUL"
export const FOLLOW_REQUESTS_EVENTS_FAILED = "FOLLOW_REQUESTS_EVENTS_FAILED"

export const GET_FOLLOW_CLIENTS = "GET_FOLLOW_CLIENTS"
export const GET_FOLLOW_CLIENTS_SUCCESSFUL = "GET_FOLLOW_CLIENTS_SUCCESSFUL"
export const GET_FOLLOW_CLIENTS_FAILED = "GET_FOLLOW_CLIENTS_FAILED"

export const FOLLOW_CLIENTS_EVENTS = "FOLLOW_CLIENTS_EVENTS"
export const FOLLOW_CLIENTS_EVENTS_SUCCESSFUL = "FOLLOW_CLIENTS_EVENTS_SUCCESSFUL"
export const FOLLOW_CLIENTS_EVENTS_FAILED = "FOLLOW_CLIENTS_EVENTS_FAILED"

export const FOLLOW_CLIENTS_RENEWAL = "FOLLOW_CLIENTS_RENEWAL"
export const FOLLOW_CLIENTS_RENEWAL_SUCCESSFUL = "FOLLOW_CLIENTS_RENEWAL_SUCCESSFUL"
export const FOLLOW_CLIENTS_RENEWAL_FAILED = "FOLLOW_CLIENTS_RENEWAL_FAILED"