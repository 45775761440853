import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESSFUL,
  FORGET_PASSWORD_FAILED,
} from "./actionTypes"

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  }
}

export const userForgetPasswordSuccessful = message => {
  return {
    type: FORGET_PASSWORD_SUCCESSFUL,
    payload: message,
  }
}

export const userForgetPasswordFailed = message => {
  return {
    type: FORGET_PASSWORD_FAILED,
    payload: message,
  }
}
