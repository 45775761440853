export const ADD_BROKER = "ADD_BROKER"
export const ADD_BROKER_SUCCESSFUL = "ADD_BROKER_SUCCESSFUL"
export const ADD_BROKER_FAILED = "ADD_BROKER_FAILED"

export const GET_AUTHENTICATION_DATA = "GET_AUTHENTICATION_DATA"
export const GET_AUTHENTICATION_DATA_SUCCESSFUL = "GET_AUTHENTICATION_DATA_SUCCESSFUL"
export const GET_AUTHENTICATION_DATA_FAILED = "GET_AUTHENTICATION_DATA_FAILED"

export const GET_BROKER_DATA = "GET_BROKER_DATA"
export const GET_BROKER_DATA_SUCCESSFUL = "GET_BROKER_DATA_SUCCESSFUL"
export const GET_BROKER_DATA_FAILED = "GET_BROKER_DATA_FAILED"

export const GET_MASTER_DATA = "GET_MASTER_DATA"
export const GET_MASTER_DATA_SUCCESSFUL = "GET_MASTER_DATA_SUCCESSFUL"
export const GET_MASTER_DATA_FAILED = "GET_MASTER_DATA_FAILED"

export const LINK_MASTER = "LINK_MASTER"
export const LINK_MASTER_SUCCESSFUL = "LINK_MASTER_SUCCESSFUL"
export const LINK_MASTER_FAILED = "LINK_MASTER_FAILED"

export const GENERATE_TOKEN = "GENERATE_TOKEN"
export const GENERATE_TOKEN_SUCCESSFUL = "GENERATE_TOKEN_SUCCESSFUL"
export const GENERATE_TOKEN_FAILED = "GENERATE_TOKEN_FAILED"

export const VALIDATE_TOKEN = "VALIDATE_TOKEN"
export const VALIDATE_TOKEN_SUCCESSFUL = "VALIDATE_TOKEN_SUCCESSFUL"
export const VALIDATE_TOKEN_FAILED = "VALIDATE_TOKEN_FAILED"