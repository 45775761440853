const common_data_init_state = {
    strategyID: "",
    strategy: "",
    strategy_name: "",
    strategy_type: "",
    symbol: "",
    symbol_code: "",
    exchange: "",
    exchange_code: "",
    day: 0,
    month_name: "",
    month_value: 0,
    year: 0,
    option_type: "ATM",
    option_gap: 0,
    trade_type: "CANDLE_CLOSE",
    timeframe: "M15",
    stoploss_on: "false",
    stoploss_type: "NORMAL",
    trailing_step: "0",
    stoploss: "0",
    target_on: "false",
    target: "0",
    qty: "",
    order_type: "MIS",
    reentry_on: "false",
    squareoff_time: "15:00",
    pnl: 0,
    pnl_percentage: 0,
    live_price: 0,
    signal: {}
}

module.exports = common_data_init_state