import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  GET_MASTERS,
  MASTERS_EVENTS,
  MASTER_AMOUNT_CHANGE,
} from "./actionTypes";
import {
  getMastersSuccessful,
  getMastersFailed,
  mastersEventsSuccessful,
  mastersEventsFailed,
  masterAmountChangeSuccessful,
  masterAmountChangeFailed,
} from "./actions";

import {
  getMastersCall, changeMasterAmountCall, mastersEventsCall
} from "../../../helpers/backend_helper";

function* getMasters({ payload: { user, history } }) {
  try {
    var input = user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(getMastersCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(getMastersSuccessful(output.data))
    }
    else {
      yield put(getMastersFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(getMastersFailed(error.message));
  }
}

function* changeMasterAmount({ payload: { user, history } }) {
  try {
    var input = user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(changeMasterAmountCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(masterAmountChangeSuccessful(output.message))
    }
    else {
      yield put(masterAmountChangeFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(masterAmountChangeFailed(error.message));
  }
}

function* mastersEvents({ payload: { user, history } }) {
  try {
    var input = user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(mastersEventsCall, input);

    //console.log("output=" + JSON.stringify(output));

    if (output.result == "success") {
      yield put(mastersEventsSuccessful(output.message))
    }
    else {
      yield put(mastersEventsFailed(output.message))
    }
  } catch (error) {
    console.log(error.message);
    yield put(mastersEventsFailed(error.message));
  }
}


function* adminMastersSaga() {
  yield takeEvery(GET_MASTERS, getMasters);
  yield takeEvery(MASTERS_EVENTS, mastersEvents);
  yield takeEvery(MASTER_AMOUNT_CHANGE, changeMasterAmount);
}

export default adminMastersSaga;
