import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
    Row, Col, Collapse,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";

import classnames from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const BottomNavbar = props => {

    const [dashboard, setdashboard] = useState(false);
    const [ui, setui] = useState(false);
    const [app, setapp] = useState(false);
    const [email, setemail] = useState(false);
    const [ecommerce, setecommerce] = useState(false);
    const [crypto, setcrypto] = useState(false);
    const [project, setproject] = useState(false);
    const [task, settask] = useState(false);
    const [contact, setcontact] = useState(false);
    const [blog, setBlog] = useState(false);
    const [component, setcomponent] = useState(false);
    const [form, setform] = useState(false);
    const [table, settable] = useState(false);
    const [chart, setchart] = useState(false);
    const [icon, seticon] = useState(false);
    const [map, setmap] = useState(false);
    const [extra, setextra] = useState(false);
    const [invoice, setinvoice] = useState(false);
    const [auth, setauth] = useState(false);
    const [utility, setutility] = useState(false);

    const [role, setRole] = useState("");

    const [activeTab, setactiveTab] = useState("0");

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("authAdmin")) {
            var authAdmin = localStorage.getItem("authAdmin");
            if (authAdmin) {
                authAdmin = atob(authAdmin);
            }
            const obj = JSON.parse(authAdmin);
            setRole(obj.role || "");
        }
        else if (localStorage.getItem("authUser")) {
            var authUser = localStorage.getItem("authUser");
            if (authUser) {
                authUser = atob(authUser);
            }
            const obj = JSON.parse(authUser);
            setRole(obj.role || "");
        }
    }, [props.success]);

    useEffect(() => {
        ChangeActiveClass()
    });

    function ChangeActiveClass() {
        var matchingMenuItem = null;
        var ul = document.getElementById("bottom_navigation");
        if (ul) {
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (props.location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
    }

    const history = props.history;
    useEffect(() => {
        history.listen((location) => {
            //console.log(`You changed the page to: ${location.pathname}`)
            ChangeActiveClass();
        })
    }, [history])

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }

    function handleScroll() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    return (
        <React.Fragment>
            {(role == "user" || role == "master") && (
                <div className="col-sm-1 d-block d-sm-none" style={{
                    position: "fixed",
                    width: "100%",
                    bottom: 0,
                    backgroundColor: "white"
                }}>
                    <Nav pills justified id="bottom_navigation">

                        <NavItem>
                            <Link
                                className={"nav-link " + classnames({
                                    active: activeTab === "1",
                                })}
                                onClick={() => {
                                    toggleTab("1");
                                    handleScroll();
                                }}
                                style={{ fontSize: "9px", height: "58px" }}
                                to="/marketwatch"
                            >
                                <i className="fas fa-chart-line font-size-20 d-sm-none" />
                                <h6 className="d-sm-block">Markets</h6>
                            </Link>
                        </NavItem>

                        <NavItem>
                            <Link
                                className={"nav-link " + classnames({
                                    active: activeTab === "2",
                                })}
                                onClick={() => {
                                    toggleTab("2");
                                    handleScroll();
                                }}
                                style={{ fontSize: "9px", height: "58px" }}
                                to="/authentication"
                            >
                                <i className="fas fa-fingerprint font-size-20 d-sm-none" />
                                <h6 className="d-sm-block">Auth</h6>
                            </Link>
                        </NavItem>

                        <NavItem>
                            <Link
                                className={"nav-link " + classnames({
                                    active: activeTab === "3",
                                })}
                                onClick={() => {
                                    toggleTab("3");
                                    handleScroll();
                                }}
                                style={{ fontSize: "9px", height: "58px" }}
                                to="/tradehistory"
                            >
                                <i className="fas fa-book-open font-size-20 d-sm-none" />
                                <h6 className="d-sm-block">Trades</h6>
                            </Link>
                        </NavItem>

                        <NavItem>
                            <Link
                                className={"nav-link " + classnames({
                                    active: activeTab === "4",
                                })}
                                onClick={() => {
                                    toggleTab("4");
                                    handleScroll();
                                }}
                                style={{ fontSize: "9px", height: "58px" }}
                                to="/settings"
                            >
                                <i className="bx bx-certification font-size-20 d-sm-none" />
                                <h6 className="d-sm-block">Settings</h6>
                            </Link>
                        </NavItem>


                    </Nav>
                </div>
            )}
        </React.Fragment >
    );
};

BottomNavbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
};

const mapStatetoProps = state => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(BottomNavbar))
);
