export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESSFUL = "GET_USERS_SUCCESSFUL"
export const GET_USERS_FAILED = "GET_USERS_FAILED"

export const USERS_EVENTS = "USERS_EVENTS"
export const USERS_EVENTS_SUCCESSFUL = "USERS_EVENTS_SUCCESSFUL"
export const USERS_EVENTS_FAILED = "USERS_EVENTS_FAILED"

export const USERS_ROLE_CHANGE = "USERS_ROLE_CHANGE"
export const USERS_ROLE_CHANGE_SUCCESSFUL = "USERS_ROLE_CHANGE_SUCCESSFUL"
export const USERS_ROLE_CHANGE_FAILED = "USERS_ROLE_CHANGE_FAILED"