import {
  GET_MASTERS,
  GET_MASTERS_SUCCESSFUL,
  GET_MASTERS_FAILED,
  MASTERS_EVENTS,
  MASTERS_EVENTS_SUCCESSFUL,
  MASTERS_EVENTS_FAILED,
  MASTER_AMOUNT_CHANGE,
  MASTER_AMOUNT_CHANGE_SUCCESSFUL,
  MASTER_AMOUNT_CHANGE_FAILED,
} from "./actionTypes"

export const getMasters = (user, history) => {
  return {
    type: GET_MASTERS,
    payload: { user, history },
  }
}

export const getMastersSuccessful = user => {
  return {
    type: GET_MASTERS_SUCCESSFUL,
    payload: user,
  }
}

export const getMastersFailed = user => {
  return {
    type: GET_MASTERS_FAILED,
    payload: user,
  }
}

export const mastersEvents = (user, history) => {
  return {
    type: MASTERS_EVENTS,
    payload: { user, history },
  }
}

export const mastersEventsSuccessful = user => {
  return {
    type: MASTERS_EVENTS_SUCCESSFUL,
    payload: user,
  }
}

export const mastersEventsFailed = user => {
  return {
    type: MASTERS_EVENTS_FAILED,
    payload: user,
  }
}

export const masterAmountChange = (user, history) => {
  return {
    type: MASTER_AMOUNT_CHANGE,
    payload: { user, history },
  }
}

export const masterAmountChangeSuccessful = user => {
  return {
    type: MASTER_AMOUNT_CHANGE_SUCCESSFUL,
    payload: user,
  }
}

export const masterAmountChangeFailed = user => {
  return {
    type: MASTER_AMOUNT_CHANGE_FAILED,
    payload: user,
  }
}