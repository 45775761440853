import {
  LOGIN_ADMIN,
  LOGIN_ADMIN_SUCCESSFUL,
  LOGIN_ADMIN_FAILED,
  LOGOUT_ADMIN,
  LOGOUT_ADMIN_SUCCESSFUL,
  LOGOUT_ADMIN_FAILED,
} from "./actionTypes"

export const loginAdmin = (admin, history) => {
  return {
    type: LOGIN_ADMIN,
    payload: { admin, history },
  }
}

export const loginAdminSuccessful = admin => {
  return {
    type: LOGIN_ADMIN_SUCCESSFUL,
    payload: admin,
  }
}

export const loginAdminFailed = admin => {
  return {
    type: LOGIN_ADMIN_FAILED,
    payload: admin,
  }
}

export const logoutAdmin = history => {
  return {
    type: LOGOUT_ADMIN,
    payload: { history },
  }
}

export const logoutAdminSuccessful = () => {
  return {
    type: LOGOUT_ADMIN_SUCCESSFUL,
    payload: {},
  }
}

export const logoutAdminFailed = () => {
  return {
    type: LOGOUT_ADMIN_FAILED,
    payload: {},
  }
}