import React, { useEffect, useState, useRef } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Tooltip } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// actions
import { getOrderbook, getAuthenticationData } from "../../../store/actions";

import { API_URL } from "helpers/api_helper";

import axios from "axios";

import TradingPopup from "pages/TradingPopup";

import { socket_io, AddToMarketDataSocketInstruments } from "../../../common/sockets"

const OrderBook = props => {

  //meta title
  document.title = "OrderBook | Algobridge ";

  const dispatch = useDispatch();

  const [show_popup, set_show_popup] = useState(false);

  const [tradeObject, set_tradeObject] = useState({});

  const [apiSuccess, SetapiSuccess] = useState(null)

  const [apiError, SetapiError] = useState(null)

  const { orderbookData, authenticationData } = useSelector(state => ({
    orderbookData: state.Trading.orderbookData,
    authenticationData: state.Authentication.authenticationData,
  }
  ));

  const [ttop, setttop] = useState({ id: "TooltipTop_0", open: false, message: "" });

  useEffect(() => {
    setTimeout(() => { dispatch(getAuthenticationData());}, 100);
  }, []);


  useEffect(() => {
    getOrderbookData();
    setInterval(getOrderbookData, 3000);
  }, []);

  function getOrderbookData() {
    if (document.title.indexOf("OrderBook") == -1) {
      return;
    }
    dispatch(getOrderbook());
  }

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => SetapiSuccess(null), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => SetapiError(null), 1000)
    }
  }, [apiError]);

  function getOrderStatusByStatusID(id) {
    if (id == 1) return "Canceled";
    else if (id == 2) return "Traded";
    else if (id == 3) return "NotUsed";
    else if (id == 4) return "Transit";
    else if (id == 5) return "Rejected";
    else if (id == 6) return "Pending";
    else if (id == 7) return "Expired";
    else return "Empty"
  }

  function MouseOver(event) {
    $(event.target).closest("tr").find(".div-call-trade").show();
  }
  function MouseOut(event) {
    $(event.target).closest("tr").find(".div-call-trade").hide();
  }

  function modifyOrder(broker, data) {

    data.broker = broker;
    set_tradeObject(data);
    set_show_popup(true);

  }

  function cancelOrder(broker, data) {

    if (confirm("Are you sure want to Cancel ?") == true) {

      var authUser = localStorage.getItem("authUser");
      if (authUser) { authUser = atob(authUser); }
      var email = JSON.parse(authUser).email;
      var role = JSON.parse(authUser).role;

      var input = {
        email: email,
        role: role,
        broker: broker,
        orderInfo: data
      }

      const headers = {
        'Content-Type': 'application/json'
      };

      axios.post(API_URL + '/trading/cancelOrder', JSON.stringify(input), { headers }).then(response => {

        //console.log(response) 

        var output = response.data;

        if (output.result == "success") {

          SetapiSuccess(output.message);

          setTimeout(() => { getOrderbookData }, 1000);

        }
        else {
          SetapiError(output.message);

        }

      });

    }

  }

  const toggleShow = () => set_show_popup(p => !p);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="OrderBook" />

          <Row>
            <Col>
              <Card>
                <CardBody>

                  {apiSuccess && (
                    <div
                      className="alert alert-success text-center"
                      role="alert">
                      {apiSuccess}
                    </div>
                  )}

                  {apiError && (
                    <div
                      className="alert alert-danger text-center"
                      role="alert">
                      {apiError}
                    </div>
                  )}


                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>Time</Th>
                            <Th>Order ID</Th>
                            <Th data-priority="1">Type</Th>
                            <Th data-priority="2">Instrument</Th>
                            <Th data-priority="3">Product</Th>
                            <Th data-priority="4">Qty</Th>
                            <Th data-priority="5">Avg Price</Th>
                            <Th data-priority="6">Status</Th>
                            <Th data-priority="7">Trade By</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {orderbookData && authenticationData && authenticationData.defaultBroker == "alice" &&

                            orderbookData.map((data, key) => (
                              <Tr key={key}>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  {data.orderentrytime}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.Nstordno}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}><span className={data.Trantype.toUpperCase() == "B" ? "text-success" : "text-danger"}>{data.Trantype}</span> </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut} style={{ position: "relative" }}>
                                  {data.Trsym}

                                  {data.Status.toUpperCase() == "OPEN" && (
                                    <div className="div-call-trade" style={{
                                      textAlign: "center",
                                      position: "absolute",
                                      right: 0,
                                      top: 0,
                                      backgroundColor: "white",
                                      width: "100%",
                                      height: "100%",
                                      paddingTop: "15px",
                                      display: "none"
                                    }}
                                    >
                                      <button type="button" onClick={() => { modifyOrder(authenticationData.defaultBroker, data) }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                        Modify
                                      </button>
                                      <button type="button" onClick={() => { cancelOrder(authenticationData.defaultBroker, data) }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                        Cancel
                                      </button>

                                    </div>
                                  )}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.Pcode}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.Qty}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.Prc > 0 ? data.Prc : data.Avgprc}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  <button
                                    type="button"
                                    className={data.Status.toUpperCase() == "OPEN" ? "btn btn-primary" : data.Status.toUpperCase() == "COMPLETE" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.Status.toUpperCase() == "COMPLETE") ? data.RejReason : JSON.stringify(data.RejReason) })

                                    }}
                                  >
                                    {data.Status.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.remarks == "--" ? "MANUAL" : data.remarks.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && authenticationData.defaultBroker == "zerodha" &&

                            orderbookData.sort((a, b) => a.order_id > b.order_id ? -1 : 1).map((data, key) => (
                              <Tr key={key}>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  {data.order_timestamp}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.order_id}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}><span className={data.transaction_type.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.transaction_type}</span> </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut} style={{ position: "relative" }}>
                                  {data.tradingsymbol}
                                  {data.status.toUpperCase() == "OPEN" && (
                                    <div className="div-call-trade" style={{
                                      textAlign: "center",
                                      position: "absolute",
                                      right: 0,
                                      top: 0,
                                      backgroundColor: "white",
                                      width: "100%",
                                      height: "100%",
                                      paddingTop: "15px",
                                      display: "none"
                                    }}
                                    >
                                      <button type="button" onClick={() => { modifyOrder(authenticationData.defaultBroker, data) }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                        Modify
                                      </button>
                                      <button type="button" onClick={() => { cancelOrder(authenticationData.defaultBroker, data) }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                        Cancel
                                      </button>

                                    </div>
                                  )}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.product}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.quantity}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.price > 0 ? data.price : data.average_price}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  <button
                                    type="button"
                                    className={data.status.toUpperCase() == "OPEN" ? "btn btn-primary" : data.status.toUpperCase() == "COMPLETE" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.status.toUpperCase() == "COMPLETE") ? data.status_message : data.status_message })

                                    }}
                                  >
                                    {data.status.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.tag == null ? "MANUAL" : data.tag.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && authenticationData.defaultBroker == "angel" &&

                            orderbookData.sort((a, b) => a.orderid > b.orderid ? -1 : 1).map((data, key) => (
                              <Tr key={key}>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  {data.updatetime}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.orderid}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}><span className={data.transactiontype.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.transactiontype}</span> </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut} style={{ position: "relative" }}>
                                  {data.tradingsymbol}
                                  {data.status.toUpperCase() == "OPEN" && (
                                    <div className="div-call-trade" style={{
                                      textAlign: "center",
                                      position: "absolute",
                                      right: 0,
                                      top: 0,
                                      backgroundColor: "white",
                                      width: "100%",
                                      height: "100%",
                                      paddingTop: "15px",
                                      display: "none"
                                    }}
                                    >
                                      <button type="button" onClick={() => { modifyOrder(authenticationData.defaultBroker, data) }} className="btn btn-sm btn-outline-success waves-effect waves-light me-1">
                                        Modify
                                      </button>
                                      <button type="button" onClick={() => { cancelOrder(authenticationData.defaultBroker, data) }} className="btn btn-sm btn-outline-danger waves-effect waves-light me-1">
                                        Cancel
                                      </button>

                                    </div>
                                  )}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.producttype}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.quantity}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.price > 0 ? data.price : data.averageprice}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  <button
                                    type="button"
                                    className={data.status.toUpperCase() == "OPEN" ? "btn btn-primary" : data.status.toUpperCase() == "COMPLETE" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.status.toUpperCase() == "COMPLETE") ? data.text : data.text })

                                    }}
                                  >
                                    {data.status.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.ordertag == null ? "MANUAL" : data.ordertag.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && (authenticationData.defaultBroker == "basan" || authenticationData.defaultBroker == "sas" || authenticationData.defaultBroker == "mastertrust") &&

                            orderbookData.sort((a, b) => a.orderid > b.orderid ? -1 : 1).map((data, key) => (
                              <Tr key={key}>
                                <Td>
                                  {new Date(parseInt(data.order_entry_time) * 1000).toLocaleString()}
                                </Td>
                                <Td>{data.oms_order_id}</Td>
                                <Td><span className={data.order_side.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.order_side}</span> </Td>
                                <Td>{data.trading_symbol}</Td>
                                <Td>{data.product}</Td>
                                <Td>{data.quantity}</Td>
                                <Td>{Math.round(data.price)}</Td>
                                <Td>
                                  <button
                                    type="button"
                                    className={data.order_status.toUpperCase() == "OPEN" ? "btn btn-primary" : data.order_status.toUpperCase() == "COMPLETE" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.order_status.toUpperCase() == "COMPLETE") ? data.rejection_reason : data.rejection_reason })

                                    }}
                                  >
                                    {data.order_status.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td>{data.ordertag == null ? "MANUAL" : data.ordertag.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && authenticationData.defaultBroker == "dhan" &&

                            orderbookData.sort((a, b) => a.createTime > b.createTime ? -1 : 1).map((data, key) => (
                              <Tr key={key}>
                                <Td>
                                  {data.createTime}
                                </Td>
                                <Td>{data.orderId}</Td>
                                <Td><span className={data.transactionType.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.transactionType}</span> </Td>
                                <Td>{data.tradingSymbol}</Td>
                                <Td>{data.productType}</Td>
                                <Td>{data.quantity}</Td>
                                <Td>{Math.round(data.price)}</Td>
                                <Td>
                                  <button
                                    type="button"
                                    className={data.orderStatus.toUpperCase() == "OPEN" ? "btn btn-primary" : data.orderStatus.toUpperCase() == "TRADED" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.orderStatus.toUpperCase() == "TRADED") ? data.omsErrorDescription : data.omsErrorDescription })

                                    }}
                                  >
                                    {data.orderStatus.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td>{data.correlationId == "" ? "MANUAL" : data.correlationId.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && authenticationData.defaultBroker == "finvasia" &&

                            orderbookData.sort((a, b) => a.norenordno > b.norenordno ? -1 : 1).map((data, key) => (
                              <Tr key={key}>
                                <Td>
                                  {data.norentm}
                                </Td>
                                <Td>{data.norenordno}</Td>
                                <Td><span className={data.trantype.toUpperCase() == "B" ? "text-success" : "text-danger"}>{data.trantype}</span> </Td>
                                <Td>{data.tsym}</Td>
                                <Td>{data.prctyp}</Td>
                                <Td>{data.qty}</Td>
                                <Td>{Math.round(data.prc)}</Td>
                                <Td>
                                  <button
                                    type="button"
                                    className={data.status.toUpperCase() == "OPEN" ? "btn btn-primary" : data.status.toUpperCase() == "COMPLETE" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.status.toUpperCase() == "COMPLETE") ? data.rejreason : data.rejreason })

                                    }}
                                  >
                                    {data.status.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td>{data.remarks ? data.remarks.split("#")[0] : "MANUAL"}</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && authenticationData.defaultBroker == "fyers" &&

                            orderbookData.sort((a, b) => a.orderDateTime > b.orderDateTime ? -1 : 1).map((data, key) => (
                              <Tr key={key}>
                                <Td>
                                  {data.orderDateTime}
                                </Td>
                                <Td>{data.id}</Td>
                                <Td><span className={data.side == 1 ? "text-success" : "text-danger"}>{data.side == 1 ? "BUY" : "SELL"}</span> </Td>
                                <Td>{data.symbol}</Td>
                                <Td>{data.productType}</Td>
                                <Td>{data.qty}</Td>
                                <Td>{Math.round(data.tradedPrice)}</Td>
                                <Td>
                                  <button
                                    type="button"
                                    className={getOrderStatusByStatusID(data.status).toUpperCase() == "PENDING" ? "btn btn-primary" : getOrderStatusByStatusID(data.status).toUpperCase() == "TRADED" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (getOrderStatusByStatusID(data.status).toUpperCase() == "TRADED") ? data.message : data.message })

                                    }}
                                  >
                                    {getOrderStatusByStatusID(data.status).toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td>-</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && (authenticationData.defaultBroker == "iifl" || authenticationData.defaultBroker == "agarwal" || authenticationData.defaultBroker == "bigul" || authenticationData.defaultBroker == "nirmal" || authenticationData.defaultBroker == "anand" || authenticationData.defaultBroker == "composite" || authenticationData.defaultBroker == "wisdom" || authenticationData.defaultBroker == "smc" || authenticationData.defaultBroker == "sscorp") &&

                            orderbookData.sort((a, b) => a.OrderGeneratedDateTime > b.OrderGeneratedDateTime ? -1 : 1).map((data, key) => (
                              <Tr key={key}>
                                <Td>
                                  {data.OrderGeneratedDateTime}
                                </Td>
                                <Td>{data.AppOrderID}</Td>
                                <Td><span className={data.OrderSide == "BUY" ? "text-success" : "text-danger"}>{data.OrderSide}</span> </Td>
                                <Td>{data.ExchangeInstrumentID}</Td>
                                <Td>{data.ProductType}</Td>
                                <Td>{data.OrderQuantity}</Td>
                                <Td>{Math.round(data.OrderAverageTradedPrice)}</Td>
                                <Td>
                                  <button
                                    type="button"
                                    className={data.OrderStatus.toUpperCase() == "PENDING" ? "btn btn-primary" : data.OrderStatus.toUpperCase() == "TRADED" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.OrderStatus.toUpperCase() == "TRADED") ? data.CancelRejectReason : data.CancelRejectReason })

                                    }}
                                  >
                                    {data.OrderStatus.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td>{data.OrderUniqueIdentifier == null ? "MANUAL" : data.OrderUniqueIdentifier.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && authenticationData.defaultBroker == "goodwill" &&

                            orderbookData.sort((a, b) => a.orderentrytime > b.orderentrytime ? -1 : 1).map((data, key) => (
                              <Tr key={key}>
                                <Td>
                                  {data.orderentrytime}
                                </Td>
                                <Td>{data.nstordno}</Td>
                                <Td><span className={data.trantype == "B" ? "text-success" : "text-danger"}>{data.trantype}</span> </Td>
                                <Td>{data.tsym}</Td>
                                <Td>{data.product}</Td>
                                <Td>{data.qty}</Td>
                                <Td>{Math.round(data.avgprc)}</Td>
                                <Td>
                                  <button
                                    type="button"
                                    className={data.status.toUpperCase() == "PENDING" ? "btn btn-primary" : data.status.toUpperCase() == "TRADED" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.status.toUpperCase() == "TRADED") ? data.rejreason : data.rejreason })

                                    }}
                                  >
                                    {data.status.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td>-</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && authenticationData.defaultBroker == "icici" &&

                            orderbookData.map((data, key) => (
                              <Tr key={key}>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  {data.order_datetime}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.order_id}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}><span className={data.action.toUpperCase() == "BUY" ? "text-success" : "text-danger"}>{data.action.toUpperCase()}</span> </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut} style={{ position: "relative" }}>
                                  {data.stock_code}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.order_type}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.quantity}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{(parseInt(data.price) || 0) > 0 ? data.price : data.average_price}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  <button
                                    type="button"
                                    className={data.status.toUpperCase() == "OPEN" ? "btn btn-primary" : data.status.toUpperCase() == "COMPLETE" ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.status.toUpperCase() == "COMPLETE") ? data.status : (data.status) })

                                    }}
                                  >
                                    {data.status.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.user_remark == "" ? "MANUAL" : data.user_remark.split("#")[0]}</Td>
                              </Tr>
                            ))}

                          {orderbookData && authenticationData && authenticationData.defaultBroker == "neo" &&

                            orderbookData.map((data, key) => (
                              <Tr key={key}>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  {data.ordDtTm}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.nOrdNo}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}><span className={data.trnsTp.toUpperCase() == "B" ? "text-success" : "text-danger"}>{data.trnsTp.toUpperCase()}</span> </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut} style={{ position: "relative" }}>
                                  {data.trdSym}
                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.prcTp}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.qty}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{parseInt(data.prc)}</Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>
                                  <button
                                    type="button"
                                    className={data.ordSt.toUpperCase().indexOf("OPEN") > -1 ? "btn btn-primary" : data.ordSt.toUpperCase().indexOf("COMPLETE") > -1 ? "btn btn-success" : "btn btn-danger"}
                                    id={"TooltipTop_" + key}
                                    onClick={() => {

                                      setttop({ id: "TooltipTop_" + key, open: !ttop.open, message: (data.ordSt.toUpperCase().indexOf("COMPLETE") > -1 ? data.ordSt : data.rejRsn) })

                                    }}
                                  >
                                    {data.ordSt.toUpperCase()}
                                  </button>

                                  <Tooltip
                                    placement="top"
                                    isOpen={ttop.id == "TooltipTop_" + key && ttop.open}
                                    target={"TooltipTop_" + key}
                                  >
                                    {ttop.id == "TooltipTop_" + key && ttop.message}
                                  </Tooltip>

                                </Td>
                                <Td onMouseOver={MouseOver} onMouseOut={MouseOut}>{data.rmk == "--" ? "MANUAL" : data.rmk.split("#")[0]}</Td>
                              </Tr>
                            ))}


                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <TradingPopup tradeObject={tradeObject} show_popup={show_popup} toggleShow={toggleShow} ModifyLimit={true}> </TradingPopup>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default OrderBook
