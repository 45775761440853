import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginUserSuccessful, loginUserFailed, logoutUserSuccessful, logoutUserFailed } from "./actions";

import { loginUserCall } from "../../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {
  try {

    var input = (user == undefined) ? {} : user;

    //console.log("input=" + JSON.stringify(input));

    const output = yield call(loginUserCall, input);

    //console.log("output=" + JSON.stringify(output));


    if (output.result == "success") {
      var authUser = output.authUser;
      localStorage.setItem("authUser", btoa(JSON.stringify(authUser)));
      localStorage.removeItem("authAdmin");
      yield put(loginUserSuccessful(output.message))
      // if (authUser.DisclaimerAgree == "true") {
      //   history.push("/marketwatch");
      // } else {
      //   history.push("/disclaimer");
      // }
      //history.push("/marketwatch");
      history.push("/totp");
    }
    else {
      yield put(loginUserFailed(output.message))
    }

  } catch (error) {
    yield put(loginUserFailed(error.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("optionchain_record");
    localStorage.removeItem("greek_record");
    history.push("/login");
  } catch (error) {
    yield put(logoutUserFailed(error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
