import {
  GET_MASTERS_SUCCESSFUL,
  GET_MASTERS_FAILED,
  MASTERS_EVENTS_SUCCESSFUL,
  MASTERS_EVENTS_FAILED,
  MASTER_AMOUNT_CHANGE_SUCCESSFUL,
  MASTER_AMOUNT_CHANGE_FAILED,
} from "./actionTypes"

const initialState = {
  loading: false,
  apiSuccess: null,
  apiError: null,
  mastersData: null,
}

const AdminMasters = (state = initialState, action) => {
  switch (action.type) {
    case GET_MASTERS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        mastersData: action.payload
      }
      break
    case GET_MASTERS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: null,
        mastersData: null
      }
      break
    case MASTER_AMOUNT_CHANGE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case MASTER_AMOUNT_CHANGE_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    case MASTERS_EVENTS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        apiSuccess: action.payload,
        apiError: null,
      }
      break
    case MASTERS_EVENTS_FAILED:
      state = {
        ...state,
        loading: false,
        apiSuccess: null,
        apiError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default AdminMasters
