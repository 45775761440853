import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Tooltip,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
} from "reactstrap";

import CommonModal from './common';

import common_data_init_state from "../model/common"

import optionwriting_data_init_state from "../model/option_writing"

function OptionWriting(props) {

  const [common_data, set_common_data] = React.useState(common_data_init_state);

  const updateCommonData = (newData) => {
    set_common_data(newData);
  };

  const [edit_data, set_edit_data] = React.useState(null);

  const { strategyID, strategy, symbol_code, exchange, exchange_code, day, month_name, month_value, year, option_type, option_gap, trade_type, order_type, timeframe,
    stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
    squareoff_time
  } = common_data;

  const [optionwriting_data, set_optionwriting_data] = React.useState(optionwriting_data_init_state);

  const { symbol, expiry_date, execution_time, hedge_range, level1_stoploss, level2_stoploss } = optionwriting_data;

  const changeHandler = (e) => {

    //option writing
    if (e.target.name == "symbol") {
      set_optionwriting_data({ ...optionwriting_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "expiry_date") {
      set_optionwriting_data({ ...optionwriting_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "execution_time") {
      set_optionwriting_data({ ...optionwriting_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "hedge_range") {
      set_optionwriting_data({ ...optionwriting_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "level1_stoploss") {
      set_optionwriting_data({ ...optionwriting_data, [e.target.name]: e.target.value });
    }
    else if (e.target.name == "level2_stoploss") {
      set_optionwriting_data({ ...optionwriting_data, [e.target.name]: e.target.value });
    }

  };


  function Submit() {

    //debugger;

    var input = { ...common_data, ...optionwriting_data };

    input.strategy = props.strategy;

    input.strategyID = props.strategyID;

    input.strategy_name = props.strategy_name;

    input.strategy_type = props.strategy_type;

    //alert(JSON.stringify(input));

    if (input.strategy == "" || symbol == "" || qty == "") {
      SetapiError("Enter All Required Fields !!!")
      return;
    }

    if (input.strategy_type == "NORMAL") {
      if (input.strategy == "" || symbol == "" || symbol_code == "" || exchange == "" || exchange_code == "" || qty == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }
    }
    else if (input.strategy_type == "OPTIONS") {
      if (input.strategy == "" || symbol == "" || month_name == "" || month_value == "" || year == "" || qty == "" || option_type == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }

      if (option_type != "ATM" && option_gap == "") {
        SetapiError("Enter All Required Fields !!!")
        return;
      }
    }

    if (input.strategyID == "") {
      props.AddStrategy(input);
      props.setmodal_standard(false);
    } else {
      props.UpdateStrategy(input);
      props.setmodal_standard(false);
    }

  }

  useEffect(() => {

    if (props.editData) {

      var item = props.editData;

      const { strategyID, strategy, symbol_code, exchange, exchange_code, trade_type, order_type, timeframe,
        stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
        squareoff_time, ...rest
      } = item;

      set_edit_data({
        strategyID, strategy, symbol_code, exchange, exchange_code, trade_type, order_type, timeframe,
        stoploss_on, stoploss_type, trailing_step, stoploss, target_on, target, qty, reentry_on,
        squareoff_time
      });

      set_optionwriting_data(rest);

    }
    else {
      set_optionwriting_data(optionwriting_data_init_state);
    }
  }, [props.editData]);


  const [apiSuccess, SetapiSuccess] = useState(null)

  const [apiError, SetapiError] = useState(null)

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => SetapiSuccess(null), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => SetapiError(null), 1000)
    }
  }, [apiError]);

  return (
    <React.Fragment>
      <>
        <Row>

          <fieldset className="border border-light border-2 rounded-3 p-2 mb-2">
            <legend className="float-none w-auto px-3 fs-6 fw-bold">
              OptionWriting Properties
            </legend>

            <Row>

              <Col className="mb-2" md={6}>
                <div className="form-floating mb-3">
                  <select value={symbol} name="symbol" required={true} className="form-select" onChange={changeHandler}>
                    <option value="">SELECT</option>
                    <option value="BANKNIFTY">BANKNIFTY</option>
                    <option value="NIFTY">NIFTY</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Symbol</label>
                </div>
              </Col>
              <Col className="mb-2" md={6}>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="date"
                    id="example-date-input"
                    value={expiry_date}
                    name="expiry_date"
                    onChange={changeHandler}
                    required={true} />
                  <label htmlFor="floatingSelectGrid">Expiry</label>
                </div>
              </Col>
              <Col className="mb-2" md={6}>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="time"
                    id="example-time-input"
                    value={execution_time}
                    name="execution_time"
                    onChange={changeHandler}
                    required={true} />
                  <label htmlFor="floatingSelectGrid">Execution Time</label>
                </div>
              </Col>
              <Col className="mb-2" md={6}>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="number"
                    value={hedge_range}
                    name="hedge_range"
                    onChange={changeHandler}
                    required={true} />
                  <label htmlFor="floatingSelectGrid">Hedge Range</label>
                </div>
              </Col>
              <Col className="mb-2" md={6}>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="number"
                    value={level1_stoploss}
                    name="level1_stoploss"
                    onChange={changeHandler}
                    required={true} />
                  <label htmlFor="floatingSelectGrid">Level 1 Stoploss %</label>
                </div>
              </Col>

              {reentry_on == "true" &&
                <Col className="mb-2" md={6}>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="number"
                      value={level2_stoploss}
                      name="level2_stoploss"
                      onChange={changeHandler}
                      required={true} />
                    <label htmlFor="floatingSelectGrid">Level 2 Stoploss %</label>
                  </div>
                </Col>
              }

            </Row>

          </fieldset>

          <CommonModal edit_data={edit_data} strategy={props.strategy} strategy_type={props.strategy_type} strategy_name={props.strategy_name} updateCommonData={updateCommonData} />

          <Col className="mb-2" md={12}>
            <button type="button" onClick={() => { Submit() }} className="btn btn-primary float-end">
              Add Strategy
            </button>
          </Col>
          {
            apiSuccess && (
              <div
                className="alert alert-success text-center mb-0"
                role="alert">
                {apiSuccess}
              </div>
            )
          }

          {
            apiError && (
              <div
                className="alert alert-danger text-center mb-0"
                role="alert">
                {apiError}
              </div>
            )
          }
        </Row>
      </>
    </React.Fragment>
  );
}

export default OptionWriting;
