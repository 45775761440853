import React, { useEffect, useState } from "react"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Badge, Collapse } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { Link } from "react-router-dom";

import classnames from "classnames";

import { API_URL } from "helpers/api_helper";

// actions
import { getAuthenticationData, followClientsEvents, followClientsEventsSuccessful, followClientsEventsFailed, followClientsRenewal } from "../../../store/actions";

import axios from "axios";

const MyClients = props => {

  //meta title
  document.title = "My Clients | Algobridge ";

  const dispatch = useDispatch();

  var today = new Date(Date.now());

  today.setHours(0, 0, 0, 0);

  const { apiSuccess, apiError, authenticationData } = useSelector(state => ({
    apiSuccess: state.MyClients.apiSuccess,
    apiError: state.MyClients.apiError,
    authenticationData: state.Authentication.authenticationData,
  }
  ));

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);

  const [planAmount, setplanAmount] = useState(0);

  const [page, setpage] = useState(0);

  const [searchData, setsearchData] = useState("");

  const [followClientsData, setfollowClientsData] = useState(null);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
  };

  useEffect(() => {
    LoadData(searchData, page);
  }, []);

  useEffect(() => {
    dispatch(followClientsEventsSuccessful(null));
    dispatch(followClientsEventsFailed(null));
  }, []);

  useEffect(() => {
    dispatch(getAuthenticationData());
  }, []);

  useEffect(() => {
    authenticationData ? setplanAmount(authenticationData.planAmount) : 0;
  }, [authenticationData]);

  useEffect(() => {
    if (apiSuccess != null) {
      setTimeout(() => dispatch(followClientsEventsSuccessful(null)), 1000)
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (apiError != null) {
      setTimeout(() => dispatch(followClientsEventsFailed(null)), 1000)
    }
  }, [apiError]);

  function CreatePayment(name, email, mobile, userKey, followingMasterKey, masterEmail) {
    // var test = name + "-" + email + "-" + mobile + "-" + userKey + "-" + followingMasterKey + "-" + masterEmail
    // alert(test);

    var service = "copytrader"

    var amount = planAmount;

    if (planAmount > 0) {

      var random_id = Math.floor((Math.random() * 1000) + 1);

      var obj = {
        "customer_id": "cus" + "-" + mobile + "-" + random_id,
        "customer_email": email,
        "customer_phone": mobile,
        "order_id": "ord" + "-" + mobile + "-" + random_id,
        "order_amount": amount,
        "order_tags": {
          "service": service.toString(),
          "name": name.toString(),
          "email": email.toString(),
          "mobile": mobile.toString(),
          "userKey": userKey.toString(),
          "followingMasterKey": followingMasterKey.toString(),
          "masterEmail": masterEmail.toString(),
          "actual_amount": amount.toString()
        },
      }

      const headers = {
        'Content-Type': 'application/json'
      };

      axios.post(API_URL + '/payment/phonepe-create-order', JSON.stringify(obj), { headers }).then(response => {

        //console.log(response) 

        var output = response.data;

        if (output.result == "success") {
          localStorage.setItem("merchantTransactionId", output.merchantTransactionId);
          location.href = output.payment_link;
        }
        else {
          dispatch(followClientsEventsFailed(output.message));
        }

      });

    }
  }

  function SearchData() {
    //alert("searchData=" + searchData);
    LoadData(searchData, page);
  }

  function LoadData(search, no) {

    var authUser = localStorage.getItem("authUser");
    if (authUser) { authUser = atob(authUser); }
    var email = JSON.parse(authUser).email;

    var obj = {
      "email": email,
      "search": search,
      "page": no
    };

    //console.log("page=" + no);

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/user/getFollowClients', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {
        setfollowClientsData(output.data);
      }
      else {
        setfollowClientsData(null);
      }
    });
  }

  function CheckAuthenticationTokenExists(defaultBroker, authentication) {
    var filterAuthentication = authentication.filter(auth => {
      return auth.brokerId.toLowerCase().match(defaultBroker.toLowerCase()) && new Date(auth.tokenUpdated_at) > today
    });
    if (filterAuthentication.length > 0) return true;
    return false;
  }

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;


  function ResetQR(email) {

    var obj = {
      "email": email,
    };

    //console.log("page=" + no);

    const headers = {
      'Content-Type': 'application/json'
    };

    axios.post(API_URL + '/user/reset-qr-key', JSON.stringify(obj), { headers }).then(response => {

      //console.log(response) 

      var output = response.data;

      if (output.result == "success") {
        dispatch(followClientsEventsSuccessful(output.message));
      }
      else {
        dispatch(followClientsEventsFailed(output.message));
      }
    });

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {apiSuccess && (
            <div
              className="alert alert-success text-center"
              role="alert">
              {apiSuccess}
            </div>
          )}

          {apiError && (
            <div
              className="alert alert-danger text-center"
              role="alert">
              {apiError}
            </div>
          )}

          <Row>
            <Col>
              <Card>
                <CardBody>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className={classnames(
                          "accordion-button",
                          "fw-medium",
                          { collapsed: !col2 }
                        )}
                        type="button"
                        onClick={t_col2}
                        style={{ cursor: "pointer" }}
                      >
                        Clients
                      </button>
                    </h2>

                    <Collapse isOpen={true} className="accordion-collapse">

                      <Row className="mt-3 mb-3 ps-2 pe-2">
                        <Col md={6} className="mb-2">
                          <div className="d-flex flex-wrap gap-2">

                            <div className="btn-group">
                              <Link to="#" onClick={() => {
                                if (page > 0) {
                                  LoadData(searchData, page - 1);
                                  setpage(page - 1);
                                }
                              }} className="btn btn-outline-primary">Prev</Link>
                              <Link to="#" className="btn btn-outline-primary">{page + 1}</Link>
                              <Link to="#" onClick={() => {
                                LoadData(searchData, page + 1);
                                setpage(page + 1);
                              }} className="btn btn-outline-primary">Next</Link>
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="mb-2" style={isMobile == false ? { textAlign: 'right' } : {}}>
                          <div style={{ display: "inline-flex" }}>
                            <input value={searchData} onChange={(val) => {
                              if (val != "") {
                                setsearchData(val.target.value)
                              }
                            }}
                              onKeyPress={event => {
                                if (event.key === 'Enter') {
                                  SearchData();
                                }
                              }}
                              className="form-control " type="text" placeholder="Search"></input>
                          </div>
                        </Col>
                      </Row>

                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Name</Th>
                                <Th data-priority="1">User Key</Th>
                                <Th data-priority="2">Email</Th>
                                <Th data-priority="2">Mobile</Th>
                                <Th data-priority="3">Days Left</Th>
                                <Th data-priority="4">Auth token</Th>
                                <Th data-priority="5">Action</Th>
                                <Th data-priority="6">Renew</Th>
                                <Th data-priority="7">Reset QR</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {followClientsData &&

                                followClientsData.map((data, key) => (
                                  <Tr key={key}>
                                    <Td>
                                      {data.name}
                                    </Td>
                                    <Td> {data.userKey}</Td>
                                    <Td> {data.email}</Td>
                                    <Td> {data.mobile}</Td>
                                    <Td style={{ "textAlign": "center" }}> {parseInt((new Date(data.expired_at) - new Date(Date.now())) / (1000 * 60 * 60 * 24))}</Td>
                                    <Td>

                                      {
                                        (data.users && data.users.length > 0 && data.users[0].authentication && data.users[0].authentication.length > 0) ?
                                          (
                                            CheckAuthenticationTokenExists(data.users[0].defaultBroker, data.users[0].authentication) == true ?
                                              (<Badge color="success" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>Valid</Badge>)
                                              :
                                              (<Badge color="danger" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>Expired</Badge>)
                                          )
                                          :
                                          (<Badge color="dark" style={{ "padding": "8px 12px 8px 12px", "textAlign": "left" }}>No Token</Badge>)
                                      }

                                    </Td>
                                    <Td>
                                      {data.active == "false" ? (
                                        <button
                                          type="button"
                                          className="btn btn-success me-2" onClick={() => { dispatch(followClientsEvents({ "userKey": data.userKey, "event": "start" }, props.history)); setTimeout(() => { LoadData(searchData, page); }, 500); }}
                                        >
                                          <i className="bx bx-check-double font-size-16 align-middle"></i>{" "}

                                        </button>) : (
                                        <button
                                          type="button"
                                          className="btn btn-warning me-2" onClick={() => { dispatch(followClientsEvents({ "userKey": data.userKey, "event": "stop" }, props.history)); setTimeout(() => { LoadData(searchData, page); }, 500); }}
                                        >
                                          <i className="bx bx-block font-size-16 align-middle"></i>{" "}

                                        </button>)}
                                      <button
                                        type="button"
                                        className="btn btn-danger" onClick={() => { dispatch(followClientsEvents({ "userKey": data.userKey, "event": "delete" }, props.history)); setTimeout(() => { LoadData(searchData, page); }, 500); }}
                                      >
                                        <i className="mdi mdi-trash-can font-size-16 align-middle"></i>{" "}

                                      </button>
                                    </Td>

                                    <Td>
                                      <button
                                        type="button"
                                        className="btn btn-primary me-2" onClick={() => { CreatePayment(data.name, data.email, data.mobile, data.userKey, data.followingMasterKey, data.masterEmail) }}
                                      >
                                        <i className="bx bx-check-double font-size-16 align-middle"></i>{" "}

                                      </button>
                                    </Td>

                                    <Td>
                                      <button
                                        type="button"
                                        className="btn btn-info me-2" onClick={() => { ResetQR(data.email) }}
                                      >
                                        <i className="bx bx-rotate-left font-size-16 align-middle"></i>{" "}

                                      </button>
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

export default MyClients
